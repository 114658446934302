import { Component } from "react";
import { Box, Card, CardBody, Flex, IconButton, Stack, Text } from "@chakra-ui/react";
import { Business, BusinessItem, BusinessItemFlow, BusinessLoading, BusinessLocation, BusinessMarketEventSummary, BusinessUser, MarketEventSummary, SelectedBusinessAttributeDetails, SelectedBusinessOrderStats } from "../../store/businesses/types";
import AppButton from "../global/forms/AppButton";
import NoContentPage from "../../pages/noContent/NoContentPage";
import ViewBusinessViewSelectors from "./ViewBusinessViewSelectors";
import { MdEdit } from "react-icons/md";
import ViewBusinessLocations from "./ViewBusinessLocations";
import ViewBusinessUsers from "./ViewBusinessUsers";
import ViewBusinessItems from "./ViewBusinessItems";
import ViewBusinessRatings from "./ViewBusinessRatings";
import ViewBusinessInformation from "./ViewBusinessInformation";

interface BusinessSummaryCardProps {
  selectedBusiness: Business | null;
  businessLoading: BusinessLoading;
  businessView: 'information' | 'locations' | 'users' | 'items' | 'ratings';
  selectedBusinessLocations: BusinessLocation[];
  selectedBusinessLocation: BusinessLocation | null;
  selectedBusinessUsers: BusinessUser[];
  selectedBusinessItems: BusinessItem[];
  selectedBusinessItem: BusinessItem | null;
  businessItemQuery: SelectedBusinessAttributeDetails;
  businessItemFlow: BusinessItemFlow;
  selectedBusinessOrderStats: SelectedBusinessOrderStats;
  selectedBusinessMarketEventSummary: BusinessMarketEventSummary;
  updateBusinessItemInput: (inputs: BusinessItemFlow) => void;
  updateBusinessItemsQuery: (query: SelectedBusinessAttributeDetails) => void;
  selectBusinessItem: (item: BusinessItem | null) => void;
  selectBusinessLocation: (location: BusinessLocation | null) => void;
  toggleViewBusinessItemModal: () => void;
  fetchBusinessItems: (businessId: string) => void;
}

class BusinessSummaryCard extends Component<BusinessSummaryCardProps> {
  render() {
    const isLoading = this.props.businessLoading.businesses;
    return (
      <Card style={{ borderRadius: 25 }} boxShadow="0 2px 8px rgba(0, 0, 0, 0.2)">
        <CardBody>
          <Stack
            as={Box}
            spacing={{ base: 4, md: 6 }}>
            <Flex align={'center'} flexDirection={'row'}>
              {/* <IconButton aria-label="Edit" variant={'ghost'} borderRadius={'full'} size={'sm'}><MdEdit /></IconButton>  */}
              <Text fontSize='lg' textAlign='center' color={'gray.500'} as='b'>{this.props.selectedBusiness?.name || '' }</Text>  
            </Flex>
            
            <ViewBusinessViewSelectors />
            {!this.props.selectedBusiness && (
              <NoContentPage 
                noContentText="No Business Selected"
                noContentButton={<AppButton text={isLoading ? 'Gathering Info' : 'Get Started'} onClick={() => console.log('Click')} loading={isLoading} transparent={isLoading}/>}
              />
            )}
            <ViewBusinessInformation 
              businessView={this.props.businessView}
              loading={this.props.businessLoading.information}
              selectedBusiness={this.props.selectedBusiness}
              selectedBusinessOrderStats={this.props.selectedBusinessOrderStats}
              selectedBusinessMarketEventSummary={this.props.selectedBusinessMarketEventSummary}
            />
            <ViewBusinessLocations 
              businessView={this.props.businessView}
              loading={this.props.businessLoading.locations}
              selectedBusinessLocations={this.props.selectedBusinessLocations}
              selectedBusinessLocation={this.props.selectedBusinessLocation}
              selectBusinessLocation={this.props.selectBusinessLocation}
            /> 
            <ViewBusinessUsers 
              businessView={this.props.businessView}
              loading={this.props.businessLoading.users}
              selectedBusinessUsers={this.props.selectedBusinessUsers}
            /> 
            <ViewBusinessItems
              businessView={this.props.businessView}
              loading={this.props.businessLoading.items}
              selectedBusinessItems={this.props.selectedBusinessItems}
              selectedBusinessItem={this.props.selectedBusinessItem}
              businessItemQuery={this.props.businessItemQuery}
              selectedBusiness={this.props.selectedBusiness}
              businessItemFlow={this.props.businessItemFlow}
              updateBusinessItemsQuery={this.props.updateBusinessItemsQuery}
              selectBusinessItem={this.props.selectBusinessItem}
              toggleViewBusinessItemModal={this.props.toggleViewBusinessItemModal}
              fetchBusinessItems={this.props.fetchBusinessItems}
              updateBusinessItemInput={this.props.updateBusinessItemInput}
            />
            <ViewBusinessRatings
              businessView={this.props.businessView}
              loading={this.props.businessLoading.ratings}
            />

          </Stack>

        </CardBody>
      </Card>
    );
  }
};

export default BusinessSummaryCard