import React, { useState } from 'react';
import moment from 'moment';
import { Box, Button, Card, CardBody, Table, Tbody, Tr, Th, Td, Text, Center } from '@chakra-ui/react';
import { Business, BusinessMarketEventSummary, BusinessUser, SelectedBusinessOrderStats } from '../../store/businesses/types';
import { formatCurrency } from '../global/data/formatCurrencyHelper';
import LoadingPage from '../../pages/loading/LoadingPage';
import VerticalBarChart from '../global/data/VerticalBarChart';

interface ViewBusinessInformationProps {
  businessView: string;
  selectedBusinessOrderStats: SelectedBusinessOrderStats;
  selectedBusiness: Business | null;
  selectedBusinessMarketEventSummary: BusinessMarketEventSummary;
  loading: boolean;
}

const ViewBusinessInformation: React.FC<ViewBusinessInformationProps> = ({ 
  businessView, selectedBusinessOrderStats, selectedBusiness, loading, selectedBusinessMarketEventSummary
}) => {
  
  if (businessView!=='information') {
    return null;
  }

  if (loading) {
    return <LoadingPage loadingText='Loading Business' />
  }

  const orderedMonths = Object.keys(selectedBusinessOrderStats.ordersByMonth).sort();
  const ordersByMonthChartTitle = orderedMonths.map(month => moment(month).format('MMM'));
  const ordersByMonthChartDataset = [
    {
      fill: true,
      label: "% Orders",
      backgroundColor: '#CA0000',
      data: orderedMonths.map(month => selectedBusinessOrderStats.ordersByMonth[month] * 100)
    }
  ]
  let ordersByDay: any = {
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0
  };
  ordersByDay = {...ordersByDay, ...selectedBusinessOrderStats.ordersByWeekday };
  const ordersByDayChartDataset = [
    {
      fill: true,
      label: "% Orders",
      backgroundColor: '#F7941D',
      data: [
        ordersByDay.monday*100,
        ordersByDay.tuesday*100,
        ordersByDay.wednesday*100,
        ordersByDay.thursday*100,
        ordersByDay.friday*100,
        ordersByDay.saturday*100,
        ordersByDay.sunday*100
      ]
    }
  ];
  const ordersByDayChartTitle = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  
  let ordersByHour: { [key: string]: number } = {};

  const now = new Date();
  const tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tzOffsetMins = now.getTimezoneOffset();
  const tzOffsetHours = tzOffsetMins/60;
  const tzOffset = Math.floor(tzOffsetHours);
  for (const hourString in selectedBusinessOrderStats.ordersByHour) {
    const hour = parseInt(hourString);
    const shiftedHour = (hour - tzOffset + 24) % 24; // Ensure result is non-negative and within 0-23 range
    const originalValue = selectedBusinessOrderStats.ordersByHour[hourString];
    if (typeof originalValue === 'number') {
      ordersByHour[shiftedHour.toString()] = originalValue;
    }
  }
  for (let i = 0; i < 24; i++) {
    if (!ordersByHour[i.toString()]) {
      ordersByHour[i.toString()] = 0;
    } else {
      ordersByHour[i.toString()] = ordersByHour[i.toString()]*100
    }
  }
  const ordersByHourChartTitle = [];

  for (let hour = 0; hour <= 23; hour++) {
    const formattedHour = hour.toString().padStart(2, '0');
    const timeString = `${formattedHour}:00`;
    ordersByHourChartTitle.push(timeString);
  }
  const ordersByHourChartDataset = [
    {
      fill: true,
      label: "% Orders",
      backgroundColor: '#CA0000',
      data: Array.from({ length: 24 }, (_, index) => ordersByHour[index] || 0)
    }
  ];

  return (
    <Box paddingTop={5} paddingBottom={5}>
      <Card borderRadius={20}>
        <CardBody>
          <Table>
            <Tbody>
              <Tr>
                <Th>Business Since</Th>
                <Td fontSize={'sm'}>{moment(selectedBusiness?.createdAt).format('LLL')}</Td>
              </Tr>
              <Tr>
                <Th>Last Ordered</Th>
                <Td fontSize={'sm'}>
                  <Text as={selectedBusinessOrderStats.lastOrderedAt ? 'p' : 'i'}>
                    {selectedBusinessOrderStats.lastOrderedAt ? moment(selectedBusinessOrderStats.lastOrderedAt).format('LLL') : 'No orders yet'}
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Th>
                  Accounting [Gross Sales]
                </Th>
                <Td fontSize={'sm'}>
                  { !selectedBusinessOrderStats.lastOrderedAt && (
                    <Text as={'i'}>No orders yet</Text>
                  ) }
                  {[...Object.keys(selectedBusinessOrderStats.accounting)].map((currency) => (
                  <Text key={currency}>{formatCurrency(currency, selectedBusinessOrderStats.accounting[currency].expenses)}</Text>
                  ))}
                </Td>
              </Tr>
              <Tr>
                <Th>Events [L7D]</Th>
                <Td>
                  {selectedBusinessMarketEventSummary.summary.length === 0 && (
                    <Text as={'i'} fontSize={'sm'}>There were no events in the last 7 days.</Text>
                  )}
                  {selectedBusinessMarketEventSummary.summary.map(event => (
                    <Button size={'xs'} key={event._id} mr={2} mb={2} variant={'outline'}>{event._id} | {event.count.toLocaleString()}</Button>
                    )
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <VerticalBarChart 
            title={`Orders by Hour (${tzName}) [TTM]`} 
            labels={ordersByHourChartTitle} 
            datasets={ordersByHourChartDataset}
          />
          <VerticalBarChart 
            title={'Orders by Weekday [TTM]'} 
            labels={ordersByDayChartTitle} 
            datasets={ordersByDayChartDataset}
          />
          <VerticalBarChart 
            title={'Orders by Month [TTM]'} 
            labels={ordersByMonthChartTitle} 
            datasets={ordersByMonthChartDataset}
          />
        </CardBody>
      </Card>  
    </Box>      
  );
}

export default ViewBusinessInformation;
