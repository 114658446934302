import { connect } from "react-redux";
import { ReduxState } from "../../store/state";
import Navbar from "../../components/navigation/Navbar";
import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Card,
  CardBody
} from '@chakra-ui/react'
import { User } from "../../store/user/types";
import AppButton from "../../components/global/forms/AppButton";
import { Business, BusinessLoading } from "../../store/businesses/types";

interface HomePageProps {
  user: User | null;
  businessLoading: BusinessLoading;
  businesses: Business[];
  selectedBusiness: Business | null;
}

function getGreeting() {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  let greeting;

  if (currentHour < 12) {
    greeting = "Good Morning";
  } else if (currentHour < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  return greeting;
}

const HomePage: React.FC<HomePageProps> = ({ user, businessLoading, businesses, selectedBusiness }) => {
  const isLoading = businessLoading.businesses;
  const renderHomePage = () => {
    return (
      <>
        <Container maxW={'2xl'} pt={10}>
          <Card pt={20} style={{ borderRadius: 25 }} boxShadow="0 4px 16px rgba(0, 0, 0, 0.2)">
            <CardBody>
              <Stack
                as={Box}
                textAlign={'center'}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 10, md: 16 }}>
                <Heading
                  fontWeight={600}
                  fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                  lineHeight={'110%'}
                  color={'#F7941D'}>
                  {getGreeting()}, <br />
                  <Text as={'span'} color={'black'}>
                    { user ? `${user.firstName}!` : null}
                  </Text>
                </Heading>
                { selectedBusiness ?
                <Text color={'gray.900'}>
                  {`${selectedBusiness.name} + Showfa Express`}
                </Text> 
                :
                <AppButton text={isLoading ? 'Gathering Info' : 'Get Started'} onClick={() => console.log('Click')} loading={isLoading} transparent={isLoading}/>
                }
               
              </Stack>
            </CardBody>
          </Card>
        </Container>
      </>
    )
  }

  return (
    <Navbar content={renderHomePage()} />
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { user } = state.user;
  const { businessLoading, businesses, selectedBusiness } = state.businesses;
  return { user, businessLoading, businesses, selectedBusiness };
};

export default connect(mapStateToProps, {
})(HomePage);

