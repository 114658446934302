import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import {
  Business, BusinessItem,
} from '../types';
import {
  editSelectedBusinessItemSuccess,
  editSelectedBusinessItemFailure,
  selectBusinessItem,
  updateBusinessItemInput,
} from '../actions';

// Initiator for updating item information
export const asyncBusinessItemEdit = (itemId: string, update: any, refreshFlow: boolean = false) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      const { auth, businesses } = getState();
      const loginToken = auth.loginToken;
      const { businessItemFlow } = businesses;

      const headers = {
        'X-Auth-Token': loginToken,
      };

      const response = await backend.put(`/marketplace-service/api/v1/items/${itemId}`, update, { headers }, ); 

      if (response.status === 200) {
        const updatedBusinessItem: BusinessItem = response.data;
        dispatch(editSelectedBusinessItemSuccess(updatedBusinessItem));
        if (refreshFlow) {
          dispatch(selectBusinessItem(response.data));
          dispatch(updateBusinessItemInput({...businessItemFlow, ...response.data}))
        }
      } else {
        dispatch(editSelectedBusinessItemFailure('Failed to edit selected item.'));
      }
    } catch (error) {
      console.error('Failed to edit selected business:', error);
      dispatch(editSelectedBusinessItemFailure('An error occurred while updating selected item.'));
    }
  };
};