import React from 'react';
import { connect } from "react-redux";
import { ReduxState } from "../../store/state";
import {
  Table, Tbody, Tr, Th, Td, Text, Button, Icon, Divider, Tag, TagLabel
} from '@chakra-ui/react';
import { AddIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { MdStar, MdEdit } from 'react-icons/md';
import moment from 'moment';
import { Order } from '../../store/orders/types';
import { asyncOrderEdit, fetchOrders, fetchOrderHistory } from "../../store/orders/initiators";

interface ViewOrderDeliveryDetailsProps {
  selectedOrder: Order | null;
  asyncOrderEdit: (orderId: string, updatedOrderData: any) => void;
  fetchOrders: (more: boolean) => void;
  fetchOrderHistory: (orderId: string) => void;
}

class ViewOrderDeliveryDetails extends React.Component<ViewOrderDeliveryDetailsProps> {
  terminalStatuses = [
    'delivered',
    'cancelled',
    'failed'
  ];

  unassign = async () => {
    const updatedOrderData = {
      ...this.props.selectedOrder,
      assigneeId: null,
      status: 'unassigned'
    };

    const orderId = this.props.selectedOrder?._id;
    if (orderId) {
      await this.props.asyncOrderEdit(orderId, updatedOrderData);
      this.props.fetchOrderHistory(orderId);
      this.props.fetchOrders(false);
    }
  }

  render() {
    const order = this.props.selectedOrder;
    if (order) {
      return (
        <>
        <Table size="sm" style={{ tableLayout: 'fixed' }}>
          <Tbody>
            <Tr>
            <Th textAlign="right">Delivery Type</Th>
            <Td textAlign="right"><Tag borderRadius={'full'}>{order.deliveryType}</Tag></Td>
            </Tr>
            <Tr>
            <Th textAlign="right">Channel</Th>
            <Td textAlign="right"><Tag borderRadius={'full'}>{order.channel}</Tag></Td>
            </Tr>
            <Tr>
              <Th textAlign="right">Distance</Th>
              <Td textAlign="right">{order.distance.toFixed(3)} km</Td>
            </Tr>
            <Tr>
              <Th textAlign="right">Target Delivery Time</Th>
              <Td textAlign="right">
                {
                  order.targetDeliveryTime 
                  ?
                  moment(new Date(order.targetDeliveryTime)).format(`YYYY-MM-DD h:mm a`)
                  :
                  <Text as='i'>No target time</Text>
                }
              </Td>
            </Tr>
            <Tr>
              <Th textAlign="right">Actual Delivery Time</Th>
              <Td textAlign="right">
                {
                  order.deliveredAt
                  ?
                  moment(new Date(order.deliveredAt)).format(`YYYY-MM-DD h:mm a`)
                  :
                  <Text as='i'>Not delivered</Text>
                }
              </Td>
            </Tr>
            <Tr>
              <Th textAlign="right">Pickup Instruction</Th>
              <Td textAlign="right">
                {
                  order.pickupInstruction
                  ?
                  `${order.pickupInstruction}`
                  :
                  <Text as='i'>No pickup instruction</Text>
                }
              </Td>
            </Tr>
            <Tr>
              <Th textAlign="right">Delivery Instruction</Th>
              <Td textAlign="right">
                {
                  order.deliveryInstruction
                  ?
                  `${order.deliveryInstruction}`
                  :
                  <Text as='i'>No delivery instruction</Text>
                }
              </Td>
            </Tr>
            <Tr>
              <Th textAlign="right">Payment Methods</Th>
              <Td textAlign="right">{
                order.data.paymentMethods && order.data.paymentMethods.length ?
                order.data.paymentMethods.map((method: any) => (
                  <Tag 
                    key={method.name}
                    size={'sm'} 
                    ml={1} mt={1}
                    borderRadius={15} 
                    colorScheme="orange"
                  >
                    <TagLabel>{method.name}: {method.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TagLabel>
                  </Tag>
                )) :
                <Text as='i'>No payment methods</Text>
              }</Td>
            </Tr>
            <Tr>
              <Th textAlign="right">Purchase Methods</Th>
              <Td textAlign="right">{
                order.data.purchaseMethods && order.data.purchaseMethods.length ?
                order.data.purchaseMethods.map((method: any) => (
                  <Tag 
                    key={method.name}
                    size={'sm'} 
                    ml={1} mt={1}
                    borderRadius={15} 
                    colorScheme="orange"
                  >
                    <TagLabel>{method.name}: {method.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TagLabel>
                  </Tag>
                )) :
                <Text as='i'>No purchase methods</Text>
              }</Td>
            </Tr>
          </Tbody>
        </Table>
        <Divider />
        </> 
      );     
    }
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { selectedOrder } = state.orders;
  return { selectedOrder };
};

export default connect(mapStateToProps, {
  asyncOrderEdit,
  fetchOrders,
  fetchOrderHistory
})(ViewOrderDeliveryDetails);