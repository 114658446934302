import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Heading,
  Container,
  Stack,
  Card,
  CardBody,
  Center
} from '@chakra-ui/react';

interface NoContentPageProps {
  noContentText?: string;
  noContentButton?: React.ReactNode
}

class NoContentPage extends Component<NoContentPageProps> {
  static defaultProps: NoContentPageProps = {
    noContentText: 'Nothing Found',
  };

  renderNoContentPage = () => {
    const { noContentText, noContentButton } = this.props;

    return (
      <>
        <Container maxW={'2xl'} pt={10}>
          <Card style={{ borderRadius: 25 }}>
            <CardBody>
              <Stack as={Box} textAlign={'center'}>
                <Heading
                  fontWeight={500}
                  fontSize={{ base: 'lg', sm: 'xl', md: '2xl' }}
                  lineHeight={'100%'}
                >
                  {noContentText} <br />
                </Heading>
                <img src={'/assets/images/no-items-box.png'} alt="No Items Found" />
              </Stack>
              { noContentButton && (
                <Center>
                  {noContentButton}
                </Center>
              )}
            </CardBody>
          </Card>
        </Container>
      </>
    );
  };

  render() {
    return <div>{this.renderNoContentPage()}</div>;
  }
}

export default NoContentPage;
