import React, { useState } from 'react';
import { connect } from "react-redux";
import {   
  Table,
  Tbody,
  Tr, Th, Td,
  Button,
  IconButton,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  Flex,
} from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';

import { ReduxState } from "../../store/state";
import { BusinessItemFlow, Business, BusinessItem } from '../../store/businesses/types';
import AppDropdown from '../global/dropdown/AppDropdown';
import { AddIcon } from '@chakra-ui/icons';
import DropdownBusinessItemProcessingDays from './DropdownBusinessItemProcessingDays';
import QuickNumberForm from '../global/forms/QuickNumberForm';
import { MdEdit } from 'react-icons/md';

interface ViewBusinessItemProcessingProps {
  handlePageDataChange: (change: any) => void;
  businessItemFlow: BusinessItemFlow;
  selectedBusiness: Business | null;
  selectedBusinessItem: BusinessItem | null;
}

const ViewBusinessItemProcessing: React.FC<ViewBusinessItemProcessingProps> = ({
  handlePageDataChange,
  businessItemFlow,
  selectedBusiness,
  selectedBusinessItem,
}) => {

  const [editLeadTimeFormOpen, setEditLeadTimeFormOpen] = useState(false);

  const renderPageData = () => {
    const { pageData, pageKey } = businessItemFlow;
    if (pageKey && pageData) {
      return (
        <>
        <Table>
        <Tbody>
          <Tr>
            <Th colSpan={2} textAlign={'center'} fontSize={'lg'}>Processing</Th>
          </Tr>
          <Tr>
            <Th>Processing Days</Th>
            <Td>
              {pageData.processingDays.map((day, index) => (
                <Tag borderRadius={'full'} mr={2} mt={2} colorScheme='orange'>
                  <TagLabel>{day}</TagLabel>
                  {pageData.processingDays.length > 1 &&(
                  <TagCloseButton onClick={() => {
                      let processingDays = pageData.processingDays;
                      processingDays.splice(index, 1);
                      handlePageDataChange({ processingDays });
                    }}/>)}
                  </Tag>
              ))}
            <AppDropdown items={<DropdownBusinessItemProcessingDays item={selectedBusinessItem} business={selectedBusiness}/>}>
              <IconButton 
                aria-label='Add' 
                borderRadius={10} 
                size='xs' variant={'ghost'} 
              >
                <AddIcon />
              </IconButton>
            </AppDropdown>    
            </Td>
          </Tr>
          <Tr>
            <Th>Lead Time</Th>
            <Td>
            {editLeadTimeFormOpen ?
              <QuickNumberForm
                initialNumber={pageData.leadTimeHours}
                minLimit={1}
                maxLimit={2000}
                onSubmit={(leadTimeHours: number) => {
                  handlePageDataChange({ leadTimeHours })
                  setEditLeadTimeFormOpen(false);
                }} 
                onClose={() => setEditLeadTimeFormOpen(false)}
              />
              :
              <Flex flexDirection={'row'}>
              <Text>{pageData.leadTimeHours ? `${pageData.leadTimeHours} hour${pageData.leadTimeHours !== 1 ? 's' : ''}` : null}</Text>
              <IconButton 
                aria-label='Edit' 
                borderRadius={10} 
                size='xs' ml={1} variant={'ghost'} 
                onClick={() => {
                  setEditLeadTimeFormOpen(true)
                }}
              >
                <MdEdit />
              </IconButton>
              </Flex>
              }            
            </Td>
          </Tr>
        </Tbody>
        </Table>
        </>
      )
    } else {
      return null;
    }

  }
  if (selectedBusinessItem && selectedBusiness && businessItemFlow) {
    return (
      <div>{renderPageData()}</div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { businessItemFlow, selectedBusiness, selectedBusinessItem } = state.businesses;
  return { businessItemFlow, selectedBusiness, selectedBusinessItem };
};

export default connect(mapStateToProps, {
})(ViewBusinessItemProcessing);
