import { 
  OrdersActionTypes, 
  Order, 
  OrderHistory, 
} from '../types';
import { Transaction } from '../../transactions/types';


// Action to select a specific order
export const selectOrder = (order: Order): OrdersActionTypes => ({
  type: 'SELECT_ORDER',
  payload: order,
});

// Action to fetch order transactions successfully
export const getOrderTransactionsSuccess = (transactions: Transaction[]): OrdersActionTypes => ({
  type: 'GET_ORDER_TRANSACTIONS_SUCCESS',
  payload: transactions,
});

// Action to handle failed order transaction fetch
export const getOrderTransactionsFailure = (error: string): OrdersActionTypes => ({
  type: 'GET_ORDER_TRANSACTIONS_FAILURE',
  payload: error,
});

// Action to fetch order history successfully
export const getOrderHistorySuccess = (history: OrderHistory[]): OrdersActionTypes => ({
  type: 'GET_ORDER_HISTORY_SUCCESS',
  payload: history,
});

// Action to handle failed order history fetch
export const getOrderHistoryFailure = (error: string): OrdersActionTypes => ({
  type: 'GET_ORDER_HISTORY_FAILURE',
  payload: error,
});