import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tr, Td, Button, Text, Flex, Icon, IconButton } from '@chakra-ui/react';
import { MdGpsFixed, MdMoped, MdSchedule, MdShoppingCart, MdStar } from 'react-icons/md';
import { Order as IOrder, OrderModals } from '../../store/orders/types';
import { 
  toggleViewOrderModal, 
  selectOrder, 
} from '../../store/orders/actions';
import {
  fetchOrderTransactions, 
  fetchOrderHistory
} from '../../store/orders/initiators';
import { ReduxState } from '../../store/state';

const allowedStatusTypes = [
  'created',
  'awaiting payment',
  'accepted',
  'started',
  'picked up',
  'on the way',
  'delivered',
  'cancelled',
  'failed'
];

const terminalStatuses = [
  'delivered',
  'cancelled',
  'failed'
];

interface OrderRowProps {
    order: IOrder;
    toggleViewOrderModal: () => void;
    selectOrder: (order: IOrder) => void;
    fetchOrderTransactions: (orderId: string) => void;
    fetchOrderHistory: (orderId: string) => void;
    orderModals: OrderModals;
}

class OrderRow extends React.Component<OrderRowProps> {
  getStatusColor = (status: string) => {
    if (allowedStatusTypes.includes(status)) {
      switch (status) {
        case 'cancelled':
        case 'failed':
          return 'red';
        case 'awaiting payment':
          return 'yellow';
        case 'delivered':
          return 'green';
        default:
          return 'gray';
      }
    }
    return 'gray'; 
  }

  handleSelection = () => {
    const { order } = this.props;
    this.props.selectOrder(order);
    this.props.toggleViewOrderModal();
    this.props.fetchOrderTransactions(order._id);
    this.props.fetchOrderHistory(order._id);
  }

  formatCurrency = (currency: string, amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    }).format(amount);
  }
  
  pickupText = () => {
    const { order } = this.props;
    if (order && order.sourceType === 'businessLocation' && order.sourceBusinessLocation && order.sourceBusinessLocation.business) {
      const address = order.sourceBusinessLocation.address;
      return `${address.length > 32 ? `${address.slice(0, 32)}...` : address}`
    }
    return null;
  }

  checkIfLate = (order: IOrder) => {
    let isLate = false;
    const currentTime = new Date();
    if (order.status === 'delivered' && order.deliveredAt && order.targetDeliveryTime) {
      const deliveredTime = new Date(order.deliveredAt);
      const targetTime = new Date(order.targetDeliveryTime);
      isLate = deliveredTime.getTime() > targetTime.getTime()
    } else if (order.targetDeliveryTime) {
      const targetTime = new Date(order.targetDeliveryTime);
      isLate = currentTime.getTime() > targetTime.getTime()      
    }
    return isLate;
  }

  formatDeliveryTime = (order: IOrder) => {
    const currentTime = new Date();
    const deliveredTime = new Date(order.deliveredAt);
    let targetDeliveryTime: any = null;
    if (order.targetDeliveryTime) {
      targetDeliveryTime = new Date(order.targetDeliveryTime);
    }

    if (order.status === 'delivered') {
      const timeDiffDelivered = moment.duration(currentTime.getTime() - deliveredTime.getTime());

      // Check if deliveredTime is more than 48 hours in the past
      if (timeDiffDelivered.asHours() > 48) {
        return moment(deliveredTime).format('MMM D');
      }

      // Check if deliveredTime is more than a year in the past
      if (timeDiffDelivered.asYears() >= 1) {
        return moment(deliveredTime).format('MMM D, YYYY');
      }

      // Otherwise, use fromNow
      return moment(deliveredTime).fromNow();
    } else {
      const timeDiffTarget = moment.duration(currentTime.getTime() - targetDeliveryTime.getTime());

      // Check if targetDeliveryTime is more than 48 hours in the past
      if (timeDiffTarget.asHours() > 48) {
        return moment(targetDeliveryTime).format('MMM D');
      }

      // Check if targetDeliveryTime is more than a year in the past
      if (timeDiffTarget.asYears() >= 1) {
        return moment(targetDeliveryTime).format('MMM D, YYYY');
      }

      // Otherwise, use fromNow
      return moment(targetDeliveryTime).fromNow();
    }
  }

  formatAcceptedTime = (order: IOrder) => {
    const currentTime = new Date();
    const acceptedTime = new Date(order.acceptedAt);
  
    const timeDiffAccepted = moment.duration(currentTime.getTime() - acceptedTime.getTime());
  
    // Check if acceptedTime is more than 48 hours in the past
    if (timeDiffAccepted.asHours() > 48) {
      return moment(acceptedTime).format('MMM D');
    }
  
    // Check if acceptedTime is more than a year in the past
    if (timeDiffAccepted.asYears() >= 1) {
      return moment(acceptedTime).format('MMM D, YYYY');
    }
  
    // Otherwise, use fromNow
    return moment(acceptedTime).fromNow();
  }
  
  render() {
    const { order } = this.props;
    // const acceptedTime = new Date(order.acceptedAt);
    // const deliveredTime = new Date(order.deliveredAt);
    // const targetDeliveryTime = new Date(order.targetDeliveryTime)

    return (
      <Tr key={order._id} style={{ background: 'white' }} boxShadow="0 2px 8px rgba(0, 0, 0, 0.2)">
        <Td>
          <Button size={'xs'} leftIcon={<MdShoppingCart />} borderRadius={'full'} onClick={this.handleSelection}>
            <Text as={'u'}>{order.orderNumber}</Text>
          </Button>
        </Td>
        <Td>
          <Text fontSize='sm' color='gray.600'>{order.user ? `${order.user.firstName} ${order.user.lastName && order.user.lastName.length > 0 ? order.user.lastName[0]+'.' : null}` : null}</Text>
        </Td>
        <Td>
          <Text fontSize='sm' color='gray.600'>{this.pickupText()}</Text>
        </Td>
        <Td>
          <Text fontSize='sm' color='gray.600' textAlign={'right'}>{this.formatCurrency(order.currency, order.orderExpenses - order.pagesTransactionFee)}</Text>
        </Td>
        {/* <Td>
          <Text fontSize='sm' color='gray.600' style={{ textAlign: 'right' }}>{this.formatCurrency(order.currency, order.orderExpenses)}</Text>
        </Td> */}
        {/* <Td>
          <Text fontSize='sm' color='gray.600' style={{ textAlign: 'right' }}>{this.formatAcceptedTime(order)}</Text>
        </Td> */}
        <Td style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Text fontSize='sm' color='gray.600' style={{ marginRight: '0.5rem' }}>
            {this.formatDeliveryTime(order)}
          </Text>
          {!['failed', 'cancelled'].includes(order.status) && (
            this.checkIfLate(order) ? <MdSchedule color={'CA0000'} /> : <MdSchedule color={'green'} />
          )}
        </Td>
        <Td>
          <Flex>
            <Button
              colorScheme={this.getStatusColor(order.status)}
              size='xs'
              ml={1}
            >
              {order.status}
            </Button>
            <Text paddingLeft={1}>{order.rating ? <Icon as={MdStar} color='gold' boxSize={6}/> : null}</Text>
          </Flex>

        </Td>
      </Tr>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { selectedOrder, orderModals } = state.orders;
  return { selectedOrder, orderModals };
};

export default connect(mapStateToProps, { 
  selectOrder,
  toggleViewOrderModal,
  fetchOrderTransactions,
  fetchOrderHistory
})(OrderRow);
