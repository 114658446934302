import React, { useState, useEffect } from 'react';
import 'react-phone-number-input/style.css'
import { connect } from 'react-redux';
import { ReduxState } from '../../store/state';
import {
  Text,
  Card,
  CardBody,
  Button,
} from '@chakra-ui/react';
import ReactCodeInput from 'react-code-input';
import { setLoginInputs } from '../../store/auth/actions';
import { UserLoginFlow } from '../../store/auth/types';
import AppButton from '../global/forms/AppButton';
import { colors } from '../../assets/colors';
import { asyncCheckVerificationCode, asyncRequestVerificationCode } from '../../store/auth/initiators';
import { User } from '../../store/user/types';

interface UserWelcomeVerifyFormProps {
  loginFlow: UserLoginFlow;
  user: User | null;
  errors: any;
  setLoginInputs: (update: UserLoginFlow) => void;
  asyncCheckVerificationCode: (navigation: any, location: any) => void;
  asyncRequestVerificationCode: (altMethod?: string) => void;
  goBack: () => void;
  goForward: () => void;
}

const UserWelcomeVerifyForm: React.FC<UserWelcomeVerifyFormProps> = ({
  loginFlow, errors, user, 
  setLoginInputs, asyncCheckVerificationCode, asyncRequestVerificationCode, goBack, goForward
}) => {

  const { verificationCode } = loginFlow;
  const isValid = verificationCode.length === 6
  const cellCount = 6;
  const loginError = errors.login;
  const [isRequestButtonDisabled, setIsRequestButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    if (user?.status === 'active' || user?.status === 'pending') {
      asyncRequestVerificationCode()
    }
  }, []); 

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setIsRequestButtonDisabled(false);
          return 30;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [isRequestButtonDisabled]);

  const renderWelcomeHeader = () => {
    if ( user && user.status === 'active') {
      return (
        <>
          <Text fontWeight={'bold'}>Welcome Back!</Text>
          <Text pb={5}>We have sent you a 6 digit code. Please enter it here to complete sign in.</Text>        
        </>
      )
    } else if (user && user.status === 'pending') {
      return (
        <>
          <Text fontWeight={'bold'}>We're glad to meet you!</Text>
          <Text pb={5}>We have sent you a 6 digit code. Please enter it here to verify your number.</Text>        
        </>
      )    
    } else if (user && user.status === 'inactive') {
      return (
        <>
          <Text fontWeight={'bold'}>We're sorry.</Text>
          <Text pb={5}>This phone number cannot be used.</Text>        
        </>
      )  
    }
  }

  const renderCodeField = () => {
    if (!user || user?.status === 'inactive') {
      return null;
    }
    return (
      <>
        { loginError 
          ?
          <Text color='red'>{loginError}</Text>
          :
          null
        }
        <ReactCodeInput
          name={"verification-code"}
          inputMode={'numeric'}
          value={verificationCode}
          onChange={(text) => setLoginInputs({...loginFlow, verificationCode: text})}
          fields={cellCount}

        />
        <Text mt={2} fontSize={'sm'}>
          Didn't receive a code? 
          <Button
            onClick={() => {
              asyncRequestVerificationCode('whatsapp');
              setLoginInputs({ ...loginFlow, verificationCode: ''});
              setIsRequestButtonDisabled(true);
            }}
            size={'xs'}
            ml={2}
            mr={2}
            isDisabled={isRequestButtonDisabled}
          >
            {isRequestButtonDisabled ? `Request [WhatsApp] (${timer}s)` : 'Request [WhatsApp]'}
          </Button>
          <Button
            onClick={() => {
              asyncRequestVerificationCode();
              setLoginInputs({ ...loginFlow, verificationCode: ''});
              setIsRequestButtonDisabled(true);
            }}
            size={'xs'}
            ml={2}
            mr={2}
            isDisabled={isRequestButtonDisabled}
          >
            {isRequestButtonDisabled ? `Request [SMS] (${timer}s)` : 'Request [SMS]'}
          </Button>
          a new one.
        </Text>
      </>  
    )
  }

  const isDisabled = (errors && errors.enterVerificationCode && errors.enterVerificationCode.length) || !isValid
  return (
    <Card borderRadius={20}>
      <CardBody padding={8}>
        {renderWelcomeHeader()}
        {renderCodeField()}
        <AppButton text={'Back'} onClick={() => goBack()} color={colors.shade1} isDisabled={isRequestButtonDisabled}/>
        <AppButton 
          text={'Next'} 
          onClick={() => {
              asyncCheckVerificationCode({ next: goForward }, {})
            }} 
          isDisabled={isDisabled}/>
      </CardBody>
    </Card>
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { loginFlow, errors } = state.auth;
  const { user } = state.user;
  return { loginFlow, user, errors };
};

export default connect(mapStateToProps, {
  setLoginInputs,
  asyncCheckVerificationCode,
  asyncRequestVerificationCode
})(UserWelcomeVerifyForm);
