import { BusinessesActionTypes, SelectedBusinessOrderStats } from '../types';

// Action types related to business informations

export const getSelectedBusinessInformationSuccess = (payload: SelectedBusinessOrderStats): BusinessesActionTypes => ({
    type: 'GET_SELECTED_BUSINESS_INFORMATION_SUCCESS',
    payload
});

export const getSelectedBusinessInformationFailure = (payload: string): BusinessesActionTypes => ({
    type: 'GET_SELECTED_BUSINESS_INFORMATION_FAILURE',
    payload,
});

// Action for successful business market events retrieval
export const getBusinessMarketEventsSuccess = (payload: any): BusinessesActionTypes => ({
    type: 'GET_SELECTED_BUSINESS_MARKET_EVENTS_SUCCESS',
    payload,
});
  
// Action for failed business market events retrieval
export const getBusinessMarketEventsFailure = (error: string): BusinessesActionTypes => ({
    type: 'GET_SELECTED_BUSINESS_MARKET_EVENTS_FAILURE',
    payload: error,
});