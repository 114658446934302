import {
    BusinessesActionTypes,
    BusinessModals
  } from '../types';
  

  export const setSelectedBusinessView = (payload:  'information' | 'locations' | 'users' | 'items' | 'ratings'): BusinessesActionTypes => ({
    type: 'SET_SELECTED_BUSINESS_VIEW',
    payload
  });

  export const setSelectedBusinessItemView = (payload:   'information' | 'inventory' | 'contact' | 'design' | 'discounts' | 'payment' | 'processing' | 'delivery' | 'feedback'): BusinessesActionTypes => ({
    type: 'SET_SELECTED_BUSINESS_ITEM_VIEW',
    payload
  });

  // Action to set loading state for different sections of businesses
  export const setBusinessesLoading = (payload: boolean): BusinessesActionTypes => ({
    type: 'SET_BUSINESSES_LOADING',
    payload
  });

  export const setBusinessInformationLoading = (payload: boolean): BusinessesActionTypes => ({
    type: 'SET_BUSINESS_INFORMATION_LOADING',
    payload
  });

  export const setBusinessLocationsLoading = (payload: boolean): BusinessesActionTypes => ({
    type: 'SET_BUSINESS_LOCATIONS_LOADING',
    payload
  });
  
  export const setBusinessUsersLoading = (payload: boolean): BusinessesActionTypes => ({
    type: 'SET_BUSINESS_USERS_LOADING',
    payload
  });
  
  export const setBusinessItemsLoading = (payload: boolean): BusinessesActionTypes => ({
    type: 'SET_BUSINESS_ITEMS_LOADING',
    payload
  });
  
  // Action to toggle business modals
  export const toggleCreateBusinessLocationModal = (): BusinessesActionTypes => ({
    type: 'TOGGLE_CREATE_BUSINESS_LOCATION_MODAL'
  });
  
  export const toggleEditBusinessLocationModal = (): BusinessesActionTypes => ({
    type: 'TOGGLE_EDIT_BUSINESS_LOCATION_MODAL'
  });
  
  export const toggleViewBusinessLocationModal = (): BusinessesActionTypes => ({
    type: 'TOGGLE_VIEW_BUSINESS_LOCATION_MODAL'
  });
  
  export const toggleCreateBusinessItemModal = (): BusinessesActionTypes => ({
    type: 'TOGGLE_CREATE_BUSINESS_ITEM_MODAL'
  });
  
  export const toggleEditBusinessItemModal = (): BusinessesActionTypes => ({
    type: 'TOGGLE_EDIT_BUSINESS_ITEM_MODAL'
  });
  
  export const toggleViewBusinessItemModal = (): BusinessesActionTypes => ({
    type: 'TOGGLE_VIEW_BUSINESS_ITEM_MODAL'
  });
  