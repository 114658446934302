import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../store/state';
import { User } from '../../store/user/types';
import { asyncWelcomeUser, checkLoginCookies, asyncLogout } from '../../store/auth/initiators';
import UserPhoneNumberForm from '../auth/UserPhoneNumberForm';
import UserWelcomeVerifyForm from '../auth/UserWelcomeVerifyForm';
import AppButton from '../global/forms/AppButton';
import { UserLoginFlow } from '../../store/auth/types';
import { Text } from '@chakra-ui/react';

interface LoginFlowProps {
  isLoggedIn: boolean;
  user: User | null;
  loginToken: string | null;
  loginFlow: UserLoginFlow;
  checkLoginCookies: () => void;
  asyncWelcomeUser: (altMethod?: string) => void;
  asyncLogout: (tokenId: string, userId: string) => void;
}

const LoginFlow: React.FC<LoginFlowProps> = ({ 
  user,
  loginToken,
  isLoggedIn,
  loginFlow,
  checkLoginCookies,
  asyncWelcomeUser,
  asyncLogout 
}) => {

  const [authStep, setAuthStep] = useState(0)
  // 0 - Continue Button
  // 1 - Enter Phone Number (skipped if isLoggedIn)
  // 2 - Verify Phone Number (skipped if isLoggedIn)
  // 3 - if isLoggedIn - Greeting

  useEffect(() => {
    checkLoginCookies();
  }, [checkLoginCookies]);

  if (authStep === 0) {
    return (
      <AppButton  text={'Continue'} onClick={() => setAuthStep(1)}/>
    );
  } else if (authStep === 1) {
    if (isLoggedIn) {
      setAuthStep(3)
    }
    return (
          <UserPhoneNumberForm 
            goBack={() => setAuthStep(0)}
            goForward={() => {
              asyncWelcomeUser()
              setAuthStep(2)
            }}
            goForwardAltWA={() => {
              asyncWelcomeUser('whatsapp')
              setAuthStep(2)
            }}
          />
          );
  } else if (authStep === 2) {
    return (
          <UserWelcomeVerifyForm 
            goBack={() => setAuthStep(1)}
            goForward={() => setAuthStep(3)}
          />
          )
  } else if (authStep === 3) {
    if (!isLoggedIn) {
      setAuthStep(1)
    }
    return null;
  } else {
    return null;
  }
};

const mapStateToProps = (state: ReduxState) => {
  const { isLoggedIn, loginToken, loginFlow } = state.auth;
  const { user } = state.user;
  return { isLoggedIn, user, loginToken, loginFlow };
};

export default connect(mapStateToProps, {
  checkLoginCookies,
  asyncWelcomeUser,
  asyncLogout
})(LoginFlow);