import React, { useState } from 'react';
import { connect } from "react-redux";
import {   
  Text,
  Table,
  Tbody,
  Tr, Th, Td,
  IconButton,
  Switch,
  Flex,
  Button,
  TagLabel,
  Tag,
} from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';

import { MdEdit } from 'react-icons/md';
import { ReduxState } from "../../store/state";
import { BusinessItemFlow, Business, BusinessItem } from '../../store/businesses/types';
import QuickNumberForm from '../global/forms/QuickNumberForm';
import AppDropdown from '../global/dropdown/AppDropdown';
import DropdownBusinessItemDiscountMode from './DropdownBusinessItemDiscountMode';
import { formatCurrency } from '../global/data/formatCurrencyHelper';
import QuickTextForm from '../global/forms/QuickTextForm';

interface ViewBusinessItemDiscountsProps {
  handlePageDataChange: (change: any) => void;
  businessItemFlow: BusinessItemFlow;
  selectedBusiness: Business | null;
  selectedBusinessItem: BusinessItem | null;
}

const ViewBusinessItemDiscounts: React.FC<ViewBusinessItemDiscountsProps> = ({
  handlePageDataChange,
  businessItemFlow,
  selectedBusiness,
  selectedBusinessItem,
}) => {
  const [editDeliveryDiscountFormOpen, setEditDeliveryDiscountFormOpen] = useState(false);
  const [editDiscountFormOpen, setEditDiscountFormOpen] = useState(false);
  const [editDiscountNameFormOpen, setEditDiscountNameFormOpen] = useState(false);
  const [editStrikethroughFormOpen, setEditStrikethroughFormOpen] = useState(false);

  const renderDiscount = (discountType: 'delivery' | 'product') => {
    const { pageData } = businessItemFlow;
    if (pageData && selectedBusinessItem) {
      const { deliveryDiscountMode, deliveryDiscount, discountMode, discount } = pageData;
      let mode = deliveryDiscountMode;
      let discountedValue = deliveryDiscount;
      let formOpen = editDeliveryDiscountFormOpen;
      let setFormOpen = setEditDeliveryDiscountFormOpen;
      if (discountType === 'product') {
         mode = discountMode;
         discountedValue = discount || 0;
         formOpen = editDiscountFormOpen;
         setFormOpen = setEditDiscountFormOpen;
      }
      let maxValue = 0;
      let display = discountedValue.toString();
      if (mode === 'amount') {
        maxValue = 999999; 
        display = formatCurrency(selectedBusinessItem.currency, discountedValue);
      }
      if (mode === 'percent') {
        maxValue = 100
        display = `${discountedValue}%`
      }
      return (
        <Flex flexDirection={'row'} paddingLeft={2}>
          {formOpen ?
          <QuickNumberForm
            initialNumber={discountedValue}
            minLimit={1}
            maxLimit={maxValue}
            onSubmit={(discountedValue: number) => {
              let change: any = { deliveryDiscount: discountedValue }
              if (discountType === 'product') {
                change = { discount: discountedValue}
              }
              handlePageDataChange(change)
              setFormOpen(false);
            }} 
            onClose={() => setFormOpen(false)}
          />
          :
          <>
          <Text>{display}</Text>
          <IconButton 
            aria-label='Edit' 
            borderRadius={10} 
            size='xs' ml={1} variant={'ghost'} 
            onClick={() => {
              setFormOpen(true)
            }}
          >
            <MdEdit />
          </IconButton>
          </>
          }      
        </Flex>
      )
    } else {
      return null;
    }

  }

  const renderStrikethrough = () => {
    const { pageData } = businessItemFlow;
    if (pageData && selectedBusinessItem) {
      const { strikeThroughMode, strikeThroughNumber } = pageData;

      let maxValue = 0;
      let display = strikeThroughNumber.toString();
      if (strikeThroughMode === 'amount') {
        maxValue = 999999; 
        display = formatCurrency(selectedBusinessItem.currency, strikeThroughNumber);
      }
      if (strikeThroughMode === 'percent') {
        maxValue = 100
        display = `${strikeThroughNumber}%`
      }
      return (
        <Flex flexDirection={'row'} paddingLeft={2} alignItems={'center'}>
          {editStrikethroughFormOpen ?
          <QuickNumberForm
            initialNumber={strikeThroughNumber}
            minLimit={1}
            maxLimit={maxValue}
            onSubmit={(strikeThroughNumber: number) => {
              handlePageDataChange({ strikeThroughNumber: strikeThroughNumber.toFixed(0) })
              setEditStrikethroughFormOpen(false);
            }} 
            onClose={() => setEditStrikethroughFormOpen(false)}
          />
          :
          <>
          <Text>{display}</Text>
          <IconButton 
            aria-label='Edit' 
            borderRadius={10} 
            size='xs' ml={1} variant={'ghost'} 
            onClick={() => {
              setEditStrikethroughFormOpen(true)
            }}
          >
            <MdEdit />
          </IconButton>
          </>
          }      
        </Flex>
      )
    } else {
      return null;
    }

  }
  const renderPageData = () => {
    const { pageData, pageKey } = businessItemFlow;
    if (pageKey && pageData) {
      return (
        <>
        <Table>
        <Tbody>
          <Tr>
            <Th colSpan={2} textAlign={'center'} fontSize={'lg'}>Discounts</Th>
          </Tr>
          <Tr>
            <Th>Delivery Discount</Th>
            <Td textAlign={'right'}>
              <Flex flexDirection={'row'} alignItems={'center'}>
              <AppDropdown items={<DropdownBusinessItemDiscountMode item={selectedBusinessItem} business={selectedBusiness} updateKey='deliveryDiscountMode'/>}>
                <Button
                  size="xs"
                >
                  {pageData.deliveryDiscountMode  ? `by ${pageData.deliveryDiscountMode}` : 'not applied'}
                </Button>
              </AppDropdown>
              { pageData.deliveryDiscountMode && (
                renderDiscount('delivery')
              )}      
              </Flex>        
            </Td>
          </Tr>
          <Tr>
            <Th>Product Discount</Th>
            <Td textAlign={'right'}>
              <Flex flexDirection={'row'} alignItems={'center'}>
              <AppDropdown items={<DropdownBusinessItemDiscountMode item={selectedBusinessItem} business={selectedBusiness} updateKey='discountMode'/>}>
                <Button
                  size="xs"
                >
                  {pageData.discountMode  ? `by ${pageData.discountMode}` : 'not applied'}
                </Button>
              </AppDropdown>
              { pageData.discountMode && (
                <>
                {renderDiscount('product')}
                { editDiscountNameFormOpen ?
                <QuickTextForm
                  initialText={pageData.discountName || 'Discount Name'}
                  minLimit={2} maxLimit={32}
                  onClose={() => setEditDiscountNameFormOpen(false)}
                  onSubmit={(discountName: string) => {
                    handlePageDataChange({ discountName })
                    setEditDiscountNameFormOpen(false)
                  }}
                />
                :
                <>
                <Tag borderRadius={'full'} colorScheme='orange'>
                  <TagLabel fontSize={'sm'}>
                    {pageData.discountName?.toUpperCase() || 'DISCOUNT NAME'}
                  </TagLabel>
                </Tag>
                <IconButton 
                  aria-label='Edit' 
                  borderRadius={10} 
                  size='xs' ml={1} variant={'ghost'} 
                  onClick={() => {
                    setEditDiscountNameFormOpen(true)
                  }}
                >
                  <MdEdit />
                </IconButton>
                </>
                }
                </>
              )}      
              </Flex>        
            </Td>
          </Tr>
          {/* <Tr>
            <Th>Allow Discount Stacking</Th>
            <Td>
              <Switch id='isChecked' isChecked={pageData.allowDiscountStacking} colorScheme="orange" mr={2} onChange={() => {
                handlePageDataChange({ allowDiscountStacking: !pageData.allowDiscountStacking });
                }}
              />                
            </Td>
          </Tr> */}
          <Tr>
            <Th>Show Strikethrough</Th>
            <Td>
              <Flex flexDirection={'row'} alignItems={'center'}>
                <Switch id='isChecked' isChecked={pageData.showStrikeThrough} colorScheme="orange" mr={2} onChange={() => {
                  handlePageDataChange({ showStrikeThrough: !pageData.showStrikeThrough });
                  }}
                />  
              <AppDropdown items={<DropdownBusinessItemDiscountMode item={selectedBusinessItem} business={selectedBusiness} updateKey='strikeThroughMode'/>}>
                <Button
                  size="xs"
                >
                  {pageData.strikeThroughMode  ? `by ${pageData.strikeThroughMode}` : 'not applied'}
                </Button>
              </AppDropdown>
                { pageData.showStrikeThrough && pageData.strikeThroughMode &&( renderStrikethrough())}  
              </Flex>            
            </Td>
          </Tr>
        </Tbody>
        </Table>
        </>
      )
    } else {
      return null;
    }

  }
  if (selectedBusinessItem && selectedBusiness && businessItemFlow) {
    return (
      <div>{renderPageData()}</div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { businessModals, businessItemFlow, selectedBusiness, selectedBusinessItem } = state.businesses;
  return { businessModals, businessItemFlow, selectedBusiness, selectedBusinessItem };
};

export default connect(mapStateToProps, {
})(ViewBusinessItemDiscounts);
