import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { 
  getSelectedBusinessInformationSuccess,
  getSelectedBusinessInformationFailure,
  setBusinessInformationLoading 
} from '../actions';

// Initiator for fetching business information 
export const fetchBusinessInformation = (businessId: string) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;
      // Construct the headers with X-Auth-Token
      const headers = {
        'X-Auth-Token': loginToken,
      };

      let url = `/commerce-service/api/v1/orders/business/stats/${businessId}`
      dispatch(setBusinessInformationLoading (true));
      const response = await backend.get(url,{ headers });

      if (response.status === 200) {
        const stats = response.data;
        dispatch(setBusinessInformationLoading (false));
        dispatch(getSelectedBusinessInformationSuccess(stats));
      } else {
        dispatch(setBusinessInformationLoading (false));
        dispatch(getSelectedBusinessInformationFailure('Failed to fetch business information .'));
      }
    } catch (error) {
      console.error('Error fetching business information :', error);
      dispatch(setBusinessInformationLoading (false));
      dispatch(getSelectedBusinessInformationFailure('An error occurred while fetching business information .'));
    }
  };
};