import { OrdersState, OrdersActionTypes } from "./types";

const initialState: OrdersState = {
  errors: {},
  selectedOrder: null,
  orders: [],
  currentOrders: [],
  selectedOrderHistory: [],
  selectedOrderTransactions: [],
  ordersQuery: {
    currentPage: null,
    itemsPerPage: null,
    totalPages: null,
    totalItems: null,
    search: null,
    sortBy: 'updatedAt',
    sortDirection: 'desc',
    page: null,
    status: null,
    currency: null,
    currentOrdersCount: null,
    minTargetDeliveryTime: null,
    maxTargetDeliveryTime: null,
    minDeliveredAt: null,
    maxDeliveredAt: null
  },
  ordersLoading: {
    search: false,
    selected: false
  },
  orderModals: {
    filterOrdersModalOpen: false,
    viewOrderModalOpen: false,
  },
  ordersViewMode: 'current',
};

const orderReducer = (state = initialState, action: OrdersActionTypes): OrdersState => {
  switch (action.type) {
    case 'UPDATE_ORDERS_QUERY':
      return {
        ...state,
        ordersQuery: action.payload,
      };
    case 'CLEAR_ORDERS_QUERY':
      return {
        ...state,
        ordersQuery: initialState.ordersQuery
      }
    case 'GET_ORDERS_SUCCESS':
      return {
        ...state,
        orders: action.payload.orders,
        ordersQuery: action.payload.ordersQuery,
      };
    case 'GET_ORDERS_FAILURE':
      return {
        ...state,
        errors: { getOrders: action.payload },
      };
    case 'GET_CURRENT_ORDERS_SUCCESS':
      return {
        ...state,
        currentOrders: action.payload.currentOrders,
        ordersQuery: action.payload.ordersQuery,
      };
    case 'GET_CURRENT_ORDERS_FAILURE':
      return {
        ...state,
        errors: { getCurrentOrders: action.payload },
      };
    case 'SELECT_ORDER':
      return {
        ...state,
        selectedOrder: action.payload,
      };
    case 'EDIT_SELECTED_ORDER_SUCCESS':
      return {
        ...state,
        selectedOrder: action.payload
      };
    case 'EDIT_SELECTED_ORDER_FAILURE':
      return {
        ...state,
        errors: { editSelectedOrder: action.payload }
      };
    case 'GET_ORDER_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        selectedOrderTransactions: action.payload,
      };
    case 'GET_ORDER_TRANSACTIONS_FAILURE':
      return {
        ...state,
        errors: { getOrderTransactions: action.payload },
      };
    case 'GET_ORDER_HISTORY_SUCCESS':
      return {
        ...state,
        selectedOrderHistory: action.payload,
      };
    case 'GET_ORDER_HISTORY_FAILURE':
      return {
        ...state,
        errors: { getOrderHistory: action.payload },
      };
    case 'SET_SEARCH_ORDERS_LOADING':
      return {
        ...state,
        ordersLoading: {
          ...state.ordersLoading,
          search: action.payload
        }
      };
    case 'SET_SELECTED_ORDER_LOADING':
      return {
        ...state,
        ordersLoading: {
          ...state.ordersLoading,
          selected: action.payload
        }
      };
    case 'TOGGLE_FILTER_ORDERS_MODAL':
      return {
        ...state,
        orderModals: {
          ...state.orderModals,
          filterOrdersModalOpen: !state.orderModals.filterOrdersModalOpen,
        },
      };
    case 'TOGGLE_VIEW_ORDER_MODAL':
      return {
        ...state,
        orderModals: {
          ...state.orderModals,
          viewOrderModalOpen: !state.orderModals.viewOrderModalOpen,
        },
      };
    case 'SET_ORDERS_VIEW_MODE':
      return {
        ...state,
        ordersViewMode: action.payload
      }
    default:
      return state;
  }
};

export default orderReducer;
