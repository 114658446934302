import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import {
  Business,
} from '../types';
import { 
  getBusinessesSuccess,
  getBusinessesFailure,
  setBusinessesLoading,
  selectBusiness
} from '../actions';

// Initiator for fetching user businesses
export const fetchBusinesses = (userId: string) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;

      // Construct the headers with X-Auth-Token
      const headers = {
        'X-Auth-Token': loginToken,
      };
      dispatch(setBusinessesLoading(true));
      const response = await backend.get(
        `/marketplace-service/api/v1/business-users/user/${userId}`,
        { headers }
      );

      if (response.status === 200) {
        const businesses: Business[] = response.data;
        dispatch(setBusinessesLoading(false));
        dispatch(getBusinessesSuccess(businesses));
        const defaultBusiness = businesses.find(business => business.default);
        if (defaultBusiness) {
          dispatch(selectBusiness(defaultBusiness));
        } else if (businesses.length > 0) { // If no default, select the first business
          dispatch(selectBusiness(businesses[0]));
        }

      } else {
        dispatch(setBusinessesLoading(false));
        dispatch(getBusinessesFailure('Failed to fetch user businesses.'));
      }
    } catch (error) {
      console.error('Error fetching user businesses:', error);
      dispatch(setBusinessesLoading(false));
      dispatch(
        getBusinessesFailure('An error occurred while fetching user businesses.')
      );
    }
  };
};