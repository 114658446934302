import React from "react";
import { connect } from "react-redux";
import { Button, Text, MenuItem } from "@chakra-ui/react";
import { Order } from "../../store/orders/types";
import { ReduxState } from "../../store/state";
import { asyncOrderEdit, fetchOrders, fetchOrderHistory } from "../../store/orders/initiators";

interface DropdownOrderStatusProps {
  asyncOrderEdit: (orderId: string, updatedOrderData: Order) => void;
  fetchOrders: (more: boolean) => void;
  fetchOrderHistory: (orderId: string) => void;
  order: Order;
}

class DropdownOrderStatus extends React.Component<DropdownOrderStatusProps> {
  
  updateOrderStatus(status: string) {
    const updatedOrderData = {
      ...this.props.order,
      status
    };
    const orderId = this.props.order._id;
    this.props.asyncOrderEdit(orderId, updatedOrderData);
    this.props.fetchOrderHistory(orderId);
    this.props.fetchOrders(false);
  }

  terminalStatuses = [
    'delivered',
    'cancelled',
    'failed'
  ];

  inProgressStatuses = [
    'pending assignment',
    'picked up',
    'started',
    'on the way',
  ];

  render() {
    if (![...this.terminalStatuses, ...this.inProgressStatuses].includes(this.props.order.status)) {
      var validStatuses = ['ready for pickup', 'cancelled'];
      if (this.props.order.status === 'created') {
        validStatuses = [...validStatuses, 'accepted', 'processing'];
      }
      if (this.props.order.status === 'accepted') {
        validStatuses = [...validStatuses, 'created', 'processing'];
      }
      if (this.props.order.status === 'processing') {
        validStatuses = [...validStatuses, 'created', 'accepted'];
      }
      if (this.props.order.status === 'awaiting payment') {
        validStatuses = ['cancelled'];
      }
      return (
        <>
        {validStatuses.map(status => {
          return (
            <MenuItem key={status}>
              <Button 
                variant='ghost' 
                size='sm' 
                onClick={() => this.updateOrderStatus(status)}
              >
                {`Mark as ${status}`}
              </Button>
            </MenuItem>
          );

        })}
        </>
      )
    } else {
      return (
        <MenuItem>
          <Text fontSize={'sm'}>Order #{this.props.order.orderNumber} is already transferred to Showfa Express.</Text>
        </MenuItem>
      )
    }
  }
}

const mapStateToProps = (state: ReduxState) => {
  return;
};

export default connect(mapStateToProps, {
  asyncOrderEdit,
  fetchOrders,
  fetchOrderHistory
})(DropdownOrderStatus);