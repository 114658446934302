import { Icon, IconProps, useBreakpointValue } from "@chakra-ui/react";


const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="71" cy="61" r="111" fill="#1E1E1E" />
      <circle cx="244" cy="106" r="139" fill="#039C0B" />
      <circle cy="291" r="139" fill="#DED304" />
      <circle cx="120.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="296.5" cy="317.5" r="101.5" fill="#DED304" />
      <circle cx="90.5" cy="458.5" r="101.5" fill="#039C0B" />
      <circle cx="476.5" cy="-0.5" r="101.5" fill="#1E1E1E" />
    </Icon>
  )
}

export default Blur;