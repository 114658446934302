import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';

import {
  OrderHistory,
} from '../types';
import {
  getOrderHistorySuccess,
  getOrderHistoryFailure,
} from '../actions';

// Thunk action to fetch order history
export const fetchOrderHistory = (orderId: string) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;

      // Construct the headers with X-Auth-Token
      const headers = {
        'X-Auth-Token': loginToken,
      };

      const response = await backend.get(`/commerce-service/api/v1/orders/${orderId}/history?describe=1`, { headers });

      if (response.status === 200) {
        const history: OrderHistory[] = response.data;
        dispatch(getOrderHistorySuccess(history));
      } else {
        dispatch(getOrderHistoryFailure('Failed to fetch order history.'));
      }
    } catch (error) {
      console.error('Error fetching order history:', error);
      dispatch(getOrderHistoryFailure('An error occurred while fetching order history.'));
    }
  };
};