import { Component } from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../store/state";
import Navbar from "../../components/navigation/Navbar";
import { Container } from "@chakra-ui/react";

import BusinessSummaryCard from "../../components/businesses/BusinessSummaryCard";
import { 
  Business, BusinessItem, BusinessItemFlow, BusinessLoading, BusinessLocation, 
  BusinessMarketEventSummary, 
  BusinessUser, SelectedBusinessAttributeDetails, SelectedBusinessOrderStats } from "../../store/businesses/types";
import { fetchBusinessLocations, fetchBusinessUsers, fetchBusinessInformation, fetchBusinessMarketEvents } from "../../store/businesses/initiators";
import { setBusinessInformationLoading, setBusinessLocationsLoading, setBusinessUsersLoading, 
  updateBusinessItemsQuery, selectBusinessItem, toggleViewBusinessItemModal, 
  setBusinessItemsLoading, selectBusinessLocation, updateBusinessItemInput 
} from "../../store/businesses/actions";
import { fetchBusinessItems } from "../../store/businesses/initiators";

interface BusinessesPageProps {
  selectedBusiness: Business | null;
  businessLoading: BusinessLoading;
  businessView: 'information' | 'locations' | 'users' | 'items' | 'ratings';
  selectedBusinessLocations: BusinessLocation[];
  selectedBusinessLocation: BusinessLocation | null;
  selectedBusinessUsers: BusinessUser[];
  selectedBusinessItems: BusinessItem[];
  selectedBusinessItem: BusinessItem | null;
  businessItemQuery: SelectedBusinessAttributeDetails;
  businessItemFlow: BusinessItemFlow;
  selectedBusinessOrderStats: SelectedBusinessOrderStats;
  selectedBusinessMarketEventSummary: BusinessMarketEventSummary;
  fetchBusinessInformation: (businessId: string) => void;
  fetchBusinessMarketEvents: (businessId: string) => void;
  setBusinessInformationLoading: (loading: boolean) => void;
  fetchBusinessLocations: (businessId: string) => void;
  selectBusinessLocation: (location: BusinessLocation | null) => void;
  setBusinessLocationsLoading: (loading: boolean) => void;
  fetchBusinessUsers: (businessId: string) => void;
  setBusinessUsersLoading: (loading: boolean) => void;
  updateBusinessItemsQuery: (query: SelectedBusinessAttributeDetails) => void;
  selectBusinessItem: (item: BusinessItem | null) => void;
  toggleViewBusinessItemModal: () => void;
  fetchBusinessItems: (businessId: string) => void;
  setBusinessItemsLoading: (loading: boolean) => void;
  updateBusinessItemInput: (inputs: BusinessItemFlow) => void;
}

class BusinessesPage extends Component<BusinessesPageProps> {
  componentDidMount = () => {
    this.refreshData(false)
  }

  refreshData = (setLoading: boolean) => {
    if (setLoading && this.props.selectedBusiness) {
      this.props.setBusinessInformationLoading(true)
      this.props.setBusinessLocationsLoading(true)
      this.props.setBusinessUsersLoading(true)
      this.props.setBusinessItemsLoading(true)
    }
    if (this.props.selectedBusiness) {
      this.props.fetchBusinessMarketEvents(this.props.selectedBusiness._id)
      this.props.fetchBusinessInformation(this.props.selectedBusiness._id)
      this.props.fetchBusinessLocations(this.props.selectedBusiness._id)
      this.props.fetchBusinessUsers(this.props.selectedBusiness._id)
      this.props.fetchBusinessItems((this.props.selectedBusiness._id))
    }
    
  }

  renderBusinessesPage = () => {
    return (
      <>
        <Container maxW={'9xl'} pt={5} pl={2} pr={2}>
            <BusinessSummaryCard
              selectedBusiness={this.props.selectedBusiness}
              businessLoading={this.props.businessLoading}
              businessView={this.props.businessView}
              selectedBusinessLocations={this.props.selectedBusinessLocations}
              selectedBusinessLocation={this.props.selectedBusinessLocation}
              selectBusinessLocation={this.props.selectBusinessLocation}
              selectedBusinessUsers={this.props.selectedBusinessUsers}
              selectedBusinessItems={this.props.selectedBusinessItems}
              selectedBusinessItem={this.props.selectedBusinessItem}
              businessItemQuery={this.props.businessItemQuery}
              businessItemFlow={this.props.businessItemFlow}
              updateBusinessItemsQuery={this.props.updateBusinessItemsQuery}
              selectBusinessItem={this.props.selectBusinessItem}
              toggleViewBusinessItemModal={this.props.toggleViewBusinessItemModal}
              fetchBusinessItems={this.props.fetchBusinessItems}
              updateBusinessItemInput={this.props.updateBusinessItemInput}
              selectedBusinessOrderStats={this.props.selectedBusinessOrderStats}
              selectedBusinessMarketEventSummary={this.props.selectedBusinessMarketEventSummary}              
            />            
        </Container>
      </>
    );
  };

  render() {
    return <Navbar content={this.renderBusinessesPage()} />;
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { 
    selectedBusiness, businessLoading, businessView, 
    selectedBusinessLocations, selectedBusinessUsers, selectedBusinessItems,
    selectedBusinessItem, businessItemQuery, selectedBusinessLocation,
    businessItemFlow, selectedBusinessOrderStats, selectedBusinessMarketEventSummary
   } = state.businesses;
  return { 
    selectedBusiness, businessLoading, businessView, 
    selectedBusinessLocations, selectedBusinessUsers, selectedBusinessItems,
    selectedBusinessItem, businessItemQuery, selectedBusinessLocation,
    businessItemFlow, selectedBusinessOrderStats, selectedBusinessMarketEventSummary
   };
};

export default connect(mapStateToProps, {
  fetchBusinessLocations,
  setBusinessLocationsLoading,
  fetchBusinessUsers,
  setBusinessUsersLoading,
  updateBusinessItemsQuery,
  selectBusinessItem,
  toggleViewBusinessItemModal,
  fetchBusinessItems,
  setBusinessItemsLoading,
  selectBusinessLocation,
  updateBusinessItemInput,
  fetchBusinessInformation,
  setBusinessInformationLoading,
  fetchBusinessMarketEvents
})(BusinessesPage);
