import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { 
  getBusinessMarketEventsSuccess,
  getBusinessMarketEventsFailure,
  setBusinessInformationLoading
} from '../actions';

// Initiator for fetching business market events
export const fetchBusinessMarketEvents = (businessId: string) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;
      // Construct the headers with X-Auth-Token
      const headers = {
        'X-Auth-Token': loginToken,
      };
      const today = new Date();

      // Set the date 168 hours ago
      const date168HoursAgo = new Date(today.getTime() - (168 * 60 * 60 * 1000));
      
      // Set the date to midnight
      const midnight = new Date(date168HoursAgo);
      midnight.setHours(0, 0, 0, 0);
      
      const rangeStart = midnight.toISOString();

      let url = `/marketplace-service/api/v1/market-events?includeSummary=1&businessId=${businessId}&sortBy=timestamp&sortDirection=desc&updatedAtMin=${rangeStart}`
      dispatch(setBusinessInformationLoading(true));
      const response = await backend.get(url,{ headers });

      if (response.status === 200) {
        const stats = response.data.eventSummary;
        dispatch(setBusinessInformationLoading(false));
        dispatch(getBusinessMarketEventsSuccess(stats));
      } else {
        dispatch(setBusinessInformationLoading(false));
        dispatch(getBusinessMarketEventsFailure('Failed to fetch business market events.'));
      }
    } catch (error) {
      console.error('Error fetching business market events:', error);
      dispatch(setBusinessInformationLoading(false));
      dispatch(getBusinessMarketEventsFailure('An error occurred while fetching business market events.'));
    }
  };
};