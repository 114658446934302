import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { 
  getSelectedBusinessUsersSuccess, 
  getSelectedBusinessUsersFailure,
  setBusinessUsersLoading 
} from '../actions';

// Action to fetch business users for a specific business
export const fetchBusinessUsers = (businessId: string, more: boolean = false) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;
      const { selectedBusinessUsers, businessUserQuery } = getState().businesses;
      const { search, currentPage } = businessUserQuery;
      let fetchedBusinessUsers: any[] = [];
      // Construct the headers with X-Auth-Token
      const headers = {
        'X-Auth-Token': loginToken,
      };
      dispatch(setBusinessUsersLoading(true));
      let url = `/marketplace-service/api/v1/business-users/business/${businessId}?describe=1`;
      if (more && currentPage) {
        const nextPage = currentPage + 1
        url += `&page=${nextPage}`
      }
      if (search) {
        url += `&search=${search}`
      }
      const response = await backend.get(url,{ headers });
      if (response.status === 200) {
        const { totalItems, currentPage, totalPages, itemsPerPage } = response.data;
        const details = { 
          ...businessUserQuery,
          // totalItems, currentPage, totalPages, itemsPerPage //doesn't paginate yet
         }
         if (more) {
          fetchedBusinessUsers = [...selectedBusinessUsers].concat(response.data) //.concat(response.data.businessUsers) doesn't paginate yet
        } else {
          fetchedBusinessUsers = response.data
        }
        dispatch(setBusinessUsersLoading(false));
        dispatch(getSelectedBusinessUsersSuccess(fetchedBusinessUsers, details));
      } else {
        dispatch(setBusinessUsersLoading(false));
        dispatch(getSelectedBusinessUsersFailure('Failed to fetch business users.'));
      }
    } catch (error) {
      console.error('Error fetching business users:', error);
      dispatch(setBusinessUsersLoading(false));
      dispatch(
        getSelectedBusinessUsersFailure('An error occurred while fetching business users.')
        );
    }
  };
};