import React from 'react';
import { connect } from "react-redux";
import {   
  Table,
  Tbody,
  Tr, Th, Td,
  Button,
  IconButton,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';

import { ReduxState } from "../../store/state";
import { BusinessItemFlow, Business, BusinessItem } from '../../store/businesses/types';
import AppDropdown from '../global/dropdown/AppDropdown';
import { AddIcon } from '@chakra-ui/icons';
import DropdownBusinessItemPaymentMethods from './DropdownBusinessItemPaymentMethods';

interface ViewBusinessItemPaymentProps {
  handlePageDataChange: (change: any) => void;
  businessItemFlow: BusinessItemFlow;
  selectedBusiness: Business | null;
  selectedBusinessItem: BusinessItem | null;
}

const ViewBusinessItemPayment: React.FC<ViewBusinessItemPaymentProps> = ({
  handlePageDataChange,
  businessItemFlow,
  selectedBusiness,
  selectedBusinessItem,
}) => {

  const renderPageData = () => {
    const { pageData, pageKey } = businessItemFlow;
    if (pageKey && pageData) {
      return (
        <>
        <Table>
        <Tbody>
          <Tr>
            <Th colSpan={2} textAlign={'center'} fontSize={'lg'}>Payment</Th>
          </Tr>
          <Tr>
            <Th>Allowed Payment Methods</Th>
            <Td>
              {pageData.allowedPaymentMethods.map((method, index) => (
                <Tag borderRadius={'full'} mr={2} mt={2} colorScheme='orange'>
                  <TagLabel>{method}</TagLabel>
                  {/* <TagCloseButton onClick={() => {
                    let allowedPaymentMethods = pageData.allowedPaymentMethods;
                    allowedPaymentMethods.splice(index, 1);
                    handlePageDataChange({ allowedPaymentMethods });
                  }}/> */}
                </Tag>
              ))}
            {/* <AppDropdown items={<DropdownBusinessItemPaymentMethods item={selectedBusinessItem} business={selectedBusiness}/>}>
              <IconButton 
                aria-label='Edit' 
                borderRadius={10} 
                size='xs' variant={'ghost'} 
              >
                <AddIcon />
              </IconButton>
            </AppDropdown>     */}
            </Td>
          </Tr>
        </Tbody>
        </Table>
        </>
      )
    } else {
      return null;
    }

  }
  if (selectedBusinessItem && selectedBusiness && businessItemFlow) {
    return (
      <div>{renderPageData()}</div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { businessItemFlow, selectedBusiness, selectedBusinessItem } = state.businesses;
  return { businessItemFlow, selectedBusiness, selectedBusinessItem };
};

export default connect(mapStateToProps, {
})(ViewBusinessItemPayment);
