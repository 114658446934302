import React, { useState } from 'react';
import { connect } from "react-redux";
import {   
  Text,
  Table,
  Tbody,
  Tr, Th, Td,
  IconButton,
  Switch,
  Flex,
} from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';

import { MdEdit } from 'react-icons/md';
import { ReduxState } from "../../store/state";
import { BusinessItemFlow, Business, BusinessItem } from '../../store/businesses/types';
import QuickNumberForm from '../global/forms/QuickNumberForm';

interface ViewBusinessItemInventoryProps {
  handlePageDataChange: (change: any) => void;
  businessItemFlow: BusinessItemFlow;
  selectedBusiness: Business | null;
  selectedBusinessItem: BusinessItem | null;
}

const ViewBusinessItemInventory: React.FC<ViewBusinessItemInventoryProps> = ({
  handlePageDataChange,
  businessItemFlow,
  selectedBusiness,
  selectedBusinessItem,
}) => {
  const [editQuantityOnHandFormOpen, setEditQuantityOnHandFormOpen] = useState(false);
  const [editQuantityOrderLimitMinFormOpen, setEditQuantityOrderLimitMinFormOpen] = useState(false);
  const [editQuantityOrderLimitMaxFormOpen, setEditQuantityOrderLimitMaxFormOpen] = useState(false);

  const renderPageData = () => {
    const { pageData, pageKey } = businessItemFlow;
    if (pageKey && pageData) {
      return (
        <>
        <Table>
        <Tbody>
          <Tr>
            <Th colSpan={2} textAlign={'center'} fontSize={'lg'}>Inventory</Th>
          </Tr>
          <Tr>
            <Th>Quantity on Hand</Th>
            <Td>
              <Flex flexDir={'row'} align={'center'}>
              <Switch id='isChecked' isChecked={pageData.displayQuantityOnHand} mr={2} colorScheme="orange" onChange={() => {
                handlePageDataChange({ displayQuantityOnHand: !pageData.displayQuantityOnHand });
              }}
              />
              { editQuantityOnHandFormOpen ?
              <QuickNumberForm
                initialNumber={pageData.quantityOnHand}
                minLimit={0}
                maxLimit={999999}
                onSubmit={(quantityOnHand: number) => {
                  handlePageDataChange({ quantityOnHand })
                  setEditQuantityOnHandFormOpen(false);
                }} 
                onClose={() => setEditQuantityOnHandFormOpen(false)}
              />
                :
                <>
                {pageData.displayQuantityOnHand && (
                  <>
                  <Text ml={2}>{pageData.quantityOnHand}</Text>
                  <IconButton 
                    aria-label='Edit' 
                    borderRadius={10} 
                    size='xs' ml={1} variant={'ghost'} 
                    onClick={() => {
                      setEditQuantityOnHandFormOpen(true)
                    }}
                  >
                    <MdEdit />
                  </IconButton>
                  </>
                )}
                </>
              }
              </Flex>            
            </Td>
          </Tr>
          <Tr>
            <Th>Order Limit</Th>
            <Td>
              <Flex flexDirection={'row'} align={'center'}>
              {editQuantityOrderLimitMinFormOpen ?
              <QuickNumberForm
                initialNumber={pageData.quantityOrderLimitMin}
                minLimit={1}
                maxLimit={pageData.quantityOrderLimitMax}
                onSubmit={(quantityOrderLimitMin: number) => {
                  handlePageDataChange({ quantityOrderLimitMin })
                  setEditQuantityOrderLimitMinFormOpen(false);
                }} 
                onClose={() => setEditQuantityOrderLimitMinFormOpen(false)}
              />
              :
              <>
              <Text>{pageData.quantityOrderLimitMin}</Text>
              <IconButton 
                aria-label='Edit' 
                borderRadius={10} 
                size='xs' ml={1} variant={'ghost'} 
                onClick={() => {
                  setEditQuantityOrderLimitMinFormOpen(true)
                }}
              >
                <MdEdit />
              </IconButton>
              </>
              }
              <Text ml={2} mr={2}>to</Text>
              {editQuantityOrderLimitMaxFormOpen ?
              <QuickNumberForm
                initialNumber={pageData.quantityOrderLimitMax}
                minLimit={pageData.quantityOrderLimitMin}
                maxLimit={999}
                onSubmit={(quantityOrderLimitMax: number) => {
                  handlePageDataChange({ quantityOrderLimitMax })
                  setEditQuantityOrderLimitMaxFormOpen(false);
                }} 
                onClose={() => setEditQuantityOrderLimitMaxFormOpen(false)}
              />
              :
              <>
              <Text>{pageData.quantityOrderLimitMax}</Text>
              <IconButton 
                aria-label='Edit' 
                borderRadius={10} 
                size='xs' ml={1} variant={'ghost'} 
                onClick={() => {
                  setEditQuantityOrderLimitMaxFormOpen(true)
                }}
              >
                <MdEdit />
              </IconButton>
              </>
              }
              </Flex>
              </Td>
          </Tr>
          <Tr>
            <Th>Show Stock Status</Th>
            <Td>
              <Switch id='isChecked' isChecked={pageData.displayStockStatus} colorScheme="orange" mr={2} onChange={() => {
                handlePageDataChange({ displayStockStatus: !pageData.displayStockStatus });
                }}
              />  
            </Td>
          </Tr>
          <Tr>
            <Th>Allow Back-Ordering</Th>
            <Td>
              <Switch id='isChecked' isChecked={pageData.allowBackOrder} colorScheme="orange" mr={2} onChange={() => {
                handlePageDataChange({ allowBackOrder: !pageData.allowBackOrder });
                }}
              />  
            </Td>
          </Tr>
        </Tbody>
        </Table>
        </>
      )
    } else {
      return null;
    }

  }
  if (selectedBusinessItem && selectedBusiness && businessItemFlow) {
    return (
      <div>{renderPageData()}</div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { businessModals, businessItemFlow, selectedBusiness, selectedBusinessItem } = state.businesses;
  return { businessModals, businessItemFlow, selectedBusiness, selectedBusinessItem };
};

export default connect(mapStateToProps, {
})(ViewBusinessItemInventory);
