import { BusinessesState, BusinessesActionTypes } from "./types";

const initialState: BusinessesState = {
  errors: {},
  businesses: [],
  selectedBusiness: null,
  selectedBusinessLocations: [],
  selectedBusinessLocation: null,
  selectedBusinessUsers: [],
  selectedBusinessUser: null,
  selectedBusinessItems: [],
  selectedBusinessItem: null,
  selectedBusinessRatings: [],
  selectedBusinessRating: null,
  businessItemFlow: {
    _id: null,
    name: '',
    status: 'pending',
    price: 0,
    currency: 'JMD',
    errors: {},
    mode: 'edit'
  },
  businessItemQuery: {
    totalItems: null,
    currentPage: null,
    totalPages: null,
    itemsPerPage: null,
    search: null,
  },
  businessLocationQuery: {
    totalItems: null,
    currentPage: null,
    totalPages: null,
    itemsPerPage: null,
    search: null,
  },
  businessUserQuery: {
    totalItems: null,
    currentPage: null,
    totalPages: null,
    itemsPerPage: null,
    search: null,
  },
  businessLoading: {
    businesses: false,
    locations: false,
    users: false,
    items: false,
    ratings: false,
    information: false
  },
  businessView: 'information',
  businessItemView: 'information',
  businessModals: {
    createBusinessLocationOpen: false,
    editBusinessLocationOpen: false,
    viewBusinessLocationOpen: false,
    createBusinessUserOpen: false,
    editBusinessUserOpen: false,
    viewBusinessUserOpen: false,
    createBusinessItemOpen: false,
    editBusinessItemOpen: false,
    viewBusinessItemOpen: false,
  },
  selectedBusinessMarketEventSummary: {
    summary: [],
    unauthenticatedUsersCount: 0,
    uniqueUserIdsCount: 0,
    locationAggregation: []
  },
  selectedBusinessOrderStats: {
    lastOrderedAt: null,
    accounting: {},
    ordersByWeekday: {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0
    },
    ordersByHour: {},
    ordersByMonth: {}
  },
};

const businessReducer = (state = initialState, action: BusinessesActionTypes): BusinessesState => {
  switch (action.type) {
    case 'GET_BUSINESSES_SUCCESS':
      return {
        ...state,
        businesses: action.payload,
      };
    case 'GET_BUSINESSES_FAILURE':
      return {
        ...state,
        businesses: [],
        errors: { getBusinesses: action.payload },
      };
    case 'SELECT_BUSINESS':
      return {
        ...state,
        selectedBusiness: action.payload,
      };
    case 'SET_SELECTED_BUSINESS_VIEW':
      return {
        ...state,
        businessView: action.payload,
      };
    case 'SET_SELECTED_BUSINESS_ITEM_VIEW':
      return {
        ...state,
        businessItemView: action.payload,
      };
    case 'GET_SELECTED_BUSINESS_INFORMATION_SUCCESS':
      return {
        ...state,
        selectedBusinessOrderStats: action.payload
      };
    case 'GET_SELECTED_BUSINESS_INFORMATION_FAILURE':
      return {
        ...state,
        errors: { getBusinessOrderStats: action.payload }
      };
    case 'GET_SELECTED_BUSINESS_MARKET_EVENTS_SUCCESS':
      return {
        ...state,
        selectedBusinessMarketEventSummary: action.payload
      };
    case 'GET_SELECTED_BUSINESS_MARKET_EVENTS_FAILURE':
      return {
        ...state,
        errors: { getBusinessMarketEvents: action.payload }
      };
    case 'GET_SELECTED_BUSINESS_LOCATIONS_SUCCESS':
      return {
        ...state,
        selectedBusinessLocations: action.payload.locations,
        businessLocationQuery: action.payload.query
      };
    case 'GET_SELECTED_BUSINESS_LOCATIONS_FAILURE':
      return {
        ...state,
        selectedBusinessLocations: [],
        errors: { getSelectedBusinessLocations: action.payload },
      };
    case 'UPDATE_BUSINESS_LOCATIONS_QUERY':
      return {
        ...state,
        businessLocationQuery: action.payload,
      };
    case 'SELECT_BUSINESS_LOCATION':
      return {
        ...state,
        selectedBusinessLocation: action.payload,
      };
    case 'GET_SELECTED_BUSINESS_USERS_SUCCESS':
      return {
        ...state,
        selectedBusinessUsers: action.payload.users,
        businessUserQuery: action.payload.query
      };
    case 'GET_SELECTED_BUSINESS_USERS_FAILURE':
      return {
        ...state,
        selectedBusinessUsers: [],
        errors: { getSelectedBusinessUsers: action.payload },
      };
    case 'GET_SELECTED_BUSINESS_ITEMS_SUCCESS':
      return {
        ...state,
        selectedBusinessItems: action.payload.items,
        businessItemQuery: action.payload.query
      };
    case 'GET_SELECTED_BUSINESS_ITEMS_FAILURE':
      return {
        ...state,
        selectedBusinessItems: [],
        errors: { getSelectedBusinessItems: action.payload },
      };
    case 'UPDATE_BUSINESS_ITEMS_QUERY':
      return {
        ...state,
        businessItemQuery: action.payload,
      };
    case 'SELECT_BUSINESS_ITEM':
      return {
        ...state,
        selectedBusinessItem: action.payload,
      };
    case 'EDIT_SELECTED_BUSINESS_ITEM_SUCCESS':
      const updatedItemIndex = state.selectedBusinessItems.findIndex(item => item._id === action.payload._id);
      let updatedBusinessItems = [...state.selectedBusinessItems];
      if (updatedItemIndex !== -1) {
        updatedBusinessItems[updatedItemIndex] = action.payload;
      }
      return {
        ...state,
        selectedBusinessItem: action.payload,
        selectedBusinessItems: updatedBusinessItems,
      };
    case 'EDIT_SELECTED_BUSINESS_ITEM_FAILURE':
      return {
        ...state,
        errors: { editSelectedBusinessItem: action.payload }
      };
    case 'UPDATE_BUSINESS_ITEM_INPUT':
      return {
        ...state,
        businessItemFlow: action.payload,
      };
    case 'SET_BUSINESSES_LOADING':
      return {
        ...state,
        businessLoading: {
          ...state.businessLoading,
          businesses: action.payload,
        },
      };
    case 'SET_BUSINESS_INFORMATION_LOADING':
      return {
        ...state,
        businessLoading: {
          ...state.businessLoading,
          information: action.payload,
        },
      };
    case 'SET_BUSINESS_LOCATIONS_LOADING':
      return {
        ...state,
        businessLoading: {
          ...state.businessLoading,
          locations: action.payload,
        },
      };
    case 'SET_BUSINESS_USERS_LOADING':
      return {
        ...state,
        businessLoading: {
          ...state.businessLoading,
          users: action.payload,
        },
      };
    case 'SET_BUSINESS_ITEMS_LOADING':
      return {
        ...state,
        businessLoading: {
          ...state.businessLoading,
          items: action.payload,
        },
      };
    case 'TOGGLE_CREATE_BUSINESS_LOCATION_MODAL':
      return {
        ...state,
        businessModals: {
          ...state.businessModals,
          createBusinessLocationOpen: !state.businessModals.createBusinessLocationOpen,
        },
      };
    case 'TOGGLE_EDIT_BUSINESS_LOCATION_MODAL':
      return {
        ...state,
        businessModals: {
          ...state.businessModals,
          editBusinessLocationOpen: !state.businessModals.editBusinessLocationOpen,
        },
      };
    case 'TOGGLE_VIEW_BUSINESS_LOCATION_MODAL':
      return {
        ...state,
        businessModals: {
          ...state.businessModals,
          viewBusinessLocationOpen: !state.businessModals.viewBusinessLocationOpen,
        },
      };
    case 'TOGGLE_CREATE_BUSINESS_ITEM_MODAL':
      return {
        ...state,
        businessModals: {
          ...state.businessModals,
          createBusinessItemOpen: !state.businessModals.createBusinessItemOpen,
        },
      };
    case 'TOGGLE_EDIT_BUSINESS_ITEM_MODAL':
      return {
        ...state,
        businessModals: {
          ...state.businessModals,
          editBusinessItemOpen: !state.businessModals.editBusinessItemOpen,
        },
      };
    case 'TOGGLE_VIEW_BUSINESS_ITEM_MODAL':
      return {
        ...state,
        businessModals: {
          ...state.businessModals,
          viewBusinessItemOpen: !state.businessModals.viewBusinessItemOpen,
        },
      };
    default:
      return state;
  }
};

export default businessReducer;
