import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { 
  getSelectedBusinessLocationsSuccess, 
  getSelectedBusinessLocationsFailure,
  setBusinessLocationsLoading, 
  selectBusinessLocation
} from '../actions';

// Action to fetch business locations for a specific business
export const fetchBusinessLocations = (businessId: string, more: boolean = false) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;
      const { selectedBusinessLocations, businessLocationQuery } = getState().businesses;
      const { search, currentPage } = businessLocationQuery;
      let fetchedBusinessLocations: any[] = [];
      // Construct the headers with X-Auth-Token
      const headers = {
        'X-Auth-Token': loginToken,
      };
      dispatch(setBusinessLocationsLoading(true));
      let url = `/marketplace-service/api/v1/business-locations/business/${businessId}?describe=1`;
      if (more && currentPage) {
        const nextPage = currentPage + 1
        url += `&page=${nextPage}`
      }
      if (search) {
        url += `&search=${search}`
      }
      const response = await backend.get(url,{ headers });

      if (response.status === 200) {
        const { totalItems, currentPage, totalPages, itemsPerPage } = response.data;
        const details = { 
          ...businessLocationQuery,
          totalItems, currentPage, totalPages, itemsPerPage
         }
         if (more) {
          fetchedBusinessLocations = [...selectedBusinessLocations].concat(response.data.businessLocations)
        } else {
          fetchedBusinessLocations = response.data.businessLocations
        }
        const defaultBusinessLocation = fetchedBusinessLocations.find(businessLocation => businessLocation.default);
        if (defaultBusinessLocation) {
          dispatch(selectBusinessLocation(defaultBusinessLocation));
        } else if (fetchedBusinessLocations.length > 0) { // If no default, select the first business
          dispatch(selectBusinessLocation(fetchedBusinessLocations[0]));
        }
        dispatch(setBusinessLocationsLoading(false));
        dispatch(getSelectedBusinessLocationsSuccess(fetchedBusinessLocations, details));
      } else {
        dispatch(setBusinessLocationsLoading(false));
        dispatch(getSelectedBusinessLocationsFailure('Failed to fetch business locations.'));
      }
    } catch (error) {
      console.error('Error fetching business locations:', error);
      dispatch(setBusinessLocationsLoading(false));
      dispatch(
        getSelectedBusinessLocationsFailure('An error occurred while fetching business locations.')
        );
    }
  };
};