import { Dispatch } from 'redux';

import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { loginFailure } from '../actions';
import { updateUser } from '../../user/actions';

export const asyncWelcomeUser = (altMethod?: string) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // POST to /self-register to check for user's existence
      // /self-register will create user, if the user doesn't exist
      const { loginFlow } = getState().auth;
      const { phoneNumber } = loginFlow;
      const firstName = 'Customer';
      let url = '/api/v1/self-register?requestCode=1'
      if (altMethod) url += `&altMethod=${altMethod}`
      const response = await backend.post(url, {
        phoneNumber,
        firstName,
        app: 'partner-ui'
      });
      if (response.status === 200) {
        dispatch(updateUser(response.data))
      }
    } catch(error) {
      console.error('User could not be welcomed. Please try again later.')
      dispatch(loginFailure('User could not be welcomed. Please try again later.'))
    }
  }
}