import React, { useState } from 'react';
import { Box, Button, Card, CardBody, Table, Tbody, Tr, Th, Td, Text, Center } from '@chakra-ui/react';
// import { BusinessItemRating } from '../../store/businesses/types';
import NoContentPage from '../../pages/noContent/NoContentPage';
import { formatPhoneNumber } from 'react-phone-number-input';
import LoadingPage from '../../pages/loading/LoadingPage';

interface ViewBusinessRatingsProps {
  businessView: string;
  // selectedBusinessItemRatings: BusinessItemRating[];
  loading: boolean;
}

const ViewBusinessRatings: React.FC<ViewBusinessRatingsProps> = ({ 
  businessView, loading
}) => {
  
  if (businessView!=='ratings') {
    return null;
  }

  // const renderRating = (item: BusinessRating, index: number) => {
  //   return (
  //     <Tr key={index}>
  //       <Td fontSize={'sm'}>
  //        <Text>{`${item.firstName} ${item.lastName}`}</Text>
  //         { item.phoneNumber && (
  //           <>
  //           <Text fontSize={'xs'} as={'u'}>
  //             <a href={`tel:${item.phoneNumber}`}>
  //               {formatPhoneNumber(item.phoneNumber)}
  //             </a>
  //           </Text><br />
  //           </>
  //         )}
  //         { item.email && (
  //           <>
  //           <Text fontSize={'xs'} as={'u'}>
  //             <a href={`mailto:${item.email}`}>
  //               {item.email}
  //             </a>
  //           </Text><br />
  //           </>
  //         )}
  //       </Td>
  //       <Td>
  //         {item.scopes.map((scope: string, index: number) => (
  //           <Button size='xs' mt={2} key={index}>
  //             {scope}
  //           </Button>
  //         ))}
  //       </Td>
  //     </Tr>
  //   );
  // }

  if (loading) {
    return <LoadingPage loadingText='Loading Ratings' />
  }
  return (
    <Box paddingTop={5} paddingBottom={5}>
      <Card borderRadius={20}>
        <CardBody>
          {/* {selectedBusinessItemRatings.length ? (
          <Table>
            <Tbody>
              <Tr>
                <Th>Rating</Th>
                <Th>Scopes</Th>
              </Tr>
              {selectedBusinessRatings.map((item: BusinessRating, index: number) => (
                !item.deleted ? renderRating(item, index) : null
              ))}
               {renderDeletedToggle()}
              {viewDeleted ? 
              selectedBusinessRatings.map((item: BusinessRating, index: number) => (
                item.deleted ? renderRating(item, index) : null
              )) : null}
            </Tbody>
          </Table>  
          )
          : ( */}
            <NoContentPage
              noContentText='No Ratings Found' 
            />
          {/* )}                */}
        </CardBody>
      </Card>  
    </Box>      
  );
}

export default ViewBusinessRatings;
