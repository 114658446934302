import { Component } from "react";
import { connect } from "react-redux";
import { 
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  SimpleGrid,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";

import { ReduxState } from "../../store/state";
import Blur from "../../components/global/ui/Blur";
import LoginFlow from "../../components/auth/LoginFlow";


interface LoginPageProps {
  errors: any;
}

const LoginPage: React.FC<LoginPageProps> = ({ errors }) =>{
  return (
    <Box position={'relative'}>
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}>
        <Stack spacing={{ base: 10, md: 20 }}>
          <Heading
            lineHeight={1.1}
            fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}>
            Reach new customers{' '}
            <Text as={'span'} bgGradient="linear(to-r, #CA0000,#F7941D)" bgClip="text">
              &
            </Text>{' '}
            Grow your sales
          </Heading>
          <Stack direction={'row'} spacing={4} align={'center'}>
            <Image
              src="/assets/images/showfa-logo.png"
              alt="Showfa Logo"
              h="8"
              w="auto"
              mr={2}
            /> 
            <Text fontFamily={'heading'} fontSize={{ base: '4xl', md: '6xl' }}>
              +
            </Text>
            <Flex
              align={'center'}
              justify={'center'}
              fontFamily={'heading'}
              fontSize={{ base: 'sm', md: 'lg' }}
              bg={'gray.800'}
              color={'white'}
              rounded={'full'}
              minWidth={useBreakpointValue({ base: '44px', md: '60px' })}
              minHeight={useBreakpointValue({ base: '44px', md: '60px' })}
              position={'relative'}
              _before={{
                content: '""',
                width: 'full',
                height: 'full',
                rounded: 'full',
                transform: 'scale(1.125)',
                bgGradient: 'linear(to-bl, orange.400,yellow.400)',
                position: 'absolute',
                zIndex: -1,
                top: 0,
                left: 0,
              }}>
              YOU
            </Flex>
          </Stack>
        </Stack>
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}>
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
              Showfa Partner
            </Heading>
            <Text color={'gray.700'} fontSize={{ base: 'sm', sm: 'md' }}>
              We’re the premiere e-commerce platform for your business!
            </Text>
          </Stack>
          <LoginFlow />
        </Stack>
      </Container>
      <Blur position={'absolute'} top={-10} left={-10} zIndex={-1} style={{ filter: 'blur(70px)' }} />
    </Box>
  )
}

const mapStateToProps = (state: ReduxState) => {
  const { errors } = state.auth;
  return { errors };
};

export default connect(mapStateToProps, {
})(LoginPage);