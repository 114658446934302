import { useState } from 'react'
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  Button
} from '@chakra-ui/react';
import {
  FiHome,
  FiShoppingCart,
  FiUsers,
  FiList,
  FiMenu,
  // FiBell,
  FiChevronLeft,
  FiChevronDown,
} from 'react-icons/fi';
import {
  MdAnalytics,
  MdAssignmentTurnedIn,
  MdCurrencyExchange,
  MdMoped,
  MdPriceCheck,
  MdStorefront
} from 'react-icons/md';
import { IconType } from 'react-icons';
import { Link, useLocation } from 'react-router-dom';
import { connect } from "react-redux";

import { ReduxState } from "../../store/state";
import { User } from '../../store/user/types';
import LogoutButton from '../auth/LogoutButton';
import ViewOrderModal from '../orders/ViewOrderModal';
import FilterOrdersModal from '../orders/FilterOrdersModal';
import Blur from '../global/ui/Blur';
import ViewBusinessItemModal from '../businesses/ViewBusinessItemModal';

interface LinkItemProps {
  name: string;
  to: string;
  icon: IconType;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  to: string;
  children: React.ReactNode;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
  user: User | null;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  onExpandedChange: (expanded: boolean) => void;
}

interface NavbarProps {
  content: React.ReactNode;
  user: User | null;
}

const SidebarContent = ({ onClose, onExpandedChange, ...rest }: SidebarProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isDesktop = window.innerWidth >= 769;
  let showName = false;
  if (isDesktop && isExpanded) showName = true;
  if (!isDesktop) showName = true;

  var LinkItems: Array<LinkItemProps> = [
    { name: 'Home', icon: FiHome, to: '/'},
    { name: 'Businesses', icon: MdStorefront, to: '/businesses' },
    { name: 'Orders', icon: FiShoppingCart, to: '/orders' },
    // { name: 'Transactions', icon: MdCurrencyExchange, to: '/transactions' },
    // { name: 'Requests', icon: MdAssignmentTurnedIn, to: '/requests' },
    // { name: 'Analytics', icon: MdAnalytics, to: '/analytics' }
  ]

  return (
    <Box
      transition="0.25s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={isExpanded ? { base: 'full', md: 60 } : { base: 'full', md: 20 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" justifyContent="space-between" 
      mb={!isExpanded ? 2 : 0}
      >
        {isExpanded ? 
        <>
          <Button 
            variant="ghost" 
            ml="4" 
            borderRadius={20}
            onClick={() => {
              setIsExpanded(false)
              onExpandedChange(false)
            }}
          >
            <Icon
              fontSize="16"
              _groupHover={{
                color: '#CA0000',
              }}
              as={FiChevronLeft}
            />
          </Button>
          <Image
            src="/assets/images/showfa-logo.png"
            alt="Showfa Logo"
            h="8"
            w="auto"
            mr={2}
          /> 
        </>
        : 
        <Button 
          variant="ghost" 
          ml="4" 
          borderRadius={20}
          onClick={() => {
            setIsExpanded(true)
            onExpandedChange(true)
          }}
        >
          <Icon
            fontSize="16"
            _groupHover={{
              color: '#CA0000',
            }}
            as={FiMenu}
          />
        </Button>
        }
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} to={link.to}>
          { showName ? link.name : null }
        </NavItem>
      ))}
    </Box>
  )
}

const NavItem = ({ icon, children, to, ...rest }: NavItemProps) => {
  const location = useLocation();
  const selected = location.pathname.startsWith(to) && to !== '/'
  return (
    <Link
      to={to}
      style={{ textDecoration: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          outline: '2px dotted #F7941D',
          color: 'black',
          borderRadius: 20
        }}
        style={
          selected ? { outline: '2px solid #F7941D', borderRadius: 20} : {}
        }
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: '#CA0000',
            }}
            style={
              selected ? { color: '#CA0000'} : {}
            }
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  )
}

const MobileNav = ({ onOpen, user, ...rest }: MobileProps) => {
  const location = useLocation();
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontWeight={"bold"}mr={4}>{location.pathname.slice(1,).toUpperCase() || 'SHOWFA EXPRESS PARTNER'}</Text>
      <HStack spacing={{ base: '0', md: '6' }}>
        {/* <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} /> */}
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  src={ undefined}
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm">{user ? user.firstName : ''} {user ? user.lastName : ''}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              zIndex={3}
            >
              {/* <MenuItem>
                <Link to={'/account'}>
                  Account
                </Link>
              </MenuItem>
              <MenuDivider /> */}
              <MenuItem><LogoutButton /></MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

const NavBar = ({ content, user }: NavbarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [expandedSidebar, setSidebarIsExpanded] = useState(false);

  const handleExpandChange = (expandedSidebar: boolean) => {
    setSidebarIsExpanded(expandedSidebar)
  }

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.50', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} onExpandedChange={handleExpandChange}/>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} onExpandedChange={handleExpandChange}/>
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} user={user} ml={!expandedSidebar ? { base: 0, md: 20 } : { base: 0, md: 60 }}/>
      <Box ml={!expandedSidebar ? { base: 0, md: 20 } : { base: 0, md: 60 }} p="2">
        {content}
        <ViewOrderModal />
        <FilterOrdersModal />
        <ViewBusinessItemModal />
      </Box>
      
    </Box>
  )
}


const mapStateToProps = (state: ReduxState) => {
  const { user } = state.user;
  return { user };
};

export default connect(mapStateToProps, {

})(NavBar);