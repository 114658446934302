import { Business, BusinessesActionTypes } from '../types';

// Action types related to businesses

export const getBusinessesSuccess = (payload: Business[]): BusinessesActionTypes => ({
    type: 'GET_BUSINESSES_SUCCESS',
    payload
});

export const getBusinessesFailure = (payload: string): BusinessesActionTypes => ({
    type: 'GET_BUSINESSES_FAILURE',
    payload,
});

export const selectBusiness = (payload: Business | null): BusinessesActionTypes => ({
    type: 'SELECT_BUSINESS',
    payload,
});
