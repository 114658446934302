import { Dispatch } from 'redux';

import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { loginFailure } from '../actions';
import { updateUser } from '../../user/actions';

export const asyncRequestVerificationCode = (altMethod?: string) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      const { phoneNumber } = getState().auth.loginFlow;
      dispatch(loginFailure(''))
      let url = `/api/v1/request-verification-code`
      if (altMethod) url += `?altMethod=${altMethod}`
      const response = await backend.post(url, { phoneNumber, app: 'partner-ui' });
      if (response.status === 200) {
        console.log('Successfully requested verification code.')
      } else {
        dispatch(loginFailure('Error requesting verification code. Try again.'));
        dispatch(updateUser(null));
      }
    } catch (error) {
      console.error('Error requesting verification code. Try again.', error);
      dispatch(loginFailure('Error requesting verification code. Try again.'));
      dispatch(updateUser(null));
    }
  };
};