import React from 'react';
import { connect } from "react-redux";
import { Stack, Text } from '@chakra-ui/react';
import { setSelectedBusinessView } from '../../store/businesses/actions';
import { ReduxState } from '../../store/state';
import { MdInfo, MdInventory, MdLocationPin, MdStar } from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';


interface ViewBusinessViewSelectorProps {
  setSelectedBusinessView: (view: 'information' | 'locations' | 'users' | 'items' | 'ratings') => void;
  businessView: 'information' | 'locations' | 'users' | 'items' | 'ratings';
}

const ViewBusinessViewSelectors: React.FC<ViewBusinessViewSelectorProps> = ({ 
  businessView, setSelectedBusinessView }) => {
  return (
    <Stack direction="row" justify="center" spacing={6}>
      <ViewSelectorButton
        label="Information"
        view="information"
        icon={<MdInfo />}
        businessView={businessView}
        setSelectedBusinessView={setSelectedBusinessView}
      />
      <ViewSelectorButton
        label="Locations"
        view="locations"
        icon={<MdLocationPin />}
        businessView={businessView}
        setSelectedBusinessView={setSelectedBusinessView}
      />
      <ViewSelectorButton
        label="Users"
        view="users"
        icon={<FiUsers />}
        businessView={businessView}
        setSelectedBusinessView={setSelectedBusinessView}
      />
      <ViewSelectorButton
        label="Items"
        view="items"
        icon={<MdInventory />}
        businessView={businessView}
        setSelectedBusinessView={setSelectedBusinessView}
      />
      {/* <ViewSelectorButton
        label="Ratings"
        view="ratings"
        icon={<MdStar />}
        businessView={businessView}
        setSelectedBusinessView={setSelectedBusinessView}
      /> */}
    </Stack>
  );
}

interface ViewSelectorButtonProps {
  label: string;
  view: 'information' | 'locations' | 'users' | 'items' | 'ratings';
  businessView: 'information' | 'locations' | 'users' | 'items' | 'ratings';
  icon?: React.ReactNode;
  setSelectedBusinessView: (view: 'information' | 'locations' | 'users' | 'items' | 'ratings') => void;
}
const ViewSelectorButton: React.FC<ViewSelectorButtonProps> = ({ 
  label, view, businessView, icon, setSelectedBusinessView }) => {
  return (
    <Stack 
      spacing={0} 
      align={'center'} 
      _hover={{
        outline: '2px dotted #F7941D'
      }}
      style={view === businessView ? { outline: '2px solid #F7941D'} : {}}
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      onClick={() => {setSelectedBusinessView(view)}}
    >
      { icon ? 
      icon :
      null
      }
      
      <Text fontSize={'sm'} color={'gray.500'}>
        {label}
      </Text>
    </Stack>
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { 
    businessView
  } = state.businesses;
  return {     
    businessView
  };
};

export default connect(mapStateToProps, {
setSelectedBusinessView,
})(ViewBusinessViewSelectors);
