import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';

import {
  OrdersQuery,
} from '../types';
import {
  getCurrentOrdersSuccess,
  getCurrentOrdersFailure,
  setSearchOrdersLoading
} from '../actions';

// Thunk action to fetch orders
export const fetchCurrentOrders = () => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;
      const { ordersQuery, orders } = getState().orders;
      const sortBy = 'updatedAt';
      const sortDirection = 'desc';
      const { search } = ordersQuery;
      const { selectedBusiness, selectedBusinessLocation } = getState().businesses;
      let fetchedCurrentOrders: any[] = [];

      if (selectedBusiness && selectedBusinessLocation) {
      // Construct the headers with X-Auth-Token
      const headers = {
        'X-Auth-Token': loginToken,
      };

      // Construct the URL with query parameters
      let url = `/commerce-service/api/v1/orders?sourceId=${selectedBusinessLocation._id}&describe=1&currentOrders=1&sortBy=${sortBy}&sortDirection=${sortDirection}&limit=50`;

      if (search) {
        url += `&search=${search}`;
      }

      const response = await backend.get(url, { headers });

      if (response.status === 200) {
        const {
          currentPage,
          itemsPerPage,
          totalPages,
          totalItems,
          currentOrdersCount
        } = response.data;
        const newOrdersQuery: OrdersQuery = {
          ...ordersQuery,
          search,
          currentPage,
          itemsPerPage,
          totalPages,
          totalItems,
          sortBy,
          sortDirection,
          currentOrdersCount
        };
        fetchedCurrentOrders = response.data.orders;
        dispatch(setSearchOrdersLoading(false));
        dispatch(getCurrentOrdersSuccess(newOrdersQuery, fetchedCurrentOrders));
      } else {
        dispatch(setSearchOrdersLoading(false));
        dispatch(getCurrentOrdersFailure('Failed to fetch current orders.'));
      }
    } else {
      dispatch(getCurrentOrdersSuccess(ordersQuery, []))
      dispatch(setSearchOrdersLoading(false));
    }
    } catch (error) {
      console.error('Error fetching orders:', error);
      dispatch(setSearchOrdersLoading(false));
      dispatch(getCurrentOrdersFailure('An error occurred while fetching current orders.'));
    }
  };
};