import { AuthState, AuthActionTypes } from './types';

const initialState: AuthState = {
  errors: {},
  loginFlow: {
    rawPhoneNumber: '+1876',
    phoneNumber: '',
    verificationCode: '',
    errors: {},
  },
  isLoggedIn: false,
  loginToken: null,
  loading: false
};

const authReducer = (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case 'SET_AUTH_LOADING':
      return {
        ...state,
        loading: action.payload
      }
    case 'SET_LOGIN_INPUTS':
      return {
        ...state,
        loginFlow: action.payload
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loginToken: action.payload.loginToken,
        isLoggedIn: true,
        errors: {},
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loginToken: null,
        isLoggedIn: false,
        loginFlow: { ...state.loginFlow, verificationCode: ''},
        errors: { login: action.payload },
      };
    case 'LOGOUT':
      return {
        ...state,
        loginFlow: initialState.loginFlow,
        loginToken: null,
        isLoggedIn: false,
        errors: {},
      };
      case 'LOGOUT_FAILURE':
        return {
          ...state,
          errors: {logout: action.payload},
        };
    default:
      return state;
  }
};

export default authReducer;
