import React from 'react';
import { connect } from "react-redux";
import {   
  Table,
  Tbody,
  Tr, Th, Td,
  Button,
} from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';

import { ReduxState } from "../../store/state";
import { BusinessItemFlow, Business, BusinessItem } from '../../store/businesses/types';

interface ViewBusinessItemDesignProps {
  handlePageDataChange: (change: any) => void;
  businessItemFlow: BusinessItemFlow;
  selectedBusiness: Business | null;
  selectedBusinessItem: BusinessItem | null;
}

const ViewBusinessItemDesign: React.FC<ViewBusinessItemDesignProps> = ({
  handlePageDataChange,
  businessItemFlow,
  selectedBusiness,
  selectedBusinessItem,
}) => {

  const renderPageData = () => {
    const { pageData, pageKey } = businessItemFlow;
    if (pageKey && pageData) {
      return (
        <>
        <Table>
        <Tbody>
          <Tr>
            <Th colSpan={2} textAlign={'center'} fontSize={'lg'}>Design</Th>
          </Tr>
          <Tr>
            <Th>Page Theme</Th>
            <Td>
            <Button
              size="xs"
            >
              {pageData.pageTheme}
            </Button>  
            </Td>
          </Tr>
        </Tbody>
        </Table>
        </>
      )
    } else {
      return null;
    }

  }
  if (selectedBusinessItem && selectedBusiness && businessItemFlow) {
    return (
      <div>{renderPageData()}</div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { businessItemFlow, selectedBusiness, selectedBusinessItem } = state.businesses;
  return { businessItemFlow, selectedBusiness, selectedBusinessItem };
};

export default connect(mapStateToProps, {
})(ViewBusinessItemDesign);
