import React from "react";
import { Table, Thead, Tr, Th, Td, Tbody, Text, Tag, TagLabel, Box, Button } from '@chakra-ui/react';
import { Order, OrderItem } from "../../store/orders/types";
import { formatCurrency } from "../global/data/formatCurrencyHelper";

interface ViewOrderItemsProps {
  selectedOrder: Order | null;
}

class ViewOrderItems extends React.Component<ViewOrderItemsProps> {
  state = {
    showConfirmation: false
  }

  render() {
    const order = this.props.selectedOrder;
    if (order) {
      return (
        <>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th textAlign="right">Quantity</Th>
              <Th textAlign="right">Unit Price</Th>
              <Th textAlign="right">Extended Price</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
            order.data && order.data.orderItems ?
            order.data.orderItems.map((item: OrderItem, index: number) => (
              <Tr key={item._id}>
                <Td>
                  <div>
                    <div>{item.name}</div>
                    <div>{item.data.details}</div>
                    {item.data.addOns && item.data.addOns.length > 0 && (
                      <div>
                        {item.data.addOns.map((addOn, addOnIndex) => (
                          <Tag 
                            key={addOnIndex}
                            size={'sm'} 
                            mr={1}
                            borderRadius={15} 
                            colorScheme="orange"
                          >
                            <TagLabel>{addOn}</TagLabel>
                          </Tag>
                        ))}
                      </div>
                    )}
                  </div>                 
                </Td>
                <Td textAlign="right">{item.quantity}</Td>
                <Td textAlign="right">{formatCurrency(item.currency, item.price)}</Td>
                <Td textAlign="right">{formatCurrency(item.currency, item.quantity * item.price)}</Td>
              </Tr>
            ))
          : null}
          {order.tip ? (
            <Tr>
              <Th colSpan={2}></Th>
              <Th textAlign="right">Tip</Th>
              <Td textAlign="right">{formatCurrency(order.currency, order.tip)}</Td>
            </Tr>
          ): null}
            <Tr>
              <Th colSpan={2}></Th>
              <Th textAlign="right">Delivery Fee</Th>
              <Td textAlign="right">{formatCurrency(order.currency, order.deliveryFee)}</Td>
            </Tr>
            {order.valueFee ? (
            <Tr>
              <Th colSpan={2}></Th>
              <Th textAlign="right">Value Fee</Th>
              <Td textAlign="right">{formatCurrency(order.currency, order.valueFee)}</Td>
            </Tr>
            )
            : null}
            {order.discountAmount ? (
            <Tr>
              <Th colSpan={2}></Th>
              <Th textAlign="right">Discount</Th>
              <Td textAlign="right">
                <div>{formatCurrency(order.currency, order.discountAmount)}</div>
                <div>{order.discountDescription ? order.discountDescription : null}</div>
              </Td>
            </Tr>
            ) : null}
            <Tr>
              <Th colSpan={2}></Th>
              <Th textAlign="right">Customer Total</Th>
              <Td textAlign="right"><Text as='b'>{formatCurrency(order.currency, order.orderRevenue)}</Text></Td>
            </Tr>
            <Tr>
              <Th colSpan={2}></Th>
              <Th textAlign="right">Pages Fee</Th>
              <Td textAlign="right">{formatCurrency(order.currency, order.pagesTransactionFee * -1)}</Td>
            </Tr>
            <Tr>
              <Th colSpan={2}></Th>
              <Th textAlign="right">Partner Payout</Th>
              <Td textAlign="right"><Text as='b'>{formatCurrency(order.currency, order.orderExpenses - order.pagesTransactionFee)}</Text></Td>
            </Tr>
          </Tbody>
        </Table>
        </> 
      );     
    }
  }
}

export default ViewOrderItems;