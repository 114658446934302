import React, { useState } from 'react';
import { Box, Button, Card, CardBody, Table, Tbody, Tr, Th, Td, Text, Center, Flex } from '@chakra-ui/react';
import { LinkIcon } from '@chakra-ui/icons';
import { FiDollarSign } from 'react-icons/fi';
import { BusinessLocation } from '../../store/businesses/types';
import moment from 'moment';
import NoContentPage from '../../pages/noContent/NoContentPage';
import { formatPhoneNumber } from 'react-phone-number-input';
import LoadingPage from '../../pages/loading/LoadingPage';
import { MdStar } from 'react-icons/md';

interface ViewBusinessLocationsProps {
  businessView: string;
  selectedBusinessLocations: BusinessLocation[];
  selectedBusinessLocation: BusinessLocation | null;
  loading: boolean;
  selectBusinessLocation: (location: BusinessLocation | null) => void;
}

const ViewBusinessLocations: React.FC<ViewBusinessLocationsProps> = ({ 
  businessView, selectedBusinessLocations, loading, selectedBusinessLocation,
  selectBusinessLocation
}) => {
  const currentDay = moment().format('ddd');
  const [selectedDay, setSelectedDay] = useState(currentDay);
  const [viewDeleted, setViewDeleted] = useState(false);
  const deletedCount = selectedBusinessLocations.filter(obj => obj.deleted).length;
  
  if (businessView!=='locations') {
    return null;
  }

  const renderLocationHours = (hours: any) => {
    const mon = {...hours.monday, label: 'Mon' };
    const tue = {...hours.tuesday, label: 'Tue' };
    const wed = {...hours.wednesday, label: 'Wed' };
    const thu = {...hours.thursday, label: 'Thu' };
    const fri = {...hours.friday, label: 'Fri' };
    const sat = {...hours.saturday, label: 'Sat' };
    const sun = {...hours.sunday, label: 'Sun' };
    const week = [mon, tue, wed, thu, fri, sat, sun]
    const daysRender = week.map((day, index) => {
        return <Button 
                key={index}
                size={'xs'} 
                borderRadius={15} 
                mr={1}
                onClick={() => setSelectedDay(day.label)}
                colorScheme={selectedDay === day.label ? 'green' : undefined}
                >
                  {day.label.slice(0,1)}
                </Button>
    })
    const hoursRender = week.map((day, index) => {
      if (selectedDay === day.label) {
        return (
                <Text 
                  mt={2} 
                  fontSize={'sm'} 
                  key={index}
                >
                  { day.start && day.end ? `${day.start} to ${day.end} ` : 'Closed'}
                </Text>
              );
      } else {
        return null;
      }
    })
    return (
      <>
      {daysRender}
      {hoursRender}
      </>
    )
  }

  const renderLocation = (item: BusinessLocation, index: number) => {
    return (
      <Tr key={index}>
        <Td fontSize={'sm'}>
          <Flex align={'center'}>
            {selectedBusinessLocation && item._id === selectedBusinessLocation._id 
            ?
            <MdStar />
            :
            <Button size={'xs'} mr={2} leftIcon={<MdStar />} onClick={() => selectBusinessLocation(item)}>Select</Button>
            }
          <Text>{item.name}</Text>
         </Flex>
          { item.phoneNumber && (
            <>
            <Text fontSize={'xs'} as={'u'}>
              <a href={`tel:${item.phoneNumber}`}>
                {formatPhoneNumber(item.phoneNumber)}
              </a>
            </Text><br />
            </>
          )}
          <Button leftIcon={<FiDollarSign />} size='xs' variant={'ghost'} mt={2}>
            {item.currency}
          </Button>
        </Td>
        <Td fontSize={'sm'}>
          <Text as={item.deleted ? 's' : 'p'}>{item.address}</Text>
          {item.deleted ? <Text color='#CA0000'>Warning: This business location has been deleted.</Text> : null}
          <a href={`https://www.google.com/maps?q=${item.latitude},${item.longitude}`} target="_blank" rel="noreferrer">
            <Button leftIcon={<LinkIcon />} size='xs' mt={2}>View</Button>
          </a>
        </Td>
        <Td>
          <Text fontSize={'sm'}>{item.timezone}</Text>
          {renderLocationHours(item.hours)}
        </Td>
      </Tr>
    );
  }
  
  const renderDeletedToggle = () => {
    if (deletedCount) {
      return (
        <Center mt={2} textAlign={'center'}>
          <Button onClick={() => setViewDeleted(!viewDeleted)} size={'xs'}>
            {viewDeleted ? 'Hide' : 'Show'} {deletedCount} deleted location{deletedCount !== 1 ? 's' : ''}
          </Button>
        </Center>
      );
    } else {
      return null;
    }
  }

  if (loading) {
    return <LoadingPage loadingText='Loading Locations' />
  }
  return (
    <Box paddingTop={5} paddingBottom={5}>
      <Card borderRadius={20}>
        <CardBody>
          {selectedBusinessLocations.length ? (
          <Table>
            <Tbody>
              <Tr>
                <Th>Name</Th>
                <Th>Address</Th>
                <Th>Hours</Th>
              </Tr>
              {selectedBusinessLocations.map((item: BusinessLocation, index: number) => (
                !item.deleted ? renderLocation(item, index) : null
              ))}
               {renderDeletedToggle()}
              {viewDeleted ? 
              selectedBusinessLocations.map((item: BusinessLocation, index: number) => (
                item.deleted ? renderLocation(item, index) : null
              )) : null}
            </Tbody>
          </Table>  
          )
          : (
            <NoContentPage
              noContentText='No Locations Found' 
            />
          )}               
        </CardBody>
      </Card>  
    </Box>      
  );
}

export default ViewBusinessLocations;
