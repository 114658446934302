import React, { Component } from 'react';
import { Box, Button, Card, CardBody, Table, Tbody, Text, Tr, Th, Td, Center, Input } from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { Business, BusinessItem, BusinessItemFlow, SelectedBusinessAttributeDetails } from '../../store/businesses/types';
import { formatCurrency } from '../global/data/formatCurrencyHelper';
import NoContentPage from '../../pages/noContent/NoContentPage';
import LoadingPage from '../../pages/loading/LoadingPage';
import { MdInventory } from 'react-icons/md';

interface ViewBusinessItemsProps {
  businessView: 'information' | 'locations' | 'users' | 'items' | 'ratings';
  loading: boolean;
  selectedBusiness: Business | null;
  selectedBusinessItem: BusinessItem | null;
  businessItemFlow: BusinessItemFlow;
  selectedBusinessItems: BusinessItem[];
  businessItemQuery: SelectedBusinessAttributeDetails;
  updateBusinessItemInput: (inputs: BusinessItemFlow) => void;
  fetchBusinessItems: (businessId: string, more: boolean, suppressLoading: boolean) => void;
  updateBusinessItemsQuery: (detils: SelectedBusinessAttributeDetails) => void;
  selectBusinessItem: (item: BusinessItem) => void;
  toggleViewBusinessItemModal: () => void;
}

class ViewBusinessItems extends Component<ViewBusinessItemsProps> {
  state = {
    debounceTimer: null,
    viewInactive: false,
    editItemId: null,
    editItemName: '',
    editItemPrice: 0,
    editItemStatus: 'active'
  }

  componentWillUnmount() {
    const { debounceTimer } = this.state;
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
  }

  debouncedSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    const { debounceTimer } = this.state;
    const inputs = {...this.props.businessItemQuery, search: text}
    this.props.updateBusinessItemsQuery(inputs);
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newTimer = setTimeout(() => {
      const { selectedBusiness } = this.props;
      if (selectedBusiness) {
        this.props.fetchBusinessItems(selectedBusiness._id, false, true);
      } else {
        this.props.updateBusinessItemsQuery({...inputs});
      }
    }, 500);

    this.setState({ debounceTimer: newTimer });
  };

  renderSearchBusinessItems = () => {
    return (
      <Input 
        placeholder={`Search items at ${this.props.selectedBusiness?.name}`}
        onInput={this.debouncedSearch} 
        value={this.props.businessItemQuery.search ? this.props.businessItemQuery.search : ''}
        style={{ borderRadius: 25}}
      />
    );
  }

  renderMoreButton = () => {
    const { currentPage, totalPages } = this.props.businessItemQuery;
    if (currentPage && totalPages && currentPage < totalPages) {
      return (
        <Box display="flex" justifyContent="center" mt={2}>
          <Button size='xs' leftIcon={<ViewIcon />} onClick={() => {
            if (this.props.selectedBusiness && this.props.selectedBusiness._id) {
              this.props.fetchBusinessItems(this.props.selectedBusiness._id, true, true);
            }
          }}>More</Button>
        </Box>
      );
    }
    return null;
  };

  renderInactiveToggle = () => {
    const inactiveCount = this.props.selectedBusinessItems.filter(obj => obj.status === 'inactive').length;
    const { viewInactive } = this.state;
    if (inactiveCount) {
      return (
        <Center mt={2} textAlign={'center'}>
          <Button onClick={() => this.setState({ viewInactive: !viewInactive})} size={'xs'}>
            {viewInactive ? 'Hide' : 'Show'} {inactiveCount} inactive item{inactiveCount !== 1 ? 's' : ''}
          </Button>
        </Center>
      );
    } else {
      return null;
    }
  }

  renderBusinessItem = (item: BusinessItem, index: number) => {
    if (this.state.viewInactive === false && item.status === 'inactive') {
      return null;
    } else {
    return (
      <Tr key={index}>
        <Td  onClick={() => {
          this.props.selectBusinessItem(item);
          this.props.updateBusinessItemInput({...this.props.businessItemFlow, ...item})
          this.props.toggleViewBusinessItemModal();
          }}>
            <Button size={'xs'} leftIcon={<MdInventory />} borderRadius={'full'}>
              <Text as={'u'}>{item.name}</Text>
            </Button>
        </Td>
        <Td>
          <Button
            colorScheme={item.status === 'active' ? 'green' : item.status === 'pending' ? 'yellow' : 'gray'}
            size="xs"
          >
            {item.status}
          </Button>
        </Td>
        <Td textAlign="right">{formatCurrency(item.currency, item.price)}</Td>
      </Tr>
    )
  }}

  render() {
    const { businessView, selectedBusinessItems } = this.props;

    if (businessView !== 'items') {
      return null;
    }

    if (this.props.loading) {
      return <LoadingPage loadingText='Loading Items' />
    }

    return (
      <Box paddingTop={5} paddingBottom={5}>
        <Card borderRadius={20}>
          <CardBody>
            {this.renderSearchBusinessItems()}
            {this.renderInactiveToggle()}
            <Table>
              <Tbody>
                <Tr>
                  <Th>Name</Th>
                  <Th>Status</Th>
                  <Th textAlign="right">Price</Th>
                </Tr>
                {selectedBusinessItems.map((item: BusinessItem, index: number) => {
                    return this.renderBusinessItem(item, index)
                })}
              </Tbody>
            </Table> 
            {this.renderMoreButton()}    
            {!selectedBusinessItems.length && (
              <NoContentPage noContentText='No Items Found' />
            )}              
          </CardBody>
        </Card>  
      </Box>      
    );
  }
}

export default ViewBusinessItems;
