import { Transaction, TransactionHistory, TransactionsActionTypes } from '../types';

// SELECT TRANSACTION
export const selectTransaction = (payload: Transaction) => ({ 
    type: 'SELECT_TRANSACTION', 
    payload
});

export const getTransactionDetailsSuccess = (payload: Transaction) => ({ 
    type: 'GET_TRANSACTION_DETAILS_SUCCESS', 
    payload
});

export const getTransactionDetailsFailure = (payload: string) => ({
    type: 'GET_TRANSACTION_DETAILS_FAILURE',
    payload
});

// Action to fetch order history successfully
export const getTransactionHistorySuccess = (history: TransactionHistory[]): TransactionsActionTypes => ({
    type: 'GET_TRANSACTION_HISTORY_SUCCESS',
    payload: history,
  });
  
  // Action to handle failed order history fetch
  export const getTransactionHistoryFailure = (error: string): TransactionsActionTypes => ({
    type: 'GET_TRANSACTION_HISTORY_FAILURE',
    payload: error,
  });