// CHANGE VIEWS
export const toggleViewTransactionModal = () => ({ 
  type: 'TOGGLE_VIEW_TRANSACTION_MODAL'
});

export const toggleEditTransactionModal = () => ({ 
  type: 'TOGGLE_EDIT_TRANSACTION_MODAL'
});

export const toggleFilterTransactionsModal = () => ({ 
  type: 'TOGGLE_FILTER_TRANSACTIONS_MODAL'
});

export const toggleTransactionLiveMode = () => ({ 
  type: 'TOGGLE_TRANSACTION_LIVE_MODE'
});

