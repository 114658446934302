import React, { useState } from 'react';
import { connect } from "react-redux";
import {   
  Table,
  Tbody,
  Tr, Th, Td,
  Button,
  IconButton,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  Flex,
  Switch,
  FormControl,
  Select,
} from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';

import { ReduxState } from "../../store/state";
import { BusinessItemFlow, Business, BusinessItem, BusinessLocation } from '../../store/businesses/types';
import AppDropdown from '../global/dropdown/AppDropdown';
import { AddIcon } from '@chakra-ui/icons';
// import DropdownBusinessItemDeliveryTypes from './DropdownBusinessItemDeliveryTypes';
import QuickNumberForm from '../global/forms/QuickNumberForm';
import { MdCheck, MdEdit } from 'react-icons/md';

interface ViewBusinessItemDeliveryProps {
  handlePageDataChange: (change: any) => void;
  businessItemFlow: BusinessItemFlow;
  selectedBusiness: Business | null;
  selectedBusinessItem: BusinessItem | null;
  selectedBusinessLocations: BusinessLocation[];
}

const ViewBusinessItemDelivery: React.FC<ViewBusinessItemDeliveryProps> = ({
  handlePageDataChange,
  businessItemFlow,
  selectedBusiness,
  selectedBusinessItem,
  selectedBusinessLocations
}) => {

  const [editLeadTimeFormOpen, setEditLeadTimeFormOpen] = useState(false);
  const [editDefaultLocationFormOpen, setEditDefaultLocationFormOpen] = useState(false);

  const renderPageData = () => {
    const { pageData, pageKey } = businessItemFlow;
    if (pageKey && pageData) {
      return (
        <>
        <Table>
        <Tbody>
          <Tr>
            <Th colSpan={2} textAlign={'center'} fontSize={'lg'}>Delivery</Th>
          </Tr>
          <Tr>
            <Th>Delivery Types</Th>
            <Td>
              {pageData.allowedDeliveryTypes.map((day, index) => (
                <Tag borderRadius={'full'} mr={2} mt={2} colorScheme='orange'>
                  <TagLabel>{day}</TagLabel>
                  {/* {pageData.allowedDeliveryTypes.length > 1 &&(
                  <TagCloseButton onClick={() => {
                      let allowedDeliveryTypes = pageData.allowedDeliveryTypes;
                      allowedDeliveryTypes.splice(index, 1);
                      handlePageDataChange({ allowedDeliveryTypes });
                    }}/>)} */}
                  </Tag>
              ))}
            {/* <AppDropdown items={<DropdownBusinessItemDeliveryTypes item={selectedBusinessItem} business={selectedBusiness}/>}>
              <IconButton 
                aria-label='Add' 
                borderRadius={10} 
                size='xs' variant={'ghost'} 
              >
                <AddIcon />
              </IconButton>
            </AppDropdown>     */}
            </Td>
          </Tr>
          {pageData.allowedDeliveryTypes.includes('out of zone') && (
          <Tr>
            <Th>Out of Zone Limit</Th>
            <Td>
            {editLeadTimeFormOpen ?
              <QuickNumberForm
                initialNumber={pageData.outOfZoneLimitKm}
                minLimit={0}
                maxLimit={2000}
                onSubmit={(outOfZoneLimitKm: number) => {
                  handlePageDataChange({ outOfZoneLimitKm })
                  setEditLeadTimeFormOpen(false);
                }} 
                onClose={() => setEditLeadTimeFormOpen(false)}
              />
              :
              <Flex flexDirection={'row'}>
              <Text>{pageData.outOfZoneLimitKm} km</Text>
              <IconButton 
                aria-label='Edit' 
                borderRadius={10} 
                size='xs' ml={1} variant={'ghost'} 
                onClick={() => {
                  setEditLeadTimeFormOpen(true)
                }}
              >
                <MdEdit />
              </IconButton>
              </Flex>
              }            
            </Td>
          </Tr>
          )}
          <Tr>
            <Th>
              Default Source Location
            </Th>
            <Td>
              {editDefaultLocationFormOpen ? (
                <Flex flexDirection={'row'}>
                <FormControl flex={2} ml={1}>
                  <Select 
                    onChange={(e) => {
                      handlePageDataChange({ defaultLocationId: e.target.value})
                      setEditDefaultLocationFormOpen(false)
                  }}
                    value={pageData.defaultLocationId || ''} 
                    style={{ borderRadius: 25}}
                  >
                    {selectedBusinessLocations.map(location => (
                      <option value={location._id}>{`${location.name} @ ${location.address}`}</option>
                    ))}
                  </Select>         
                </FormControl>  
                <IconButton 
                  onClick={() => setEditDefaultLocationFormOpen(false)}
                  aria-label='Submit' 
                  colorScheme={'green'}
                  borderRadius={25} 
                  ml={1} 
                >
                  <MdCheck />
                </IconButton> 
                </Flex>                   
              ) :
              (
                <Flex flexDirection={'row'}>
                {selectedBusinessLocations.map(location => {
                  if (location._id === pageData.defaultLocationId) {
                    return (
                      <Text>{`${location.name} @ ${location.address}`}</Text>
                    );
                  } else {
                    return null;
                  }
                })} 
              <IconButton 
                aria-label='Edit' 
                borderRadius={10} 
                size='xs' ml={1} variant={'ghost'} 
                onClick={() => {
                  setEditDefaultLocationFormOpen(true)
                }}
              >
                <MdEdit />
              </IconButton>                
                </Flex>                 
              )}

            </Td>
          </Tr>
          {/* <Tr>
            <Th>
              Hide Source in Order Tracking
            </Th>
            <Td>
              <Switch id='isChecked' isChecked={pageData.hideSourceInTracking} colorScheme="orange" mr={2} onChange={() => {
                handlePageDataChange({ hideSourceInTracking: !pageData.hideSourceInTracking });
                }}
              />  
            </Td>
          </Tr> */}
        </Tbody>
        </Table>
        </>
      )
    } else {
      return null;
    }

  }
  if (selectedBusinessItem && selectedBusiness && businessItemFlow) {
    return (
      <div>{renderPageData()}</div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { businessItemFlow, selectedBusiness, selectedBusinessItem, selectedBusinessLocations } = state.businesses;
  return { businessItemFlow, selectedBusiness, selectedBusinessItem, selectedBusinessLocations };
};

export default connect(mapStateToProps, {
})(ViewBusinessItemDelivery);
