import { Dispatch } from 'redux';

import backend from '../../../apis/backend';
import { logout, logoutFailure } from '../actions';
import { updateUser } from '../../user/actions';

export const asyncLogout = (tokenId: string, userId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await backend.post(`/api/v1/logout`, {
        tokenId,
        userId,
        app: 'partner-ui'
      });

      if (response.status === 200) {
        localStorage.removeItem('showfaPartnerLoginToken');
        localStorage.removeItem('showfaPartnerUserId');
        dispatch(logout());
        dispatch(updateUser(null));
      } else {
        dispatch(logoutFailure('Logout failed'));
      }
    } catch (error) {
      console.error('Error during logout:', error);
      dispatch(logoutFailure('An error occurred during logout.'));
    }
  };
};