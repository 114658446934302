import { Component } from "react";
import { FormControl, Input, IconButton, Flex, Textarea } from "@chakra-ui/react";
import { MdCheck, MdClose } from "react-icons/md";

export interface QuickTextFormProps {
  onSubmit: (formText: string) => void;
  onClose: () => void;
  initialText?: string;
  minLimit: number;
  maxLimit: number;
  asTextArea?: boolean;
}
class QuickTextForm extends Component<QuickTextFormProps> {
  state = {
    formText: this.props.initialText || ''
  }

  renderFormContents = () => {
    if (this.props.asTextArea) {
      return (
        <Textarea 
          onChange={(e) => this.setState({formText: e.target.value})}
          value={this.state.formText} 
          style={{ borderRadius: 25}}
        />
      )    
    } else {
      return (
        <Input 
          onChange={(e) => this.setState({formText: e.target.value})}
          value={this.state.formText} 
          style={{ borderRadius: 25}}
        />        
      )
    }
  }

  render = () => {
    const { onSubmit, onClose, minLimit, maxLimit } = this.props;
    const { formText } = this.state
    const canSubmit = formText.length >= minLimit && formText.length <= maxLimit
    return (
      <Flex direction={'row'} align={'center'}>
      <FormControl flex={1}>
      {this.renderFormContents()}
      </FormControl>
      <IconButton 
        onClick={() => {
          if (canSubmit) {
            onSubmit(formText)
          }
        }}
        aria-label='Submit' 
        colorScheme={canSubmit ? 'green' : undefined}
        borderRadius={25} 
        ml={1} 
      >
        <MdCheck />
      </IconButton>
      <IconButton 
        onClick={() => onClose()}
        aria-label='Close' 
        borderRadius={25} 
        ml={1} 
      >
        <MdClose />
      </IconButton>
      </Flex>
    )
  }
}

export default QuickTextForm;