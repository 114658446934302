import { OrdersActionTypes } from "../types";


export const setSearchOrdersLoading = (state: boolean): OrdersActionTypes => ({
  type: 'SET_SEARCH_ORDERS_LOADING',
  payload: state,
});

export const setSelectedOrderLoading = (state: boolean): OrdersActionTypes => ({
  type: 'SET_SELECTED_ORDER_LOADING',
  payload: state,
});