import { BusinessUser, BusinessesActionTypes, SelectedBusinessAttributeDetails } from '../types';

// Action types related to business users

export const getSelectedBusinessUsersSuccess = (users: BusinessUser[], query: SelectedBusinessAttributeDetails): BusinessesActionTypes => ({
    type: 'GET_SELECTED_BUSINESS_USERS_SUCCESS',
    payload: { users, query},
});

export const getSelectedBusinessUsersFailure = (payload: string): BusinessesActionTypes => ({
    type: 'GET_SELECTED_BUSINESS_USERS_FAILURE',
    payload,
});

export const updateBusinessUsersQuery = (payload: SelectedBusinessAttributeDetails): BusinessesActionTypes => ({
    type: 'UPDATE_BUSINESS_USERS_QUERY',
    payload,
});