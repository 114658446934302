import { combineReducers } from 'redux';
import authReducer from './auth/reducers';
import userReducer from './user/reducers';
import orderReducer from './orders/reducers';
import transactionsReducer from './transactions/reducers';
import businessReducer from './businesses/reducers';
import eventReducer from './events/reducers';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  orders: orderReducer,
  transactions: transactionsReducer,
  businesses: businessReducer,
  events: eventReducer
});

