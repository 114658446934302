import React from 'react';
import {
  Button, Spinner
} from '@chakra-ui/react';
import { colors } from '../../../assets/colors';

interface AppButtonProps {
  text: string;
  onClick: () => void;
  color?: string;
  loading?: boolean;
  transparent?: boolean;
  isDisabled?: boolean;
}

const AppButton: React.FC<AppButtonProps> = ({ text, onClick, color, isDisabled, loading, transparent }) => {
  const buttonColor = color ? color : colors.primary;
  const buttonDisabled = isDisabled ? isDisabled : false;

  return (
    <>
    <Button
      rounded={'none'}
      w={'full'}
      mt={8}
      size={'lg'}
      py={'7'}
      bg={transparent ? undefined : buttonColor}
      borderRadius={'full'}
      color={transparent ? 'black' : 'white'}
      _hover={{
        transform: 'translateY(2px)',
        boxShadow: 'lg',
      }}
      onClick={() => onClick()}
      isDisabled={buttonDisabled}
      leftIcon={ loading ? <Spinner /> : undefined}
    >
      {text}
    </Button>
    </>
    );
};

export default AppButton;