import { 
  OrdersActionTypes, 
  Order, 
  OrdersQuery,
} from '../types';

// Action to update orders query
export const updateOrdersQuery = (ordersQuery: OrdersQuery): OrdersActionTypes => ({
  type: 'UPDATE_ORDERS_QUERY',
  payload: ordersQuery,
});

// Action to clear orders query
export const clearOrdersQuery = (): OrdersActionTypes => ({
  type: 'CLEAR_ORDERS_QUERY'
});

// Action to fetch orders successfully
export const getOrdersSuccess = (ordersQuery: OrdersQuery, orders: Order[]): OrdersActionTypes => ({
  type: 'GET_ORDERS_SUCCESS',
  payload: { ordersQuery, orders },
});

// Action to handle failed order fetch
export const getOrdersFailure = (error: string): OrdersActionTypes => ({
  type: 'GET_ORDERS_FAILURE',
  payload: error,
});

// Action to fetch current orders successfully
export const getCurrentOrdersSuccess = (ordersQuery: OrdersQuery, currentOrders: Order[]): OrdersActionTypes => ({
  type: 'GET_CURRENT_ORDERS_SUCCESS',
  payload: { ordersQuery, currentOrders },
});

// Action to handle failed current order fetch
export const getCurrentOrdersFailure = (error: string): OrdersActionTypes => ({
  type: 'GET_CURRENT_ORDERS_FAILURE',
  payload: error,
});