import { AuthActionTypes, UserLoginFlow } from './types';

export const setAuthLoading = (state: boolean): AuthActionTypes => ({
  type: 'SET_AUTH_LOADING',
  payload: state,
});

export const loginSuccess = (loginToken: string ): AuthActionTypes => ({
  type: 'LOGIN_SUCCESS',
  payload: { loginToken },
});

export const loginFailure = (error: string): AuthActionTypes => ({
  type: 'LOGIN_FAILURE',
  payload: error,
});

export const logout = (): AuthActionTypes => ({
  type: 'LOGOUT',
});

export const logoutFailure = (error: string): AuthActionTypes => ({
  type: 'LOGOUT_FAILURE',
  payload: error,
});

export const setLoginInputs = (inputs: UserLoginFlow): AuthActionTypes => ({
  type: 'SET_LOGIN_INPUTS',
  payload: inputs
})

