import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';


export default function TimelineRow(props: any) {
	const { logo, titleRow, date, color, note } = props;
	const bgIconColor = useColorModeValue('white.300', 'gray.700');

	return (
		<Flex alignItems='center' minH='78px' justifyContent='start' mb='5px'>
			<Flex direction='column' h='100%'>
				<Box w='2px' bg='gray.200' h={'15px'} />
				<Icon
					as={logo}
					bg={bgIconColor}
					color={color}
					h={'30px'}
					w={'26px'}
					pe='6px'
					zIndex='1'
					position='relative'
					right={document.documentElement.dir === 'rtl' ? '-8px' : ''}
					left={document.documentElement.dir === 'rtl' ? '' : '-8px'}
				/>
				<Box w='2px' bg='gray.200' h={'15px'} />
			</Flex>
			<Flex direction='column' justifyContent='flex-start' h='100%'>
				{titleRow}
				<Text fontSize={'sm'}>{note}</Text>
				<Text fontSize='xs' color='gray.500' fontWeight='normal'>
					{date}
				</Text>
				
			</Flex>
		</Flex>
	);
}