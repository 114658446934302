import React, { useState, ChangeEvent } from 'react';
import { connect } from "react-redux";
import {
  Button,
  Text,
  Table,
  Tbody,
  Tr, Th, Td,
  IconButton,
  Tag,
  TagLabel,
  TagCloseButton,
  Flex,
  FormControl,
  Input,
  Image
} from '@chakra-ui/react';
import QuickTextForm from '../global/forms/QuickTextForm';
import AppDropdown from '../global/dropdown/AppDropdown';
import DropdownBusinessItemPageStatus from './DropdownBusinessItemPageStatus';
import 'react-toastify/dist/ReactToastify.css';

import { MdEdit, MdUploadFile, MdCheck, MdClose } from 'react-icons/md';
import { ReduxState } from "../../store/state";
import { BusinessItemFlow, Business, BusinessItem, BusinessItemPageData, BusinessItemPageDataMedia } from '../../store/businesses/types';
import { AddIcon, LinkIcon } from '@chakra-ui/icons';
import pages from '../../apis/pages';

interface ViewBusinessItemInformationProps {
  handlePageDataChange: (change: any) => void;
  businessItemFlow: BusinessItemFlow;
  selectedBusiness: Business | null;
  selectedBusinessItem: BusinessItem | null;
}

const ViewBusinessItemInformation: React.FC<ViewBusinessItemInformationProps> = ({
  handlePageDataChange,
  businessItemFlow, selectedBusiness, selectedBusinessItem
}) => {
  const [editTitleFormOpen, setEditTitleFormOpen] = useState(false);
  const [editDescriptionFormOpen, setEditDescriptionFormOpen] = useState(false);
  const [addDetailFormOpen, setAddDetailFormOpen] = useState(false);
  const [addFeatureFormOpen, setAddFeatureFormOpen] = useState(false);
  const [detailFormName, setDetailFormName] = useState('');
  const [detailFormDetail, setDetailFormDetail] = useState('');
  const [file, setFile] = useState<File | null>(null);


  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  }

  const renderDetailForm = (pageData: BusinessItemPageData) => {
    return (
      <Flex direction={'row'} align={'center'}>
        <FormControl flex={1}>
          <Input
            onChange={(e) => setDetailFormName(e.target.value)}
            placeholder='Name'
            value={detailFormName}
            style={{ borderRadius: 25 }}
          />
        </FormControl>
        <FormControl flex={1}>
          <Input
            onChange={(e) => setDetailFormDetail(e.target.value)}
            placeholder='Detail'
            value={detailFormDetail}
            style={{ borderRadius: 25 }}
          />
        </FormControl>
        <IconButton
          onClick={() => {
            handlePageDataChange({
              details: [
                ...pageData.details,
                {
                  detailName: detailFormName,
                  detailValue: detailFormDetail
                }
              ]
            })
            setAddDetailFormOpen(false);
            setDetailFormName('');
            setDetailFormDetail('');
          }}
          aria-label='Submit'
          colorScheme={'green'}
          borderRadius={25}
          ml={1}
        >
          <MdCheck />
        </IconButton>
        <IconButton
          onClick={() => setAddDetailFormOpen(false)}
          aria-label='Close'
          borderRadius={25}
          ml={1}
        >
          <MdClose />
        </IconButton>
      </Flex>
    )
  }

  const renderPageData = () => {
    const { pageData, pageKey } = businessItemFlow;
    if (pageKey && pageData) {
      const mediaRows: BusinessItemPageDataMedia[][] = [];
      for (let i = 0; i < pageData?.media.length; i += 5) {
        mediaRows.push(pageData?.media.slice(i, i + 5));
      }
      return (
        <>
          <Table>
            <Tbody>
              <Tr>
                <Th colSpan={2} textAlign={'center'} fontSize={'lg'}>Information</Th>
              </Tr>
              <Tr>
                <Th >Page Key</Th>
                <Td fontSize='sm' color='gray.600' as='u'>
                  <a href={`${pages}/${pageKey}`} target="_blank" rel="noreferrer">
                    <Button leftIcon={<LinkIcon />} size='xs'>{pageKey}</Button>
                  </a>
                </Td>
              </Tr>
              <Tr>
                <Th>Page Status</Th>
                <Td>
                  <Button
                    colorScheme={pageData.status === 'active' ? 'green' : pageData.status === 'pending' ? 'yellow' : 'gray'}
                    size="xs"
                  >
                    {pageData.status}
                  </Button>
                  {/* <AppDropdown items={<DropdownBusinessItemPageStatus item={selectedBusinessItem} business={selectedBusiness} />}>
                    <Button
                      colorScheme={pageData.status === 'active' ? 'green' : pageData.status === 'pending' ? 'yellow' : 'gray'}
                      size="xs"
                    >
                      {pageData.status}
                    </Button>
                  </AppDropdown> */}
                </Td>
              </Tr>
              <Tr>
                <Th>Title</Th>
                <Td>
                  {editTitleFormOpen ?
                    <QuickTextForm
                      initialText={pageData.title}
                      minLimit={2}
                      maxLimit={64}
                      onSubmit={(title: string) => {
                        handlePageDataChange({ title })
                        setEditTitleFormOpen(false);
                      }}
                      onClose={() => setEditTitleFormOpen(false)}
                    />
                    :
                    <>
                      {pageData.title}
                      {/* <IconButton
                        aria-label='Edit'
                        borderRadius={10} size='xs' ml={1} mb={1} variant={'ghost'}
                        onClick={() => {
                          setEditTitleFormOpen(true);
                        }}
                      >
                        <MdEdit />
                      </IconButton> */}
                    </>
                  }
                </Td>
              </Tr>
              <Tr>
            <Th>Media</Th>
            <Td>
            <Flex direction="column">
              {mediaRows.map((row, rowIndex) => (
                <Flex key={rowIndex} direction="row">
                  {row.map((media, index) => (
                    <div key={index} style={{ marginRight: '10px', marginBottom: '10px', position: 'relative' }}>
                      <Image
                        src={media.url}
                        alt={`Thumbnail ${index}`}
                        boxSize="100px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                    </div>
                  ))}
                </Flex>
              ))}
            </Flex> 
            { file && (
              <Text>{file.name}</Text>
            )}
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="image/*"
            />
            </Td>
          </Tr>
          <Tr>
            <Th>Description</Th>
            <Td>
            { editDescriptionFormOpen ?
              <QuickTextForm 
                initialText={pageData.description} 
                minLimit={2} 
                maxLimit={1024} 
                onSubmit={(description: string) => {
                  handlePageDataChange({ description })
                  setEditDescriptionFormOpen(false)
                }} 
                onClose={() => setEditDescriptionFormOpen(false)}
                asTextArea={true}
              />
              :
              <>
              {pageData.description}
              {/* <IconButton 
                aria-label='Edit' 
                borderRadius={10} 
                size='xs' ml={1} mb={1} variant={'ghost'} 
                onClick={() => {
                  setEditDescriptionFormOpen(true)
                }}
              >
                <MdEdit />
              </IconButton> */}
              </>
              }              
            </Td>
          </Tr>
          <Tr>
            <Th>Features</Th>
            <Td>
              {pageData.features.map((feature, index) => (
                <Tag borderRadius={'full'} mr={2} mb={2} colorScheme='orange'>
                  <TagLabel>{feature}</TagLabel>
                  {/* <TagCloseButton onClick={() => {
                    let features = pageData.features;
                    features.splice(index, 1);
                    handlePageDataChange({ features });
                  }}/> */}
                </Tag>
              ))}
              { addFeatureFormOpen ?
                <QuickTextForm 
                  initialText={''} 
                  minLimit={2} 
                  maxLimit={64} 
                  onSubmit={(newFeature: string) => {
                    handlePageDataChange({ features: [...pageData.features, newFeature] })
                    setAddFeatureFormOpen(false)
                  }} 
                  onClose={() => setAddFeatureFormOpen(false)}
                />
              :
              null
                // <IconButton 
                //   aria-label='Add' 
                //   borderRadius={10} 
                //   size='xs' ml={1} mb={1} variant={'ghost'} 
                //   onClick={() => {
                //     setAddFeatureFormOpen(true)
                //   }}
                // >
                //   <AddIcon />
                // </IconButton> 
                }            
            </Td>
          </Tr>
          <Tr>
            <Th>Details</Th>
            <Td>
            {pageData.details.map((detail, index) => (
                <Tag borderRadius={'full'} mr={2} mb={2} colorScheme='orange'>
                  <TagLabel>{detail.detailName}: {detail.detailValue}</TagLabel>
                  {/* <TagCloseButton onClick={() => {
                    let details = pageData.details;
                    details.splice(index, 1);
                    handlePageDataChange({ details });
                  }}/> */}
                </Tag>
              ))}
              { addDetailFormOpen ?
                renderDetailForm(pageData)
              :
              null
                // <IconButton 
                //   aria-label='Add' 
                //   borderRadius={10} 
                //   size='xs' ml={1} mb={1} variant={'ghost'} 
                //   onClick={() => {
                //     setAddDetailFormOpen(true)
                //   }}
                // >
                //   <AddIcon />
                // </IconButton> 
                }    
            </Td>
          </Tr>
            </Tbody>
          </Table>
        </>
      )
    } else {
      return null;
    }
  }

  if (selectedBusinessItem && selectedBusiness && businessItemFlow) {
    return (
      <div>{renderPageData()}</div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { businessItemFlow, selectedBusiness, selectedBusinessItem } = state.businesses;
  return { businessItemFlow, selectedBusiness, selectedBusinessItem };
};

export default connect(mapStateToProps, {
})(ViewBusinessItemInformation);
