import React, { useState, useEffect } from "react";
import { usePageVisibility } from 'react-page-visibility';
import { connect } from "react-redux";
import { Box, FormControl, Card, Input, Text, Button, SimpleGrid, Flex, Switch, Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";
import { AddIcon, UpDownIcon, ViewIcon } from "@chakra-ui/icons";
import moment from "moment";

import { ReduxState } from "../../store/state";
import Navbar from "../../components/navigation/Navbar";
import OrdersTable from "../../components/orders/OrdersTable";
import LoadingPage from "../loading/LoadingPage";
import { OrdersQuery } from "../../store/orders/types";
import { OrdersLoading } from "../../store/orders/types/common";
import { 
  toggleFilterOrdersModal, 
  updateOrdersQuery, 
  setOrdersViewMode ,
  setSearchOrdersLoading
} from "../../store/orders/actions";
import {
  fetchCurrentOrders,
  fetchOrders
} from "../../store/orders/initiators";
import { Business, BusinessLocation } from "../../store/businesses/types";
import { MdStorefront } from "react-icons/md";
import { colors } from "../../assets/colors";
import { setSelectedBusinessView } from "../../store/businesses/actions";
import { Link } from "react-router-dom";

interface OrdersPageProps {
    ordersQuery: OrdersQuery;
    ordersViewMode: string;
    ordersLoading: OrdersLoading;
    selectedBusiness: Business | null;
    selectedBusinessLocation: BusinessLocation | null;
    toggleFilterOrdersModal: () => void;
    updateOrdersQuery: (ordersQuery: OrdersQuery) => void;
    fetchCurrentOrders: (more: boolean) => void;
    fetchOrders: (more: boolean) => void;
    setOrdersViewMode: (mode: string) => void;
    setSearchOrdersLoading: (state: boolean) => void;
    setSelectedBusinessView: (view: 'locations' | 'users' | 'items' | 'ratings') => void;
}

const OrdersPage: React.FC<OrdersPageProps> = ({
  ordersQuery, ordersViewMode, ordersLoading, selectedBusiness, selectedBusinessLocation,
  toggleFilterOrdersModal, updateOrdersQuery,
  fetchCurrentOrders, fetchOrders, setOrdersViewMode, setSearchOrdersLoading,
  setSelectedBusinessView
}) => {

  let initialSearch = '';
  if (ordersQuery.search) {
    initialSearch = ordersQuery.search;
  }
  const [searchInput, setSearchInput] = useState(initialSearch);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const isVisible = usePageVisibility();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchInput(search);
    setSearchOrdersLoading(true);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimeout = setTimeout(() => {
      updateOrdersQuery({...ordersQuery, search, page: '1', currentPage: 1})
      handleFetch(false);
    }, 500);

    setDebounceTimeout(newTimeout);
  };

  useEffect(() => {
    // Clear the timeout when the component unmounts
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  const fetchMore = () => {
    const { currentPage } = ordersQuery;
    if (currentPage) {
      const newPage = `${currentPage+1}` 
      updateOrdersQuery({...ordersQuery, page: newPage})
      handleFetch(true)
    }
  }

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout | null = null;

    const startPolling = () => {
      pollingInterval = setInterval(() => {
        if (isVisible) {
          handleFetch(false);
        }
      }, 10000); // Adjust the polling interval as needed (e.g., every 10 seconds).
    };

    const stopPolling = () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
        pollingInterval = null;
      }
    };

    // Start polling when ordersViewMode is 'current', stop when it's not.
    if (ordersViewMode === 'current' && isVisible) {
      startPolling();
    } else {
      stopPolling();
    }

    // Clean up the interval when the component unmounts.
    return () => {
      stopPolling();
    };
  }, [ordersViewMode, isVisible]);
  
  const handleFetch = (more: boolean) => {
    const isLive = ordersViewMode === 'current'
    if (isLive) {
      fetchCurrentOrders(more);
    } else {
      fetchOrders(more);
    }

  }

  const renderMoreButton = () => {
    const { currentPage, totalPages } = ordersQuery;
    if (currentPage && totalPages && currentPage < totalPages && !ordersLoading.search) {
      return (
        <Box display="flex" justifyContent="center">
          <Button size='xs' leftIcon={<ViewIcon />} onClick={fetchMore}>More</Button>
        </Box>
      )
    }
    return null;

  }

  const resetFilter = (defaultFilter: any) => {
    setSearchOrdersLoading(true)
    updateOrdersQuery({...ordersQuery, ...defaultFilter})
    fetchOrders(false)
  }

  const renderFilterBox = () => {
    const searchFilters: any = {...ordersQuery}
    const selectors = ['status', 'sourceType', 'destinationType', 'currency', 'branch'];
    const ranges = ['Rating', 'Distance', 'OrderExpenses', 'OrderRevenue'];
    const timeranges = ['DeliveredAt', 'TargetDeliveryTime', 'ActualDeliveryTime'];
    return (
      <>
      {selectors.map(f => {
        const value = searchFilters[f];
        if (value) {
          return <Tag 
                    key={`${value}`}
                    size={'sm'} 
                    mt={2} 
                    ml={1} 
                    borderRadius={15}
                    colorScheme="orange"
                  >
                    <TagLabel>{`${f}: ${value}`}</TagLabel>
                    <TagCloseButton onClick={() => resetFilter({[f]: null})}/>
                </Tag>
        } else {
          return null;
        }
        
      })}
      {ranges.map(f => {
        const minValue = searchFilters['min'+f];
        const maxValue = searchFilters['max'+f];
        if (minValue || maxValue) {
          let text = f
          if (minValue && maxValue) {
            text = `${f}: ${minValue} to ${maxValue}`
          } else if (minValue && !maxValue) {
            text = `${f}: > ${minValue}`
          } else if (!minValue && maxValue) {
            text = `${f}: < ${maxValue}`
          }
          return <Tag 
                  size={'sm'} 
                  mt={2} 
                  ml={1} 
                  borderRadius={15}
                  colorScheme="orange"
                >
                  <TagLabel>{text}</TagLabel>
                  <TagCloseButton onClick={() => resetFilter({[`min${f}`]: null, [`max${f}`]: null})}/>
                </Tag>
        } else {
          return null;
        }

      })}
      {timeranges.map(f => {
        var minValue = searchFilters['min'+f];
        var maxValue = searchFilters['max'+f];
        if (minValue || maxValue) {
          minValue = moment(minValue).format('l')
          maxValue = moment(maxValue).format('l')
          let text = f
          if (minValue && maxValue) {
            text = `${f}: ${minValue} to ${maxValue}`
          } else if (minValue && !maxValue) {
            text = `${f}: > ${minValue}`
          } else if (!minValue && maxValue) {
            text = `${f}: < ${maxValue}`
          }
          return <Tag 
                    size={'sm'} 
                    mt={2} 
                    ml={1} 
                    borderRadius={15} 
                    colorScheme="orange"
                  >
                    <TagLabel>{text}</TagLabel>
                    <TagCloseButton onClick={() => resetFilter({[`min${f}`]: null, [`max${f}`]: null})}/>
                  </Tag>
        } else {
          return null;
        }

      })}
      </>
    )
  }

  const renderTabBox = () => {
    const isLive = ordersViewMode === 'current'
    if (isLive) {
      var toggleTo = 'filtered';
      var fetchTo = fetchOrders
    } else {
      toggleTo = 'current';
      fetchTo = fetchCurrentOrders
    }
    
    return (
      <Flex mr={2} mt={1}>
        <Text fontSize={'sm'} mr={2}>Live Mode </Text>
        <Switch id='isChecked' colorScheme="orange" isChecked={isLive} onChange={() => {
          setSearchOrdersLoading(true);
          setOrdersViewMode(toggleTo);
          fetchTo(false);
          }}
        />
      </Flex>
    )
  }

  const renderFilterOptions = () => {
    return (
      <Flex>
        {renderTabBox()}
        <Text fontSize="sm" paddingRight={4} paddingTop={1}>
          {ordersLoading.search || ordersQuery.totalItems === null ? 'Counting orders...' : `Found ${ordersQuery.totalItems.toLocaleString()} ${ordersQuery.totalItems === 1 ? 'order' : 'orders'}`}
        </Text>
        {ordersViewMode === 'filtered' ?
        <Button leftIcon={<UpDownIcon />} variant='outline' size='sm' borderRadius={15} onClick={toggleFilterOrdersModal}>
          Filter
        </Button>
        : null}
      </Flex>
    )
  }

  const renderOrdersPage = () => {
    return (
        <Box p={4}>
            <Card borderRadius={20} boxShadow="0 2px 10px rgba(0, 0, 0, 0.2)" zIndex={1} top="0" position="sticky">
                <Box p={5} position={'sticky'} top='0'>
                    {selectedBusiness && selectedBusinessLocation 
                    ?
                    <Text pb={2} fontSize={'sm'} color={'gray.600'}>{`${selectedBusiness.name}: ${selectedBusinessLocation.name} @ ${selectedBusinessLocation.address}`}</Text>
                    :
                    <Flex pb={2} alignItems={'center'}>
                    <Text fontSize={'sm'} color={'gray.600'}>Select a</Text>
                    <Link to='/businesses'>
                      <Button 
                        leftIcon={<MdStorefront />} 
                        size={'xs'} ml={1} mr={2} borderRadius={'full'}
                        bg={colors.primary}
                        color={'white'}
                        onClick={() => {
                          setSelectedBusinessView('locations')
                        }}
                      >
                        business location
                      </Button>
                    </Link>
                    <Text fontSize={'sm'} color={'gray.600'}>to view orders.</Text>
                    </Flex>
                    }
                    <FormControl>
                        <Input 
                          placeholder="Search by Order # or Customer Name" 
                          onInput={handleInput} 
                          value={searchInput ? searchInput : ''}
                          style={{ borderRadius: 25}}
                        />
                    </FormControl>
                    <SimpleGrid columns={2}>
                      <Box paddingTop={2} paddingLeft={1}>
                        {renderFilterOptions()}
                        </Box>
                    </SimpleGrid>
                    {renderFilterBox()}
                </Box>
            </Card>
            <Box p={2}>
                { ordersLoading.search ?
                <LoadingPage loadingText="Searching orders..."/> :
                <OrdersTable />
                }

            </Box>
            {renderMoreButton()}
            
        </Box>
    );
  }


    return (
      <div>
        <Navbar content={renderOrdersPage()} />
      </div>
    );
}

const mapStateToProps = (state: ReduxState) => {
    const { ordersQuery, ordersViewMode, ordersLoading } = state.orders;
    const { selectedBusiness, selectedBusinessLocation } = state.businesses;
    return { ordersQuery, ordersViewMode, ordersLoading, selectedBusiness, selectedBusinessLocation };
};

export default connect(mapStateToProps, {
  toggleFilterOrdersModal,
  updateOrdersQuery,
  fetchCurrentOrders,
  fetchOrders,
  setOrdersViewMode,
  setSearchOrdersLoading,
  setSelectedBusinessView
})(OrdersPage);
