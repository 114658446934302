import React from "react";
import { connect } from "react-redux";
import { Button } from "@chakra-ui/react";

import { asyncLogout } from "../../store/auth/initiators";
import { ReduxState } from "../../store/state";
import { User } from "../../store/user/types";

interface LogoutButtonProps {
  asyncLogout: (tokenId: string, userId: string, bulkLogout: boolean) => void;
  user: User | null,
  loginToken: string | null;
  altToken?: string;
  altText?: string;
  bulkLogout?: boolean | undefined;
}

class LogoutButton extends React.Component<LogoutButtonProps> {

  handleLogout = () => {
    const { asyncLogout, user, loginToken, altToken, bulkLogout } = this.props;
    if (loginToken && user) {
      let tokenToUse = loginToken;
      let shouldBulkLogout: boolean = false;
      if (altToken) {
        tokenToUse = altToken;
      }
      if (bulkLogout) {
        shouldBulkLogout = true;
      }
      asyncLogout(tokenToUse, user._id, shouldBulkLogout);
    }
  }


  render() {
    const { altToken, altText } = this.props;
    return (
      <Button colorScheme='gray' size='sm' onClick={this.handleLogout}>{altText ? altText : 'Sign Out'}</Button>
    );
  }
}


const mapStateToProps = (state: ReduxState) => {
  const { isLoggedIn, loginToken, errors } = state.auth;
  const { user } = state.user;
  return { user, isLoggedIn, loginToken, errors };
}

export default connect(mapStateToProps, {
  asyncLogout
})(LogoutButton);
