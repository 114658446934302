import { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ReduxState } from "./store/state";
import { checkLoginCookies } from "./store/auth/initiators";
import { setAuthLoading } from "./store/auth/actions";

import LoginPage from "./pages/auth/LoginPage";
import LoadingPage from "./pages/loading/LoadingPage";
import HomePage from "./pages/home/HomePage";
//import AccountPage from "./pages/auth/AccountPage";
//import UsersPage from "./pages/users/UsersPage";
import OrdersPage from "./pages/orders/OrdersPage";
import BusinessesPage from "./pages/businesses/BusinessesPage";
import NotFoundPage from "./pages/error/NotFoundPage";
import ForbiddenPage from "./pages/error/ForbiddenPage";
//import TransactionsPage from "./pages/transactions/TransactionsPage";


interface AppProps {
  setAuthLoading: (loading: boolean) => void;
  checkLoginCookies: () => void;
  isLoggedIn: boolean;
  loading: boolean;
}

class App extends Component<AppProps> {
  componentDidMount(): void {
    this.props.setAuthLoading(true);
    this.props.checkLoginCookies()
  }

  render() {
    const { isLoggedIn, loading } = this.props;
    if (loading) {
      return <LoadingPage />
    }
    return (
      <div className="App">
        {!isLoggedIn ? ( 
            <LoginPage />
        ) : (
          <div>
            <Router>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/businesses" element={<BusinessesPage />} />
                <Route path="/orders" element={<OrdersPage />} />
                {/* <Route path="/account" element={<AccountPage />} /> */}
                {/* <Route path="/users" element={<UsersPage />} />
                
                
                <Route path="/delivery" element={<DeliveryPage />} />
                <Route path="/funds" element={<FundsPage />} />
                <Route path="/transactions" element={<TransactionsPage />} /> */}
                <Route path="/forbidden" element={<ForbiddenPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Router>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { isLoggedIn, loading } = state.auth;
  return { isLoggedIn, loading };
};

export default connect(mapStateToProps, {
  setAuthLoading,
  checkLoginCookies
})(App);

