import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';

import {
  OrdersQuery,
} from '../types';
import {
  getOrdersSuccess,
  getOrdersFailure,
  setSearchOrdersLoading,
} from '../actions';

// Thunk action to fetch orders
export const fetchOrders = (more: boolean = false) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;
      const { ordersQuery, orders } = getState().orders;
      const { selectedBusiness, selectedBusinessLocation } = getState().businesses;

      if (selectedBusiness && selectedBusinessLocation) {
        const { search, page, sortBy, sortDirection } = ordersQuery;
        let fetchedOrders: any[] = [];
  
        // Construct the headers with X-Auth-Token
        const headers = {
          'X-Auth-Token': loginToken,
        };
  
        // Construct the URL with query parameters
        let url = `/commerce-service/api/v1/orders?sourceId=${selectedBusinessLocation._id}&describe=1`;
        const searchFilters = [
          'status',
          'minTargetDeliveryTime', 'maxTargetDeliveryTime',
          'minDeliveredAt', 'maxDeliveredAt'
        ]
  
        if (search) {
          url += `&search=${search}`;
        }
  
        if (page) {
          url += `&page=${page}`;
        }
  
        if (sortBy) {
          url += `&sortBy=${sortBy}`;
        }
  
        if (sortDirection) {
          url += `&sortDirection=${sortDirection}`;
        }
  
        const searchBy: any = {...ordersQuery};
        searchFilters.forEach((filter) => {
          if (searchBy[filter]) {
            url += `&${filter}=${searchBy[filter]}`;
          }
        });
  
        const response = await backend.get(url, { headers });
  
        if (response.status === 200) {
          const {
            currentPage,
            itemsPerPage,
            totalPages,
            totalItems,
            currentOrdersCount
          } = response.data;
          const newOrdersQuery: OrdersQuery = {
            ...ordersQuery,
            search,
            currentPage,
            itemsPerPage,
            totalPages,
            totalItems,
            sortBy,
            sortDirection,
            page,
            currentOrdersCount
          };
          if (more) {
            fetchedOrders = [...orders].concat(response.data.orders);
          } else {
            fetchedOrders = response.data.orders;
          }
          dispatch(setSearchOrdersLoading(false));
          dispatch(getOrdersSuccess(newOrdersQuery, fetchedOrders));
        } else {
          dispatch(setSearchOrdersLoading(false));
          dispatch(getOrdersFailure('Failed to fetch orders.'));
        }
      } else {
        dispatch(getOrdersSuccess(ordersQuery, []));
        dispatch(setSearchOrdersLoading(false));
      }

    } catch (error) {
      console.error('Error fetching orders:', error);
      dispatch(setSearchOrdersLoading(false));
      dispatch(getOrdersFailure('An error occurred while fetching orders.'));
    }
  };
};