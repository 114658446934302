import { 
  OrdersActionTypes, 
} from '../types';

// CHANGE VIEWS

// Action to toggle the filter orders modal
export const toggleFilterOrdersModal = (): OrdersActionTypes => ({
  type: 'TOGGLE_FILTER_ORDERS_MODAL',
});

// Action to toggle the view order modal
export const toggleViewOrderModal = (): OrdersActionTypes => ({
  type: 'TOGGLE_VIEW_ORDER_MODAL',
});

// Action to set orders view mode
export const setOrdersViewMode = (mode: string): OrdersActionTypes => ({
  type: 'SET_ORDERS_VIEW_MODE',
  payload: mode,
});