import React from 'react';
import { connect } from "react-redux";
import {   
  Table,
  Tbody,
  Tr, Th, Td,
  Switch,
} from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';

import { ReduxState } from "../../store/state";
import { BusinessItemFlow, Business, BusinessItem } from '../../store/businesses/types';

interface ViewBusinessItemContactProps {
  handlePageDataChange: (change: any) => void;
  businessItemFlow: BusinessItemFlow;
  selectedBusiness: Business | null;
  selectedBusinessItem: BusinessItem | null;
}

const ViewBusinessItemContact: React.FC<ViewBusinessItemContactProps> = ({
  handlePageDataChange,
  businessItemFlow,
  selectedBusiness,
  selectedBusinessItem,
}) => {

  const renderPageData = () => {
    const { pageData, pageKey } = businessItemFlow;
    if (pageKey && pageData) {
      return (
        <>
        <Table>
        <Tbody>
          <Tr>
            <Th colSpan={2} textAlign={'center'} fontSize={'lg'}>Contact</Th>
          </Tr>
          <Tr>
            <Th>Show Phone Number</Th>
            <Td>
              <Switch id='isChecked' isChecked={pageData.displayPhoneNumber} colorScheme="orange" mr={2} onChange={() => {
                handlePageDataChange({ displayStockStatus: !pageData.displayPhoneNumber });
                }}
              />  
            </Td>
          </Tr>
          <Tr>
            <Th>Show Email Address</Th>
            <Td>
              <Switch id='isChecked' isChecked={pageData.displayEmail} colorScheme="orange" mr={2} onChange={() => {
                handlePageDataChange({ displayEmail: !pageData.displayEmail });
                }}
              />  
            </Td>
          </Tr>
        </Tbody>
        </Table>
        </>
      )
    } else {
      return null;
    }

  }
  if (selectedBusinessItem && selectedBusiness && businessItemFlow) {
    return (
      <div>{renderPageData()}</div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { businessItemFlow, selectedBusiness, selectedBusinessItem } = state.businesses;
  return { businessItemFlow, selectedBusiness, selectedBusinessItem };
};

export default connect(mapStateToProps, {
})(ViewBusinessItemContact);
