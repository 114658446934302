import React, { useState } from 'react';
import { Box, Button, Card, CardBody, Table, Tbody, Tr, Th, Td, Text, Center } from '@chakra-ui/react';
import { BusinessUser } from '../../store/businesses/types';
import NoContentPage from '../../pages/noContent/NoContentPage';
import { formatPhoneNumber } from 'react-phone-number-input';
import LoadingPage from '../../pages/loading/LoadingPage';

interface ViewBusinessUsersProps {
  businessView: string;
  selectedBusinessUsers: BusinessUser[];
  loading: boolean;
}

const ViewBusinessUsers: React.FC<ViewBusinessUsersProps> = ({ 
  businessView, selectedBusinessUsers, loading
}) => {
  const [viewDeleted, setViewDeleted] = useState(false);
  const deletedCount = selectedBusinessUsers.filter(obj => obj.deleted).length;
  
  if (businessView!=='users') {
    return null;
  }

  const renderUser = (item: BusinessUser, index: number) => {
    return (
      <Tr key={index}>
        <Td fontSize={'sm'}>
         <Text>{`${item.firstName} ${item.lastName}`}</Text>
          { item.phoneNumber && (
            <>
            <Text fontSize={'xs'} as={'u'}>
              <a href={`tel:${item.phoneNumber}`}>
                {formatPhoneNumber(item.phoneNumber)}
              </a>
            </Text><br />
            </>
          )}
          { item.email && (
            <>
            <Text fontSize={'xs'} as={'u'}>
              <a href={`mailto:${item.email}`}>
                {item.email}
              </a>
            </Text><br />
            </>
          )}
        </Td>
        <Td>
          {item.scopes.map((scope: string, index: number) => (
            <Button size='xs' mt={2} key={index}>
              {scope}
            </Button>
          ))}
        </Td>
      </Tr>
    );
  }
  
  const renderDeletedToggle = () => {
    if (deletedCount) {
      return (
        <Center mt={2} textAlign={'center'}>
          <Button onClick={() => setViewDeleted(!viewDeleted)} size={'xs'}>
            {viewDeleted ? 'Hide' : 'Show'} {deletedCount} deleted user{deletedCount !== 1 ? 's' : ''}
          </Button>
        </Center>
      );
    } else {
      return null;
    }
  }

  if (loading) {
    return <LoadingPage loadingText='Loading Users' />
  }
  return (
    <Box paddingTop={5} paddingBottom={5}>
      <Card borderRadius={20}>
        <CardBody>
          {selectedBusinessUsers.length ? (
          <Table>
            <Tbody>
              <Tr>
                <Th>User</Th>
                <Th>Scopes</Th>
              </Tr>
              {selectedBusinessUsers.map((item: BusinessUser, index: number) => (
                !item.deleted ? renderUser(item, index) : null
              ))}
               {renderDeletedToggle()}
              {viewDeleted ? 
              selectedBusinessUsers.map((item: BusinessUser, index: number) => (
                item.deleted ? renderUser(item, index) : null
              )) : null}
            </Tbody>
          </Table>  
          )
          : (
            <NoContentPage
              noContentText='No Users Found' 
            />
          )}               
        </CardBody>
      </Card>  
    </Box>      
  );
}

export default ViewBusinessUsers;
