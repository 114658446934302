import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface VerticalBarChartDataset {
  fill: boolean;
  label: string;
  data: number[];
  backgroundColor: string;
}

interface VerticalBarChartProps {
  title: string;
  labels: string[];
  datasets: VerticalBarChartDataset[];
  
}

const VerticalBarChart: React.FC<VerticalBarChartProps> = ({title, labels, datasets}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: title,
      },
    },
  };  
  return (
    <Bar
      options={options}
      data={{
        labels,
        datasets
      }}
    />
  )
}

export default VerticalBarChart;