import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  Select,
  Flex,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderMark
} from '@chakra-ui/react';
import { ReduxState } from '../../store/state';
import { OrderModals, OrdersQuery } from '../../store/orders/types';
import { toggleFilterOrdersModal, updateOrdersQuery, clearOrdersQuery, setSearchOrdersLoading } from '../../store/orders/actions';
import { fetchOrders } from '../../store/orders/initiators';
import { convertUTCtoLocal, convertLocalToUTC } from '../global/data/convertTimezoneHelper';

interface FilterOrdersModalProps {
  toggleFilterOrdersModal: () => void;
  orderModals: OrderModals;
  ordersQuery: OrdersQuery
  fetchOrders: (more: boolean) => void;
  updateOrdersQuery: (ordersQuery: OrdersQuery) => void;
  clearOrdersQuery: () => void;
  setSearchOrdersLoading: (state: boolean) => void;
}

interface FilterSelector {
  key: string;
  label: string;
}

const FilterOrdersModal: React.FC<FilterOrdersModalProps> = ({
  toggleFilterOrdersModal,
  fetchOrders,
  updateOrdersQuery,
  clearOrdersQuery,
  setSearchOrdersLoading,
  orderModals,
  ordersQuery
}) => {
  const updatedQuery = ordersQuery;
  const [visibleFilters, setVisibleFilters] = useState<string[]>([])

  const handleChange = (change: any) => {
    updateOrdersQuery({...updatedQuery, ...change})
  }

  const handleSubmit = () => {
    setSearchOrdersLoading(true)
    fetchOrders(false)
    toggleFilterOrdersModal()
  };

  const renderFilterSelectors = () => {
    const filterTypes: FilterSelector[] = [
      { key: 'status', label: 'Status' },
      { key: 'sourceType', label: 'Source Type' },
      { key: 'destinationType', label: 'Destination Type' },
      { key: 'rating', label: 'Rating' },
      { key: 'distance', label: 'Distance' },
      { key: 'orderExpenses', label: 'Order Expenses' },
      { key: 'orderRevenue', label: 'Order Revenue' },
      { key: 'currency', label: 'Currency' },
      { key: 'targetDeliveryTime', label: 'Target Delivery Time' },
      { key: 'deliveredAt', label: 'Actual Delivery Time' },
      { key: 'branch', label: 'Branch' },
      { key: 'sort', label: 'Sort' },
    ]
    return filterTypes.map(f => {
      const isActive = visibleFilters.includes(f.key)
      const handleClick = () => {
        if (isActive) {
          setVisibleFilters(visibleFilters.filter(key => key !== f.key));
        } else {
          setVisibleFilters([...visibleFilters, f.key]);
        }
      };
      return (
        <Button
          m={1}
          borderRadius={25}
          variant="outline"
          key={f.key}
          colorScheme={isActive ? 'green' : 'black'}
          onClick={handleClick}
        >
          {f.label}
        </Button>
      )
    })
  }


  const renderStatusFilter = (isVisible: boolean) => {
    const allowedStatusTypes = [
      'created',
      'awaiting payment',
      'accepted',
      'started',
      'picked up',
      'on the way',
      'delivered',
      'cancelled',
      'failed'
    ];

    if (isVisible) {
      return (
        <FormControl>
          <FormLabel fontWeight={'normal'} htmlFor='status'>Status</FormLabel>
          <Select 
            placeholder='Select status' 
            id='status' 
            value={updatedQuery.status ? updatedQuery.status : ''}
            borderRadius={25}
            onChange={(event) => handleChange({status: event.target.value})}
          >
          {allowedStatusTypes.map((status: string) => (
            <option key={status}>{status}</option>
          ))}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  }

  const renderCurrencyFilter = (isVisible: boolean) => {
    if (isVisible) {
      return (
        <FormControl mt={4}>
          <FormLabel fontWeight={'normal'} htmlFor='currency'>Currency</FormLabel>
          <Select 
            placeholder='Select currency' 
            id='currency'
            value={updatedQuery.currency ? updatedQuery.currency : ''}
            borderRadius={25}
            onChange={(event) => handleChange({currency: event.target.value})}
          >
            <option>JMD</option>
            <option>CAD</option>
            <option>GBP</option>
            <option>USD</option>
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  }

  const renderTargetDeliveryTimeFilter = (isVisible: boolean) => {
    if (isVisible) {
      return (
        <Flex>
          <FormControl mt={4} mr={4}>
            <FormLabel fontWeight={'normal'} htmlFor='targetDeliveryTime'>Target Delivery Time (After)</FormLabel>
            <Input 
              id="targetDeliveryTime" 
              placeholder="Target Delivery Time (After)" 
              type="datetime-local"
              value={updatedQuery.minTargetDeliveryTime ? convertUTCtoLocal(updatedQuery.minTargetDeliveryTime) :''}
              style={{ borderRadius: 25}}
              onChange={e => handleChange({ minTargetDeliveryTime: convertLocalToUTC(e.target.value)})}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel fontWeight={'normal'} htmlFor='targetDeliveryTime'>Target Delivery Time (Before)</FormLabel>
            <Input 
              id="targetDeliveryTime" 
              placeholder="Target Delivery Time (Before)" 
              type="datetime-local"
              value={updatedQuery.maxTargetDeliveryTime ? convertUTCtoLocal(updatedQuery.maxTargetDeliveryTime) :''}
              style={{ borderRadius: 25}}
              onChange={e => handleChange({ maxTargetDeliveryTime: convertLocalToUTC(e.target.value)})}
            />
          </FormControl>
        </Flex>
      );
    } else {
      return null;
    }
  }

  const renderActualDeliveryTimeFilter = (isVisible: boolean) => {
    if (isVisible) {
      return (
        <Flex>
          <FormControl mt={4} mr={4}>
            <FormLabel fontWeight={'normal'} htmlFor='deliveredAt'>Actual Delivery Time (After)</FormLabel>
            <Input 
              id="deliveredAt" 
              placeholder="Actual Delivery Time (After)" 
              type="datetime-local"
              value={updatedQuery.minDeliveredAt ? convertUTCtoLocal(updatedQuery.minDeliveredAt) :''}
              style={{ borderRadius: 25}}
              onChange={e => handleChange({ minDeliveredAt: convertLocalToUTC(e.target.value)})}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel fontWeight={'normal'} htmlFor='deliveredAt'>Actual Delivery Time (Before)</FormLabel>
            <Input 
              id="deliveredAt" 
              placeholder="Actual Delivery Time (Before)" 
              type="datetime-local"
              value={updatedQuery.maxDeliveredAt ? convertUTCtoLocal(updatedQuery.maxDeliveredAt) :''}
              style={{ borderRadius: 25}}
              onChange={e => handleChange({ maxDeliveredAt: convertLocalToUTC(e.target.value)})}
            />
          </FormControl>
        </Flex>
      );
    } else {
      return null;
    }
  }

  const renderSortFilter = (isVisible: boolean) => {
    if (isVisible) {
      return (
        <Flex>
          <FormControl mt={4}>
            <FormLabel fontWeight={'normal'} htmlFor='sortBy'>Sort By</FormLabel>
            <Select 
              placeholder='Select field to sort by' 
              id='sortBy'
              value={updatedQuery.sortBy ? updatedQuery.sortBy : ''}
              borderRadius={25}
              onChange={(event) => handleChange({sortBy: event.target.value})}
            >
              <option value='rating'>Rating</option>
              <option value='distance'>Distance</option>
              <option value='orderExpenses'>Order Expenses</option>
              <option value='orderRevenue'>Order Revenue</option>
              <option value='orderNumber'>Order Number</option>
              <option value='targetDeliveryTime'>Target Delivery Time</option>
              <option value='deliveredAt'>Actual Delivery Time</option>
              <option value='createdAt'>Creation Time</option>
              <option value='updatedAt'>Last Updated</option>
            </Select>
          </FormControl>
          <FormControl mt={4} ml={4}>
            <FormLabel fontWeight={'normal'} htmlFor='sortDirection'>Sort Direction</FormLabel>
            <Select 
              placeholder='Select sort direction' 
              id='sortDirection'
              value={updatedQuery.sortDirection ? updatedQuery.sortDirection : ''}
              borderRadius={25}
              onChange={(event) => handleChange({sortDirection: event.target.value})}
            >
              <option value='desc'>Descending</option>
              <option value='asc'>Ascending</option>
            </Select>
          </FormControl>
        </Flex>
      );
    } else {
      return null;
    }
  }
  return (
    <Modal
      isOpen={orderModals.filterOrdersModalOpen}
      onClose={toggleFilterOrdersModal}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: 25 }}>
        <ModalHeader>Filter Orders</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          {renderFilterSelectors()}
          {renderStatusFilter(visibleFilters.includes('status'))}
          {renderCurrencyFilter(visibleFilters.includes('currency'))}
          {renderTargetDeliveryTimeFilter(visibleFilters.includes('targetDeliveryTime'))}
          {renderActualDeliveryTimeFilter(visibleFilters.includes('deliveredAt'))}
          {renderSortFilter(visibleFilters.includes('sort'))}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={() => {toggleFilterOrdersModal(); clearOrdersQuery()}} borderRadius={25}>
            Back
          </Button>
          <Button
            colorScheme="green"
            variant="outline"
            ml={3} mr={3}
            onClick={handleSubmit}
            borderRadius={25}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { orderModals, ordersQuery } = state.orders;
  return { orderModals, ordersQuery };
};

export default connect(mapStateToProps, {
  toggleFilterOrdersModal,
  fetchOrders,
  updateOrdersQuery,
  clearOrdersQuery,
  setSearchOrdersLoading
})(FilterOrdersModal);
