import { BusinessItem, SelectedBusinessAttributeDetails, BusinessesActionTypes, BusinessItemFlow } from '../types';

// Action types related to business items

export const getSelectedBusinessItemsSuccess = (items: BusinessItem[], query: SelectedBusinessAttributeDetails): BusinessesActionTypes => ({
    type: 'GET_SELECTED_BUSINESS_ITEMS_SUCCESS',
    payload: { items, query },
});

export const getSelectedBusinessItemsFailure = (payload: string): BusinessesActionTypes => ({
    type: 'GET_SELECTED_BUSINESS_ITEMS_FAILURE',
    payload,
});

export const updateBusinessItemsQuery = (payload: SelectedBusinessAttributeDetails): BusinessesActionTypes => ({
    type: 'UPDATE_BUSINESS_ITEMS_QUERY',
    payload,
});

export const selectBusinessItem = (payload: BusinessItem | null): BusinessesActionTypes => ({
    type: 'SELECT_BUSINESS_ITEM',
    payload,
});

// Action to handle successful retrieval of business items
export const editSelectedBusinessItemSuccess = (item: BusinessItem): BusinessesActionTypes => ({
    type: 'EDIT_SELECTED_BUSINESS_ITEM_SUCCESS',
    payload: item,
  });
  
  // Action to handle failure in retrieving business items
  export const editSelectedBusinessItemFailure = (error: string): BusinessesActionTypes => ({
    type: 'EDIT_SELECTED_BUSINESS_ITEM_FAILURE',
    payload: error,
  });
  
  // Action to update create business input
  export const updateBusinessItemInput = (
    businessItemFlow: BusinessItemFlow
  ): BusinessesActionTypes => ({
    type: 'UPDATE_BUSINESS_ITEM_INPUT',
    payload: businessItemFlow,
  });