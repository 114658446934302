import { Dispatch } from 'redux';

import backend from '../../../apis/backend';
import { logout, setAuthLoading } from '../actions';
import { updateUser } from '../../user/actions';
import { reloadUser } from './reloadUser';
import { fetchBusinesses } from '../../businesses/initiators';

export const checkLoginCookies = () => {
  return async (dispatch: Dispatch<any>) => {
    const tokenId = localStorage.getItem('showfaPartnerLoginToken');
    const userId = localStorage.getItem('showfaPartnerUserId');
    if (tokenId && userId) {
      try {
        const response = await backend.post('/api/v1/check-token', { tokenId, userId, app: 'partner-ui' });
        if (response.status === 200) {
          dispatch(reloadUser(tokenId, userId));
          dispatch(setAuthLoading(false));
        } else {
          dispatch(logout());
          dispatch(updateUser(null));
          dispatch(setAuthLoading(false));
        }
      } catch (error) {
        console.error('Error checking token:', error);
        dispatch(logout());
        dispatch(updateUser(null));
        dispatch(setAuthLoading(false));
      }
    } else {
      dispatch(logout());
      dispatch(updateUser(null));
      dispatch(setAuthLoading(false));
    }
  };
};