import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { ChakraProvider } from '@chakra-ui/react';

import './index.css';
import App from './App';
import store from './store/store';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reduxStore = createStore(
        store,
        composeEnhancers(applyMiddleware(reduxThunk))
    );

ReactDOM.render(
  <Provider store={reduxStore}>
    <ChakraProvider>
        <App />
    </ChakraProvider>
  </Provider>,
  document.querySelector('#root')
);
reportWebVitals();
