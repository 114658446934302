import { Dispatch } from 'redux';

import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { loginSuccess, loginFailure } from '../actions';
import { updateUser } from '../../user/actions';
import { osName, osVersion, browserName, browserVersion, mobileVendor, mobileModel, deviceType } from 'react-device-detect';
import { fetchBusinesses } from '../../businesses/initiators';
import { setBusinessesLoading } from '../../businesses/actions';

export const asyncCheckVerificationCode = (navigation: any, location: any) => {
  return async (dispatch: any, getState: () => ReduxState) => {
    try {
      const { phoneNumber, verificationCode } = getState().auth.loginFlow;
      const response = await backend.post(`/api/v1/check-verification-code`, 
      { 
        phoneNumber, 
        verificationCode, 
        app: 'partner-ui',
        data: {
          osName, osVersion, browserName, browserVersion, 
          mobileVendor, mobileModel, deviceType, location        
        } 
      });
      if (response.status === 200) {
        const { user, token } = response.data;
        const userId = user._id;
        dispatch(loginSuccess(token));
        dispatch(updateUser(user));
        dispatch(fetchBusinesses(userId));
        localStorage.setItem('showfaPartnerLoginToken', token);
        localStorage.setItem('showfaPartnerUserId', user._id);
        navigation.next()
      } else {
        dispatch(loginFailure('Invalid verification code. Try again.'));
        dispatch(updateUser(null));
      }
    } catch (error) {
      console.error('Invalid verification code. Try again.', error);
      dispatch(loginFailure('Invalid verification code. Try again.'));
      dispatch(updateUser(null));
    }
  };
};