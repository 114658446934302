import { Component } from 'react';
import 'react-phone-number-input/style.css'
import { connect } from 'react-redux';
import { ReduxState } from '../../store/state';
import {
  FormControl,
  Text,
  Card,
  CardBody,
} from '@chakra-ui/react';
import PhoneInput from 'react-phone-number-input';
import { setLoginInputs } from '../../store/auth/actions';
import { UserLoginFlow } from '../../store/auth/types';
import AppButton from '../global/forms/AppButton';
import { colors } from '../../assets/colors';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface UserPhoneNumberFormProps {
  loginFlow: UserLoginFlow,
  setLoginInputs: (update: UserLoginFlow) => void;
  goBack: () => void;
  goForward: () => void;
  goForwardAltWA: () => void;
}

class UserPhoneNumberForm extends Component<UserPhoneNumberFormProps> {
  handleSubmit = () => {
    const { loginFlow } = this.props;
    this.props.setLoginInputs(loginFlow);
  };

  handlePhoneNumberChange = (text: string) => {
    const { loginFlow } = this.props;
    let formattedNumber = '';
    let rawPhoneNumber = text;
    let error = '';

    if (text) {
      const numericPhone = text.replace(/[^+0-9]/g, '');
      const phoneNumber = parsePhoneNumberFromString(numericPhone);

      if (phoneNumber && phoneNumber.isValid()) {
        formattedNumber = phoneNumber.format('E.164');
        rawPhoneNumber = formattedNumber;
      } else {
        error = 'Enter a valid phone number';
      }
    } else {
      error = 'Enter a valid phone number';
    }

    this.props.setLoginInputs({
      ...loginFlow,
      phoneNumber: formattedNumber,
      rawPhoneNumber,
      errors: {
        enterPhoneNumber: error,
      },
    });
  };

  phoneNumberSection = (loginFlow: UserLoginFlow) => {
    const { errors } = loginFlow;
    return (
      <FormControl flex={4} pb={5}>
        <PhoneInput
          id="phoneNumber"
          placeholder="+18769990000"
          onChange={this.handlePhoneNumberChange}
          value={loginFlow.rawPhoneNumber}
          style={{
            width: '100%',
            borderColor: '#DEDEDE',
            borderWidth: 1,
            padding: 8,
            paddingLeft: 15,
            borderRadius: 25,
          }}
        />
        {errors && errors.enterPhoneNumber && loginFlow.rawPhoneNumber.length > 5 && (
          <Text fontSize={'xs'} color={'red'}>
            {errors.enterPhoneNumber}
          </Text>
        )}
        <AppButton text={'Back'} onClick={() => this.props.goBack()} color={colors.shade1} />
        <AppButton text={'Send code via WhatsApp'} onClick={() => this.props.goForwardAltWA()} isDisabled={!loginFlow.phoneNumber || (errors && errors.enterPhoneNumber && errors.enterPhoneNumber.length)}/>
        <AppButton text={'Send code via SMS'} color={'#F7941D'} onClick={() => this.props.goForward()} isDisabled={!loginFlow.phoneNumber || (errors && errors.enterPhoneNumber && errors.enterPhoneNumber.length)}/>
      </FormControl>
    );
  };

  render() {
    const { loginFlow } = this.props;
    return (
      <Card borderRadius={20}>
        <CardBody padding={8}>
        <Text fontWeight={'bold'}>Welcome!</Text>
        <Text pb={5}>Enter your phone number to get started.</Text>
          {this.phoneNumberSection(loginFlow)}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { loginFlow } = state.auth;
  return { loginFlow };
};

export default connect(mapStateToProps, {
  setLoginInputs
})(UserPhoneNumberForm);
