import React from 'react';
import { connect } from "react-redux";
import { Stack, Text } from '@chakra-ui/react';
import { setSelectedBusinessItemView } from '../../store/businesses/actions';
import { ReduxState } from '../../store/state';
import { MdInfo, MdInventory, MdLocalShipping, MdPalette, MdPayments, MdPending, MdPhone, MdSell, MdStar } from 'react-icons/md';


interface ViewBusinessItemViewSelectorProps {
  setSelectedBusinessItemView: (view: 'information' | 'inventory' | 'contact' | 'design' | 'discounts' | 'payment' | 'processing' | 'delivery' | 'feedback') => void;
  businessItemView: 'information' | 'inventory' | 'contact' | 'design' | 'discounts' | 'payment' | 'processing' | 'delivery' | 'feedback';
}

const ViewBusinessItemViewSelectors: React.FC<ViewBusinessItemViewSelectorProps> = ({ 
  setSelectedBusinessItemView, businessItemView }) => {
  return (
      <Stack direction="row" justify="center" spacing={6}>
        <ViewSelectorButton
          label="Information"
          view="information"
          businessItemView={businessItemView}
          count={'*'}
          icon={<MdInfo />}
          setSelectedBusinessItemView={setSelectedBusinessItemView}
        />
        <ViewSelectorButton
          label="Inventory"
          view="inventory"
          businessItemView={businessItemView}
          count={'*'}
          icon={<MdInventory />}
          setSelectedBusinessItemView={setSelectedBusinessItemView}
        />
        {/* <ViewSelectorButton
          label="Contact"
          view="contact"
          businessItemView={businessItemView}
          count={'*'}
          icon={<MdPhone />}
          setSelectedBusinessItemView={setSelectedBusinessItemView}
        /> */}
        {/* <ViewSelectorButton
          label="Design"
          view="design"
          businessItemView={businessItemView}
          count={'*'}
          icon={<MdPalette />}
          setSelectedBusinessItemView={setSelectedBusinessItemView}
        /> */}
        <ViewSelectorButton
          label="Discounts"
          view="discounts"
          businessItemView={businessItemView}
          count={'*'}
          icon={<MdSell />}
          setSelectedBusinessItemView={setSelectedBusinessItemView}
        />
        <ViewSelectorButton
          label="Payment"
          view="payment"
          businessItemView={businessItemView}
          count={'*'}
          icon={<MdPayments />}
          setSelectedBusinessItemView={setSelectedBusinessItemView}
        />
        <ViewSelectorButton
          label="Processing"
          view="processing"
          businessItemView={businessItemView}
          count={'*'}
          icon={<MdPending />}
          setSelectedBusinessItemView={setSelectedBusinessItemView}
        />
        <ViewSelectorButton
          label="Delivery"
          view="delivery"
          businessItemView={businessItemView}
          count={'*'}
          icon={<MdLocalShipping />}
          setSelectedBusinessItemView={setSelectedBusinessItemView}
        />
        {/* <ViewSelectorButton
          label="Feedback"
          view="feedback"
          businessItemView={businessItemView}
          count={'*'}
          icon={<MdStar />}
          setSelectedBusinessItemView={setSelectedBusinessItemView}
        /> */}
      </Stack>
  );
}

interface ViewSelectorButtonProps {
  label: string;
  view: 'information' | 'inventory' | 'contact' | 'design' | 'discounts' | 'payment' | 'processing' | 'delivery' | 'feedback';
  businessItemView: 'information' | 'inventory' | 'contact' | 'design' | 'discounts' | 'payment' | 'processing' | 'delivery' | 'feedback';
  count: number | string;
  icon?: React.ReactNode;
  setSelectedBusinessItemView: (view: 'information' | 'inventory' | 'contact' | 'design' | 'discounts' | 'payment' | 'processing' | 'delivery' | 'feedback') => void;
}
const ViewSelectorButton: React.FC<ViewSelectorButtonProps> = ({ 
  label, view, businessItemView, count, icon, setSelectedBusinessItemView }) => {
  return (
    <Stack 
      spacing={0} 
      align={'center'} 
      _hover={{
        outline: '2px dotted #F7941D'
      }}
      style={view === businessItemView ? { outline: '2px solid #F7941D'} : {}}
      p="1"
      mx="1"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      onClick={() => {setSelectedBusinessItemView(view)}}
    >
      { icon ? 
      icon :
      <Text fontWeight={600}>{count}</Text>
      }
      
      <Text fontSize={'sm'} color={'gray.500'}>
        {label}
      </Text>
    </Stack>
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { 
    businessItemView
  } = state.businesses;
  return {     
    businessItemView,
  };
};

export default connect(mapStateToProps, {
setSelectedBusinessItemView,
})(ViewBusinessItemViewSelectors);
