import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';

import { Transaction } from '../../transactions/types';
import {
  getOrderTransactionsSuccess,
  getOrderTransactionsFailure,
} from '../actions';

// Thunk action to fetch order transactions
export const fetchOrderTransactions = (orderId: string) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;

      // Construct the headers with X-Auth-Token
      const headers = {
        'X-Auth-Token': loginToken,
      };

      const response = await backend.get(`/commerce-service/api/v1/orders/${orderId}/transactions?describe=1`, { headers });

      if (response.status === 200) {
        const transactions: Transaction[] = response.data;
        dispatch(getOrderTransactionsSuccess(transactions));
      } else {
        dispatch(getOrderTransactionsFailure('Failed to fetch order transactions.'));
      }
    } catch (error) {
      console.error('Error fetching order transactions:', error);
      dispatch(getOrderTransactionsFailure('An error occurred while fetching order transactions.'));
    }
  };
};