import React from 'react';
import { connect } from "react-redux";
import {   
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';

import { ReduxState } from "../../store/state";
import { BusinessItemFlow, Business, BusinessModals, BusinessItem, BusinessItemPageDataMedia } from '../../store/businesses/types';
import { 
  toggleViewBusinessItemModal,
  updateBusinessItemInput 
} from "../../store/businesses/actions";
import { asyncBusinessItemEdit, fetchBusinessItems } from '../../store/businesses/initiators';
import ViewBusinessItemViewSelector from './ViewBusinessItemViewSelector';
import ViewBusinessItemInformation from './ViewBusinessItemInformation';
import ViewBusinessItemInventory from './ViewBusinessItemInventory';
import ViewBusinessItemContact from './ViewBusinessItemContact';
import ViewBusinessItemDesign from './ViewBusinessItemDesign';
import ViewBusinessItemDiscounts from './ViewBusinessItemDiscounts';
import ViewBusinessItemPayment from './ViewBusinessItemPayment';
import ViewBusinessItemProcessing from './ViewBusinessItemProcessing';
import ViewBusinessItemDelivery from './ViewBusinessItemDelivery';

interface ViewBusinessItemModalProps {
  toggleViewBusinessItemModal: () => void;
  updateBusinessItemInput: (inputs: BusinessItemFlow) => void;
  asyncBusinessItemEdit: (itemId: string, updatedItemData: BusinessItem, refreshFlow: boolean) => void;
  fetchBusinessItems: (businessId: string, more: boolean, suppressLoading: boolean) => void;
  businessModals: BusinessModals;
  businessItemFlow: BusinessItemFlow;
  selectedBusiness: Business | null;
  selectedBusinessItem: BusinessItem | null;
  businessItemView: string;
}

class ViewBusinessItemModal extends React.Component<ViewBusinessItemModalProps> {

  state = {
    editQuantityOnHandFormOpen: false,
    editQuantityOrderLimitMinFormOpen: false,
    editQuantityOrderLimitMaxFormOpen: false,
    detailFormName: '',
    detailFormDetail: '',
    file: null as File | null
  }

  componentDidMount = () => {
    const { businessItemFlow, selectedBusinessItem, updateBusinessItemInput } = this.props;
    if (selectedBusinessItem) {
      updateBusinessItemInput({...businessItemFlow, ...selectedBusinessItem})
    }
  }

  componentDidUpdate(prevProps: Readonly<ViewBusinessItemModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.selectedBusinessItem && this.props.selectedBusinessItem && prevProps.selectedBusinessItem._id !== this.props.selectedBusinessItem._id ) {
      const { businessItemFlow, selectedBusinessItem, updateBusinessItemInput } = this.props;
      updateBusinessItemInput({...businessItemFlow, ...selectedBusinessItem})
    }
  }

  handlePageDataChange = (change: any) => {
    const { selectedBusiness, selectedBusinessItem } = this.props;
    if (selectedBusinessItem && selectedBusiness && selectedBusinessItem.pageData) {
      const updatedItemData = {
        ...selectedBusinessItem,
        pageData: {
          ...selectedBusinessItem.pageData,
          ...change
        }
      };
    
      this.props.asyncBusinessItemEdit(selectedBusinessItem._id, updatedItemData, true);
      this.props.fetchBusinessItems(selectedBusiness._id, false, true);
    }
  }
  
  renderPageData = () => {
    const { pageData, pageKey } = this.props.businessItemFlow;
    if (pageKey && pageData) {
      const mediaRows: BusinessItemPageDataMedia[][] = [];
      for (let i = 0; i < pageData?.media.length; i += 5) {
        mediaRows.push(pageData?.media.slice(i, i + 5));
      }
      return (
        <>
        <ViewBusinessItemViewSelector />
        { this.props.businessItemView === 'information' && (
          <ViewBusinessItemInformation handlePageDataChange={this.handlePageDataChange}/>
        )}
        { this.props.businessItemView === 'inventory' && (
          <ViewBusinessItemInventory handlePageDataChange={this.handlePageDataChange}/>
        )}
        { this.props.businessItemView === 'contact' && (
          <ViewBusinessItemContact handlePageDataChange={this.handlePageDataChange}/>
        )}
        { this.props.businessItemView === 'design' && (
          <ViewBusinessItemDesign handlePageDataChange={this.handlePageDataChange}/>
        )}
        { this.props.businessItemView === 'discounts' && (
          <ViewBusinessItemDiscounts handlePageDataChange={this.handlePageDataChange}/>
        )}
        { this.props.businessItemView === 'payment' && (
          <ViewBusinessItemPayment handlePageDataChange={this.handlePageDataChange}/>
        )}
        { this.props.businessItemView === 'processing' && (
          <ViewBusinessItemProcessing handlePageDataChange={this.handlePageDataChange}/>
        )}
        { this.props.businessItemView === 'delivery' && (
          <ViewBusinessItemDelivery handlePageDataChange={this.handlePageDataChange}/>
        )}
        </>
      )
    } else {
      return null;
    }

  }

  renderPageKeyCheck = () => {
    const { businessItemFlow, selectedBusinessItem } = this.props;
    const businessItemId = businessItemFlow._id || '';
    if (businessItemFlow.pageKey) {
      return <>{this.renderPageData()}</>
    } else {
      return (
      <>
        <Text textAlign={'center'}>This item has not been added to pages. Make a request to Showfa Express.</Text>
        <Box textAlign={'center'} mt={2}>
        {/* <Button
          colorScheme="green"
          variant="outline"
          borderRadius={20}
          onClick={() => console.log('request page')}
        >
          Request Page
        </Button>    */}
        </Box>
     
      </>
      )
    }
  }

  render() {
    const { selectedBusinessItem, selectedBusiness, businessItemFlow } = this.props;
    if (selectedBusinessItem && selectedBusiness && businessItemFlow) {
      return (
        <Modal isOpen={this.props.businessModals.viewBusinessItemOpen} onClose={this.props.toggleViewBusinessItemModal} size={'5xl'}>
          <ModalOverlay />
          <ModalContent style={{ borderRadius: 25 }}>
            <ModalHeader>Item: {businessItemFlow.name}</ModalHeader>
            <Text fontSize={'sm'} pl={6}>Sold by: {selectedBusiness.name}</Text>
            <ModalCloseButton />
            <ModalBody p={10}>
              <Box>
                {this.renderPageKeyCheck()}
              </Box>
            </ModalBody>
            <ModalFooter>
            {/* <Text fontSize={'xs'} color={'gray.500'}>
              {businessItemFlow._id}
            </Text> */}
              <Button
                onClick={() => {
                  this.props.toggleViewBusinessItemModal()
                }}
                variant="ghost"
                mr="5%"
                borderRadius={20}
              >
                Back
              </Button>
          </ModalFooter>
          </ModalContent>
        </Modal>
      );
    } else {
      return null;
    }

  }
}

const mapStateToProps = (state: ReduxState) => {
  const { businessModals, businessItemFlow, selectedBusiness, selectedBusinessItem, businessItemView } = state.businesses;
  return { businessModals, businessItemFlow, selectedBusiness, selectedBusinessItem, businessItemView };
};

export default connect(mapStateToProps, {
  toggleViewBusinessItemModal,
  updateBusinessItemInput,
  asyncBusinessItemEdit,
  fetchBusinessItems
})(ViewBusinessItemModal);
