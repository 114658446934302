
export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  inputField: '#EFF2F5',
  inputFont: '#292D32',
  primary: '#F74E49', //red
  primaryMuted: '#F7928F',
  secondary: '#FAA741', //orange
  tertiary: "#48BB78",
  shade1: '#3E3E3E',
  shade2: '#E0E0E0',
  statusGreen: '#52F749',
  statusGreenMuted: '#84E390',
  statusYellowMuted: '#F7ED8F',
  statusRedMuted: '#F7928F',
}