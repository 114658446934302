import { TransactionsActionTypes, TransactionsState } from "./types";

const initialState: TransactionsState = {
  errors: {},
  selectedTransaction: null,
  selectedTransactionHistory: [],
  transactions: [],
  transactionsQuery: {
    currentPage: null,
    itemsPerPage: null,
    totalPages: null,
    totalItems: null,
    search: null,
    page: null,
    sortBy: 'timestamp',
    sortDirection: 'desc',
    type: null,
    method: null,
    status: null,
    minAmount: null,
    maxAmount: null,
    currency: null,
    minTimestamp: null,
    maxTimestamp: null,
    branchCode: null,
  },
  transactionsModals: {
    viewTransactionModalOpen: false,
    filterTransactionsModalOpen: false,
    editTransactionModalOpen: false
  },
  transactionsLoading: {
    search: false,
    selected: false,
  },
  transactionFlow: {
    _id: null,
    orderId: '',
    userId: '',
    user: null,
    type: '',
    method: '',
    status: '',
    amount: 0,
    currency: 'JMD',
    timestamp: '',
    branchCode: '',
    data: {},
    errors: {}
  },
  liveMode: true,
}

const transactionsReducer = (state = initialState, action: TransactionsActionTypes): TransactionsState => {
  switch (action.type) {
    case 'SELECT_TRANSACTION':
      return {
        ...state,
        selectedTransaction: action.payload,
      };
    case 'GET_TRANSACTION_HISTORY_SUCCESS':
      return {
        ...state,
        selectedTransactionHistory: action.payload,
      };
    case 'GET_TRANSACTION_HISTORY_FAILURE':
      return {
        ...state,
        errors: { getTransactionHistory: action.payload },
      };
    case 'UPDATE_TRANSACTIONS_QUERY':
      return {
        ...state,
        transactionsQuery: action.payload,
      };
    case 'CLEAR_TRANSACTIONS_QUERY':
      return {
        ...state,
        transactionsQuery: initialState.transactionsQuery
      };
    case 'GET_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        transactionsQuery: action.payload.transactionsQuery,
        transactions: action.payload.transactions,
      };
    case 'GET_TRANSACTIONS_FAILURE':
      return {
        ...state,
        errors: {getTransactions: action.payload}
      };
    case 'UPDATE_EDIT_SELECTED_TRANSACTION_INPUT':
      return {
        ...state,
        transactionFlow: action.payload
      };
    case 'EDIT_SELECTED_TRANSACTION_SUCCESS':
      return {
        ...state,
        selectedTransaction: action.payload,
        transactionFlow: initialState.transactionFlow,
        transactionsModals: { ...state.transactionsModals, editTransactionModalOpen: false, viewTransactionModalOpen: true}
      }
    case 'TOGGLE_VIEW_TRANSACTION_MODAL':
      return {
        ...state,
        transactionsModals: {
          ...state.transactionsModals,
          viewTransactionModalOpen: !state.transactionsModals.viewTransactionModalOpen,
        },
      };
    case 'TOGGLE_EDIT_TRANSACTION_MODAL':
      return {
        ...state,
        transactionsModals: {
          ...state.transactionsModals,
          editTransactionModalOpen: !state.transactionsModals.editTransactionModalOpen,
        },
      };
    case 'TOGGLE_FILTER_TRANSACTIONS_MODAL':
      return {
        ...state,
        transactionsModals: {
          ...state.transactionsModals,
          filterTransactionsModalOpen: !state.transactionsModals.filterTransactionsModalOpen,
        },
      };
    case 'SET_SEARCH_TRANSACTIONS_LOADING':
      return {
        ...state,
        transactionsLoading: {
          ...state.transactionsLoading,
          search: action.payload,
        },
      };
    case 'SET_SELECTED_TRANSACTION_LOADING':
      return {
        ...state,
        transactionsLoading: {
          ...state.transactionsLoading,
          selected: action.payload,
        },
      };
    case 'TOGGLE_TRANSACTION_LIVE_MODE':
      return {
        ...state,
        liveMode: !state.liveMode,
      };
    case 'EDIT_SELECTED_TRANSACTION_SUCCESS':
      return {
        ...state,
        selectedTransaction: action.payload,
      };
    case 'EDIT_SELECTED_TRANSACTION_FAILURE':
      return {
        ...state,
        errors: { editSelectedTransaction: action.payload }
      };
    case 'GET_TRANSACTION_DETAILS_SUCCESS':
      return {
        ...state,
        // Update state based on the success action
      };
    case 'GET_TRANSACTION_DETAILS_FAILURE':
      return {
        ...state,
        errors: { getTransactionDetails: action.payload }
      };
    default:
      return state;
  }
};

export default transactionsReducer;
