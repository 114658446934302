import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { 
  getSelectedBusinessItemsSuccess, 
  getSelectedBusinessItemsFailure,
  setBusinessItemsLoading 
} from '../actions';

// Action to fetch business items for a specific business
export const fetchBusinessItems = (businessId: string, more: boolean = false, suppressLoading: boolean = false) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;
      const { selectedBusinessItems, businessItemQuery } = getState().businesses;
      const { search, currentPage } = businessItemQuery;
      let fetchedBusinessItems: any[] = [];
      // Construct the headers with X-Auth-Token
      const headers = {
        'X-Auth-Token': loginToken,
      };
      if (!suppressLoading) {
        dispatch(setBusinessItemsLoading(true));
      }
      // Construct the URL with query parameters
      let url = `/marketplace-service/api/v1/items?businessId=${businessId}&describe=1`;
      if (more && currentPage) {
        const nextPage = currentPage + 1
        url += `&page=${nextPage}`
      }
      if (search) {
        url += `&search=${search}`;
      }
      const response = await backend.get(url, { headers });

      if (response.status === 200) {
        const { currentPage, itemsPerPage, totalPages, totalItems } = response.data;
        const details = { 
          ...businessItemQuery,
          totalItems, currentPage, totalPages, itemsPerPage
         }
         if (more) {
          fetchedBusinessItems = [...selectedBusinessItems].concat(response.data.items)
        } else {
          fetchedBusinessItems = response.data.items
        }
        dispatch(setBusinessItemsLoading(false));
        dispatch(getSelectedBusinessItemsSuccess(fetchedBusinessItems, details));
      } else {
        dispatch(setBusinessItemsLoading(false));
        dispatch(getSelectedBusinessItemsFailure('Failed to fetch business items.'));
      }
    } catch (error) {
      console.error('Error fetching business items:', error);
      dispatch(setBusinessItemsLoading(false));
      dispatch(getSelectedBusinessItemsFailure('An error occurred while fetching business items.'));
    }
  };
};