import React, { ReactNode } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem, Text } from '@chakra-ui/react';

interface AppDropdownProps {
  children: ReactNode;
  items: any;
}

const AppDropdown: React.FC<AppDropdownProps> = ({ children, items }) => {
  return (
    <Menu>
      <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
        {children}
      </MenuButton>
      <MenuList bg={useColorModeValue('white', 'gray.900')} borderColor={useColorModeValue('gray.200', 'gray.700')}>
        {items}
      </MenuList>
    </Menu>
  );
};

export default AppDropdown;
