import { 
  OrdersActionTypes, 
  Order
} from '../types';

// Action for successful edit of selected order
export const editSelectedOrderSuccess = (
  updatedOrder: Order
): OrdersActionTypes => ({
  type: 'EDIT_SELECTED_ORDER_SUCCESS',
  payload: updatedOrder,
});

// Action for failed edit of selected order
export const editSelectedOrderFailure = (error: string): OrdersActionTypes => ({
  type: 'EDIT_SELECTED_ORDER_FAILURE',
  payload: error,
});