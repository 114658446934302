import axios from 'axios';

//DEV
// export const baseURL = 'https://dev-coreapi.goshowfa.com'

//PRD
export const baseURL = 'https://coreapi.goshowfa.com'

export default axios.create({
    baseURL 
})