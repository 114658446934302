import React from "react";
import { connect } from "react-redux";
import { Button, Text, MenuItem } from "@chakra-ui/react";
import { ReduxState } from "../../store/state";
import { asyncBusinessItemEdit, fetchBusinessItems } from "../../store/businesses/initiators";
import { Business, BusinessItem, BusinessItemPageData } from "../../store/businesses/types";

interface DropdownBusinessItemDiscountModeProps {
  asyncBusinessItemEdit: (itemId: string, updatedItemData: BusinessItem, refreshFlow: boolean) => void;
  fetchBusinessItems: (businessId: string, more: boolean, suppressLoading: boolean) => void;
  item: BusinessItem | null;
  business: Business | null;
  updateKey: 'deliveryDiscountMode' | 'strikeThroughMode' | 'discountMode'
}
class DropdownBusinessItemDiscountMode extends React.Component<DropdownBusinessItemDiscountModeProps> {
  
  updateBusinessItemDiscountMode(
    updateKey: 'deliveryDiscountMode' | 'strikeThroughMode' | 'discountMode',
    update: string | null) {
    const { item, business } = this.props;
    let additionalChanges: any = {}
    if (updateKey === 'deliveryDiscountMode') {
      additionalChanges.deliveryDiscount = 10;
    } else if (updateKey === 'discountMode') {
      additionalChanges.discount = 10;
    } else if (updateKey === 'strikeThroughMode') {
      additionalChanges.strikeThroughNumber = 10;
    }
    if (item && business && item.pageData) {
      const updatedItemData = {
        ...item,
        pageData: {
          ...item.pageData,
          [updateKey]: update,
          ...additionalChanges
        }
      };
    
      this.props.asyncBusinessItemEdit(item._id, updatedItemData, true);
      this.props.fetchBusinessItems(business._id, false, true);
    }
  }

  render() {
    const validModes = ['amount', 'percent', null];
      return (
        <>
        {validModes.map((mode: string | null) => {
          let label = 'not applied';
          if (mode) {
            label = `by ${mode}`
          }
          return (
            <MenuItem key={mode}>
              <Button 
                variant='ghost' 
                size='sm' 
                onClick={() => this.updateBusinessItemDiscountMode(this.props.updateKey, mode)}
              >
                {label}
              </Button>
            </MenuItem>
          );

        })}
        </>
      )
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {};
};

export default connect(mapStateToProps, {
  asyncBusinessItemEdit,
  fetchBusinessItems,
})(DropdownBusinessItemDiscountMode);