import moment from "moment";

export const convertUTCtoLocal = (utcTimestamp: string) => {
  const utcMoment = moment.utc(utcTimestamp);
  const localMoment = utcMoment.local();
  const localTimestamp = localMoment.format('YYYY-MM-DDTHH:mm');
  return localTimestamp;
}

export const convertLocalToUTC = (localTimestamp: string) => {
  const localMoment = moment(localTimestamp);
  const utcMoment = localMoment.utc();
  const utcTimestamp = utcMoment.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  return utcTimestamp;
}