import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Card,
  Table,
  Tr,
  Th,
  Td,
  Tbody,
  CardBody,
  Divider,
  SimpleGrid,
  Box
} from '@chakra-ui/react';

import { CheckCircleIcon } from '@chakra-ui/icons';
import { MdAdjust } from 'react-icons/md';
import moment from 'moment';
import { formatPhoneNumber } from '../global/data/formatPhoneNumberHelper';
import { ReduxState } from '../../store/state';
import { OrderModals, Order as IOrder, OrderHistory } from '../../store/orders/types';
import { Transaction } from '../../store/transactions/types';
import { toggleViewOrderModal } from '../../store/orders/actions';
import TimelineRow from '../global/data/TimelineRow';
import ViewOrderDeliveryDetails from './ViewOrderDeliveryDetails';
import { formatCurrency } from '../global/data/formatCurrencyHelper';
import ViewOrderItems from './ViewOrderItems';
import AppDropdown from '../global/dropdown/AppDropdown';
import DropdownOrderStatus from './DropdownOrderStatus';
import TransactionRow from '../transactions/TransactionRow';

interface ViewOrderModalProps {
  toggleViewOrderModal: () => void;
  orderModals: OrderModals;
  selectedOrder: IOrder | null;
  selectedOrderHistory: OrderHistory[];
  selectedOrderTransactions: Transaction[];
}

const ViewOrderModal: React.FC<ViewOrderModalProps> = ({
  toggleViewOrderModal,
  orderModals,
  selectedOrder,
  selectedOrderHistory,
  selectedOrderTransactions,
}) => {
  const pickupCard = () => {
    const order = selectedOrder;
    if (order && order.sourceType === 'businessLocation') {
      return (
        <>
          <Text as="b">
            {order.sourceBusinessLocation && order.sourceBusinessLocation.business
              ? `${order.sourceBusinessLocation.business.name}`
              : ''}
          </Text>
          <Text>{order.sourceBusinessLocation ? `${order.sourceBusinessLocation.address}` : ''}</Text>
        </>
      );
    } else {
      return null;
    }
  };

  const transactionsRender = () => {
    const transactions = selectedOrderTransactions;
    if (!transactions.length) {
      return <Text as="i" textAlign={'center'}>
        No transactions
      </Text>;
    }
    return transactions.map((item: Transaction, index: number) => {
      if (['purchase', 'payment', 'partner commission', 'partner payout'].includes(item.type)) {
        return <TransactionRow transaction={item} hideBranch={true} hideNotes={true} key={item._id} shortenNames={true}/>
      } else {
        return null;
      }
  });
  };

  const historyRender = () => {
    const history = selectedOrderHistory;
    return history.map((item: OrderHistory, index: number, history) => {
      const timestamp = moment(new Date(item.timestamp)).format(`MMM D h:mm a`);
      let locationString = '';
      // if (item.locationType === 'userLocation' && item.userLocation) {
      //   locationString = `@ ${item.userLocation.address}`;
      // }
      // if (item.locationType === 'businessLocation' && item.businessLocation && item.businessLocation.business) {
      //   locationString = `@ ${item.businessLocation.business.name}: ${item.businessLocation.address}`;
      // }
      const color = item.event === 'created' ? 'gray' : item.event === 'awaiting payment' ? 'orange' : item.event === 'cancelled' || item.event === 'failed' ? 'red' : item.event === 'delivered' ? 'green' : 'gray';
      const icon = item.event === 'delivered' ? CheckCircleIcon : MdAdjust;
      const titleRow = (
        <>
          <Text fontSize="xs">
            <Button
              variant={'outline'}
              size={'xs'}
              colorScheme={
                item.event === 'created'
                  ? 'gray'
                  : item.event === 'awaiting payment' 
                  ? 'yellow' 
                  : item.event === 'cancelled' || item.event === 'failed'
                  ? 'red'
                  : item.event === 'delivered'
                  ? 'green'
                  : 'gray'
              }
            >
              {item.event}
            </Button>
            {item.user ? ` ${item.user.firstName} ${item.user.lastName && item.user.lastName.length > 0 ? item.user.lastName[0]+'.' : null}` : null}
          </Text>
          <Text fontSize={'xs'} as="i">
            {locationString}
          </Text>
        </>
      );
      return (
        <TimelineRow
          logo={icon}
          titleRow={titleRow}
          date={timestamp}
          color={color}
          index={index}
          arrLength={history.length}
        />
      );
    });
  };

  const terminalStatuses = ['delivered', 'cancelled', 'failed'];
  const inProgressStatuses = ['ready for pickup', 'picked up', 'on the way'];
  let isInProgress = false;
  if (selectedOrder && inProgressStatuses.includes(selectedOrder.status)) {
    isInProgress = true;
  }

  if (!selectedOrder) {
    return (
      <Modal isOpen={orderModals.viewOrderModalOpen} onClose={toggleViewOrderModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order: Not Found</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Order Not Found</p>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={toggleViewOrderModal}>
              Back
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={orderModals.viewOrderModalOpen} onClose={toggleViewOrderModal} size="5xl">
      <ModalOverlay />
      <ModalContent style={{ borderRadius: 25 }}>
        <ModalHeader>ORDER #{selectedOrder.orderNumber}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={2}>
            <Box>
              <Text as="b">{selectedOrder.user ? `${selectedOrder.user.firstName} ${selectedOrder.user.lastName && selectedOrder.user.lastName.length > 0 ? selectedOrder.user.lastName[0]+'.' : null}` : ''}</Text>
            </Box>
            <Box ml="auto">
              <AppDropdown items={<DropdownOrderStatus order={selectedOrder} />}>
                <Button
                  ml={1}
                  colorScheme={
                    selectedOrder.status === 'created'
                      ? 'gray'
                      : selectedOrder.status === 'awaiting payment' 
                      ? 'yellow'
                      : selectedOrder.status === 'cancelled' || selectedOrder.status === 'failed'
                      ? 'red'
                      : selectedOrder.status === 'delivered'
                      ? 'green'
                      : 'gray'
                  }
                  size="xs"
                >
                  {selectedOrder.status}
                </Button>
              </AppDropdown>
            </Box>
          </SimpleGrid>

          <Box paddingTop={5} paddingBottom={5}>
            <Card>
              <CardBody>
                <Text fontSize="lg" textAlign="center" color={'gray.500'} as="b">
                  PICKUP
                </Text>
                <br />
                {pickupCard()}
              </CardBody>
            </Card>
          </Box>

          <Divider />

          <Box paddingTop={5} paddingBottom={5}>
            <Card>
              <CardBody>
                <Text fontSize="lg" textAlign="center" color={'gray.500'} as="b">
                  ORDER
                </Text>
                <br />
                <ViewOrderItems selectedOrder={selectedOrder} />
              </CardBody>
            </Card>
          </Box>
          <Box paddingTop={5} paddingBottom={5}>
            <Card>
              <CardBody>
                <Text fontSize="lg" textAlign="center" color={'gray.500'} as="b">
                  DELIVERY
                </Text>
                <br />
                <Text>
                  <ViewOrderDeliveryDetails />
                </Text>
              </CardBody>
            </Card>
          </Box>

          <Box paddingTop={5} paddingBottom={5}>
            <Card>
              <CardBody>
                <Text fontSize="lg" textAlign="center" color={'gray.500'} as="b">
                  TRANSACTIONS
                </Text>
                <br />
                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Th>Identifier</Th>
                      <Th>Timestamp</Th>
                      <Th>Type</Th>
                      <Th textAlign={'right'}>Amount</Th>
                      <Th>Method</Th>
                      <Th>User</Th>
                      <Th>Status</Th>
                    </Tr>
                    {transactionsRender()}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </Box>

          <Box paddingTop={5} paddingBottom={5}>
            <Card>
              <CardBody>
                <Text fontSize="lg" textAlign="center" color={'gray.500'} as="b">
                  HISTORY
                </Text>
                <br />
                <Box paddingLeft={20}>{historyRender()}</Box>
              </CardBody>
            </Card>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { selectedOrder, orderModals, selectedOrderHistory, selectedOrderTransactions } = state.orders;
  return { selectedOrder, orderModals, selectedOrderHistory, selectedOrderTransactions };
};

export default connect(mapStateToProps, {
  toggleViewOrderModal,
})(ViewOrderModal);
