import React from "react";
import { connect } from "react-redux";
import { Button, MenuItem } from "@chakra-ui/react";
import { ReduxState } from "../../store/state";
import { asyncBusinessItemEdit, fetchBusinessItems } from "../../store/businesses/initiators";
import { Business, BusinessItem, BusinessItemPageData } from "../../store/businesses/types";

interface DropdownBusinessItemProcessingDaysProps {
  asyncBusinessItemEdit: (itemId: string, updatedItemData: BusinessItem, refreshFlow: boolean) => void;
  fetchBusinessItems: (businessId: string, more: boolean, suppressLoading: boolean) => void;
  item: BusinessItem | null;
  business: Business | null;
}
class DropdownBusinessItemProcessingDays extends React.Component<DropdownBusinessItemProcessingDaysProps> {
  
  updateBusinessItemProcessingDays(processingDays: ('monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday')[]) {
    const { item, business } = this.props;
    
    // Define the order of weekdays
    const weekdaysOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    
    // Sort processingDays according to the weekdaysOrder
    processingDays.sort((a, b) => weekdaysOrder.indexOf(a) - weekdaysOrder.indexOf(b));
    
    if (item && business && item.pageData) {
        const updatedItemData = {
            ...item,
            pageData: {
                ...item.pageData,
                processingDays
            }
        };

        this.props.asyncBusinessItemEdit(item._id, updatedItemData, true);
        this.props.fetchBusinessItems(business._id, false, true);
    }
}


  render() {

    const processingDays: ('monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday')[] = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'       
    ];
    const currentDays: ('monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday')[] = this.props.item?.pageData?.processingDays || [];
      return (
        <>
        {processingDays.map((day) => {
          if (!currentDays.includes(day)) {
            return (
              <MenuItem key={day}>
                <Button 
                  variant='ghost' 
                  size='sm' 
                  onClick={() => this.updateBusinessItemProcessingDays([
                    ...currentDays,
                    day
                  ])}
                >
                  {`Add ${day} processing`}
                </Button>
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
        { processingDays.length === this.props.item?.pageData?.processingDays.length && (
            <MenuItem>
              <Button 
                variant='ghost' 
                size='sm' 
              >
                {`All weekdays are currently included.`}
              </Button>
            </MenuItem>          
        )}
        </>
      )
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {};
};

export default connect(mapStateToProps, {
  asyncBusinessItemEdit,
  fetchBusinessItems,
})(DropdownBusinessItemProcessingDays);