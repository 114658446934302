import React, { Component } from "react";
import { FormControl, Input, IconButton, Flex } from "@chakra-ui/react";
import { MdCheck, MdClose } from "react-icons/md";

export interface QuickNumberFormProps {
  onSubmit: (formNumber: number) => void;
  onClose: () => void;
  initialNumber?: number;
  minLimit: number;
  maxLimit: number;
}

class QuickNumberForm extends Component<QuickNumberFormProps> {
  state = {
    formNumber: this.props.initialNumber || 0
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ formNumber: parseInt(e.target.value) || 0 });
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { formNumber } = this.state;
    if (!isNaN(formNumber)) { // Check if formNumber is a valid number
      onSubmit(formNumber);
    }
  }

  render = () => {
    const { onClose, minLimit, maxLimit } = this.props;
    const { formNumber } = this.state;
    const canSubmit = formNumber >= minLimit && formNumber <= maxLimit;
    
    return (
      <Flex direction={'row'} align={'center'}>
        <FormControl width={20}>
          <Input 
            onChange={this.handleChange}
            value={formNumber.toString()} 
            style={{ borderRadius: 25}}
            textAlign={'right'}
          />        
        </FormControl>
        <IconButton 
          onClick={this.handleSubmit}
          aria-label='Submit' 
          colorScheme={canSubmit ? 'green' : undefined}
          borderRadius={25} 
          ml={1} 
        >
          <MdCheck />
        </IconButton>
        <IconButton 
          onClick={onClose}
          aria-label='Close' 
          borderRadius={25} 
          ml={1} 
        >
          <MdClose />
        </IconButton>
      </Flex>
    );
  }
}

export default QuickNumberForm;
