import { BusinessLocation, BusinessesActionTypes, SelectedBusinessAttributeDetails } from '../types';

// Action types related to business locations

export const getSelectedBusinessLocationsSuccess = (locations: BusinessLocation[], query: SelectedBusinessAttributeDetails): BusinessesActionTypes => ({
    type: 'GET_SELECTED_BUSINESS_LOCATIONS_SUCCESS',
    payload: { locations, query },
});

export const getSelectedBusinessLocationsFailure = (payload: string): BusinessesActionTypes => ({
    type: 'GET_SELECTED_BUSINESS_LOCATIONS_FAILURE',
    payload,
});

export const updateBusinessLocationsQuery = (payload: SelectedBusinessAttributeDetails): BusinessesActionTypes => ({
    type: 'UPDATE_BUSINESS_LOCATIONS_QUERY',
    payload,
});

export const selectBusinessLocation = (payload: BusinessLocation | null): BusinessesActionTypes => ({
    type: 'SELECT_BUSINESS_LOCATION',
    payload,
});