import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tr, Td, Button, Text } from '@chakra-ui/react';
import { MdCurrencyExchange } from 'react-icons/md';
import { Transaction as ITransaction, TransactionsModals } from '../../store/transactions/types';
import { 
  toggleViewTransactionModal, 
  selectTransaction, 
} from '../../store/transactions/actions';
import { ReduxState } from '../../store/state';

const allowedStatusTypes = [
  'pending',
  'completed',
  'failed'
];

function shortenUuid(uuidStr: string, length: number = 8): string {
  // Convert the UUID string to lowercase
  uuidStr = uuidStr.toLowerCase();
  // Remove hyphens from the UUID
  uuidStr = uuidStr.replace(/-/g, '');
  // Return the first 'length' characters
  return uuidStr.slice(0, length);
}

interface TransactionRowProps {
    transaction: ITransaction;
    toggleViewTransactionModal: () => void;
    selectTransaction: (transaction: ITransaction) => void;
    transactionsModals: TransactionsModals;
    hideBranch?: boolean;
    hideNotes?: boolean;
    shortenNames?: boolean;
}

class TransactionRow extends React.Component<TransactionRowProps> {

  handleSelection = () => {
    const { transaction } = this.props;
    this.props.selectTransaction(transaction);
    this.props.toggleViewTransactionModal();
  }

  formatCurrency = (currency: string, amount: number) => {
    if (!currency) {
      return amount
    }
    if (currency === 'RWP') {
      return amount.toFixed(0)
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    }).format(amount);
  }
  
  render() {
    const { transaction } = this.props;
    const timestamp = moment(new Date(transaction.timestamp)).format(`MMM D h:mm a`);
    const { notes } = transaction.data;
    let notesDisplay = '';
    if (notes) {
      if (notes.length > 20) {
        notesDisplay = `${notes.slice(0,20)}...`
      } else {
        notesDisplay = notes
      }
    }
    let userName = '';
    if (transaction.user) {
      const firstName = transaction.user.firstName
      let lastName = '';
      if (transaction.user.lastName && transaction.user.lastName.length > 0) {
        if (this.props.shortenNames) {
          lastName = transaction.user.lastName[0] + '.'
        } else {
          lastName = transaction.user.lastName
        }
      }
      userName = `${firstName} ${lastName}`
    }
    return (
      <Tr key={transaction._id} style={{ background: 'white' }} boxShadow="0 2px 8px rgba(0, 0, 0, 0.2)">
        <Td>
          <Button size={'xs'} leftIcon={<MdCurrencyExchange />} borderRadius={'full'} onClick={this.handleSelection}>
            <Text>{shortenUuid(transaction._id)}</Text>
          </Button>
        </Td>
        <Td>
          <Text fontSize='sm' color='gray.600'>{timestamp}</Text>
        </Td>
        <Td>
          <Button variant={'outline'} size={'xs'} colorScheme={transaction.type === 'partner payout' ? 'green' : 'gray'}>{transaction.type}</Button>
        </Td>
        <Td>
          <Text fontSize='sm' color='gray.600' textAlign={'right'}>{this.formatCurrency(transaction.currency, transaction.amount)}</Text>
        </Td>
        <Td>
        <Button variant={'outline'} size={'xs'}>{transaction.method}</Button>
        </Td>
        <Td>
          <Text fontSize='sm' color='gray.600'>{transaction.user ? `${userName}` : null}</Text>
        </Td>
        {!this.props.hideBranch && (
        <Td>
          <Button variant={'outline'} size={'xs'}>{transaction.branchCode}</Button>
        </Td>
        )}
        <Td>
          <Button
              colorScheme={transaction.status === 'completed' ? 'green' : 'gray'}
              size='xs'
            >
              {transaction.status}
            </Button> 
        </Td>
        {!this.props.hideNotes && (
        <Td>
          <Text fontSize='sm' color='gray.600'>{notesDisplay}</Text>
        </Td>          
        )}

      </Tr>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { selectedTransaction, transactionsModals } = state.transactions;
  return { selectedTransaction, transactionsModals };
};

export default connect(mapStateToProps, { 
  selectTransaction,
  toggleViewTransactionModal
})(TransactionRow);
