import React from 'react';
import { connect } from 'react-redux';
import { fetchOrders, fetchCurrentOrders } from '../../store/orders/initiators';
import { ReduxState } from '../../store/state';
import OrderRow from './OrderRow';
import { Order as IOrder, OrderModals } from '../../store/orders/types';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer
} from '@chakra-ui/react'
import NoContentPage from '../../pages/noContent/NoContentPage';

interface OrdersListProps {
    fetchOrders: (more: boolean) => void;
    fetchCurrentOrders: (more: boolean) => void;
    orders: IOrder[];
    currentOrders: IOrder[];
    orderModals: OrderModals;
    ordersViewMode: string;
}

class OrdersList extends React.Component<OrdersListProps> {
  componentDidMount() {
    if (this.props.ordersViewMode === 'current') {
      this.props.fetchCurrentOrders(false);
    } else {
      this.props.fetchOrders(false);
    }
  }

  renderOrders(orderList: IOrder[]) {

      return (
        <>
          {orderList.map((order: IOrder) => {
            return <OrderRow key={order._id} order={order} />
          })}
        </>
      )
  }

  renderOrdersTable() {
    var orderList = this.props.orders;
    if (this.props.ordersViewMode === 'current') {
      orderList = this.props.currentOrders
    }

    if (!orderList.length) {
      return <NoContentPage noContentText='No Orders Found'/>
    }

    return (
      <TableContainer>
      <Table variant='simple' >
        <Thead>
          <Tr>
            <Th>Order #</Th>
            <Th>Customer</Th>
            <Th>Ship From</Th>
            <Th textAlign={'right'}>Sales</Th>
            {/* <Th style={{ textAlign: 'right' }}>Expenses</Th> */}
            {/* <Th style={{ textAlign: 'right' }}>Accepted</Th> */}
            <Th style={{ textAlign: 'right' }}>Delivered/Target</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
        {this.renderOrders(orderList)}
        </Tbody>
      </Table>
    </TableContainer>    
    );
  }
  
  render() {
    return (
      <div>
        <div>
        {this.renderOrdersTable()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
    const { ordersQuery, orders, currentOrders, errors, selectedOrder, orderModals, ordersViewMode } = state.orders;
    return { ordersQuery, orders, currentOrders, errors, selectedOrder, orderModals, ordersViewMode };
};

export default connect(mapStateToProps, { 
    fetchOrders,
    fetchCurrentOrders
})(OrdersList);
