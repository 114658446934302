import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';

import {
  Order
} from '../types';
import {
  editSelectedOrderSuccess,
  editSelectedOrderFailure,
} from '../actions';

// Thunk action to edit the selected order
export const asyncOrderEdit = (orderId: string, updatedOrderData: Order) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const loginToken = auth.loginToken;

      // Construct the headers with X-Auth-Token
      const headers = {
        'X-Auth-Token': loginToken,
      };

      const response = await backend.put(`/commerce-service/api/v1/orders/${orderId}?describe=1`, updatedOrderData, { headers });

      if (response.status === 200) {
        const order: Order = response.data;
        dispatch(editSelectedOrderSuccess(order));
      } else {
        dispatch(editSelectedOrderFailure('Failed to edit the order.'));
      }
    } catch (error) {
      console.error('Error editing order:', error);
      dispatch(editSelectedOrderFailure('An error occurred while editing the order.'));
    }
  };
};